import { oneOf } from 'prop-types';

const CLICK_BEHAVIORS = {
  productPage: 'productPage',
  quickview: 'quickview',
};

const productCardClickBehaviorType = oneOf([...Object.values(CLICK_BEHAVIORS), '', undefined]);

export default productCardClickBehaviorType;
export { CLICK_BEHAVIORS };
