import PropTypes from 'prop-types';
import React, {
  useCallback,
  useReducer,
  useRef,
} from 'react';
import { useWindowSize } from 'react-use';
import SearchResultsContext from '../../context/SearchResultsContext';
import useCRSParameterListener from '../../hooks/useCRSParameterListener';
import useFilterTriggerAnalytics from '../../hooks/useFilterTriggerAnalytics';
import useIsScrolling from '../../hooks/useIsScrolling';
import useStoreData from '../../hooks/useStoreData';
import useTranslatedText from '../../hooks/useTranslatedText';
import useUrlSync from '../../hooks/useUrlSync';
import DomNodePortal from '../../tools/DomNodePortal';
import {
  ACTION,
  EDITABLE_SEARCH_SCROLL_OFFSET,
  HEADER_SCROLL_OFFSET,
  PRODUCTS_PER_PAGE,
} from '../../tools/constants';
import getFacetKeysForAnalytics from '../../tools/getFacetKeysForAnalytics';
import getFacetmap from '../../tools/getFacetmap';
import getPageType from '../../tools/getPageType';
import getSelectedFacets from '../../tools/getSelectedFacets';
import isServer from '../../tools/isServer';
import reducer from '../../tools/parametersChange';
import $window from '../../tools/window';
import BreakpointProvider from '../BreakpointProvider';
import DepartmentSelector from '../DepartmentSelector';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
} from '../DigitalDataProvider';
import EditableSearchHeader from '../EditableSearch/EditableSearchHeader';
import FacetsLeftRail from '../FacetsLeftRail';
import ProductGridScrollHandler from '../ProductGridScrollHandler';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import recsContainerStyles from '../RecommendationSlider/recommendationSlider.module.scss';
import useStickyIntersection from '../Refine/hooks/useStickyIntersection';
import { usePopularSearches } from '../SearchBox/hooks/useQueries';
import SearchDefaultView from '../SearchDefaultView';
import SearchErrorPage from '../SearchErrorPage';
import StoreDetailsProvider from '../StoreDetailsProvider/StoreDetailsProvider';
import StoreToggle from '../StoreToggle';
import SearchGridWrapper from './SearchGridWrapper';
import style from './SearchResultsPage.module.scss';
import useFeatureFlags from './hooks/useFeatureFlags';
import useSearchResponse from './hooks/useSearchResponse';

function SearchResultsPage({
  brand: intlBrand,
  countryFulfillmentStore: intlCountryFulfillmentStore = '',
  departmentId: intlDepartmentId = '',
  initialDepartmentId = '',
  facet: intlFacet = [],
  filter: intlFilter = '',
  userInputedSearchTerm = '',
  searchTerm: intlSearchTerm = '',
  sort: intlSort = '',
  start: intlStart = '0',
  store = '',
  storeId = '',
}) {
  //  STATE:
  const intlFacetArray = typeof intlFacet === 'string' ? [intlFacet] : intlFacet;
  const gridWrapper = useRef(null);
  const railRef = useRef({ appliedRailFilters: [] });
  const gridIntersecting = useStickyIntersection(gridWrapper);
  const storeDetails = useStoreData();
  const popularSearchesList = usePopularSearches();
  const { width } = useWindowSize();
  const isScrollingUp = useIsScrolling(['up']);
  const [parameters, dispatch] = useReducer(reducer, {
    brand: intlBrand,
    countryFulfillmentStore: intlCountryFulfillmentStore,
    currentURL: $window.location?.pathname,
    departmentId: intlDepartmentId, // Provided by CRS (SOLR API version)
    initialDepartmentId: initialDepartmentId || '', // Provided by the URL
    facet: intlFacetArray,
    filter: intlFilter,
    rows: PRODUCTS_PER_PAGE, // Constant value set to 42 to improve performance
    searchTerm: intlSearchTerm,
    sort: intlSort,
    submitMethod: { trigger: 'toaster' },
    start: intlStart,
    initialSearchTerm: userInputedSearchTerm,
    userInputedSearchTerm,
  });

  // feature flags
  const {
    editableSearchFlag,
    departmentSuggestionsFlag,
    facetsLeftRailFlag,
    hasShopMyStoreEnabled,
  } = useFeatureFlags();

  //  Search response
  const {
    data,
    error,
    loading,
    previousData,
    resolvedSearchTerm,
    setResolvedSearchTerm,
  } = useSearchResponse(parameters);

  // derived state:
  const userInitiatedAsyncSearch = parameters?.submitMethod?.trigger !== 'debounce';
  const isFacetSelected = parameters.facet?.length > 0;
  const isDesktop = width > 1025;

  const isClearAllButtonEnabled = isFacetSelected
    || !!parameters.filter;

  const noMatchFoundFor = useTranslatedText('noMatchFoundFor', {
    replacements: userInputedSearchTerm,
    skip: (userInputedSearchTerm === intlSearchTerm) || !userInputedSearchTerm,
    fallback: `No match found for "${userInputedSearchTerm}".`,
  });

  const showCorrectedSearchText = parameters.userInputedSearchTerm
    && noMatchFoundFor
    && parameters.userInputedSearchTerm !== parameters.searchTerm;

  // URL SYNC
  useUrlSync(parameters, dispatch);

  //  EVENT HANDLERS

  const syncSearchTerm = useCallback(() => {
    if (resolvedSearchTerm !== parameters.searchTerm) {
      dispatch({
        type: ACTION.SEARCHTERM,
        payload: { searchTerm: resolvedSearchTerm },
      });
    }
  }, [resolvedSearchTerm, parameters.searchTerm]);

  const onClearFacetTag = (payload) => {
    if (railRef.current.appliedRailFilters.includes(`${payload.facetKey},${payload.facetValue}`)) {
      railRef.current.appliedRailFilters = railRef.current.appliedRailFilters.filter(
        (item) => item !== `${payload.facetKey},${payload.facetValue}`,
      );
    }
    if ((isDesktop && facetsLeftRailFlag) || !isDesktop) {
      const facetMap = getSelectedFacets(parameters.facet);
      // Check if selected checkbox is in already selected facet filters
      if (facetMap.has(payload.facetKey)) {
        const checkedFacets = facetMap.get(payload.facetKey);
        const index = checkedFacets.indexOf(payload.facetValue);
        if (index !== -1) {
          if (checkedFacets.length === 1) {
            facetMap.delete(payload.facetKey);
          } else {
            checkedFacets.splice(index, 1);
          }
        }
      }
      syncSearchTerm();
      dispatch({
        type: ACTION.FACET_TOGGLE,
        payload: [...facetMap],
      });
    } else {
      const correspondingLeftRailFilterInput = document.querySelector(
        `input[name="${payload.facetKey}"][value="${payload.facetValue}"],
        input[name="${payload.facetValue}"][value="${payload.facetKey},${payload.facetValue}"]`,
      );
      if (correspondingLeftRailFilterInput && correspondingLeftRailFilterInput.checked) {
        correspondingLeftRailFilterInput.click();
      }
    }
  };

  const onClearAllBtnClick = () => {
    railRef.current.appliedRailFilters = [];
    syncSearchTerm();
    dispatch({
      type: ACTION.CLEAR_ALL_PARAMETERS,
    });
  };

  const updateFacets = (facetMap, discoverTrigger = '', facetId) => {
    const discoverProps = discoverTrigger ? {
      data_text: `${discoverTrigger} ${facetId} filter`,
      data_action: 'applied',
    } : {};
    syncSearchTerm();
    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });

    if ($window.digitalData) {
      const analyticsEvent = {
        search_filter_applied_category: `${discoverTrigger} ${[...getFacetKeysForAnalytics(facetMap)]}`,
        search_filter_applied_value_name: `${[...facetMap.values()]}`,
        event_name: 'filter_applied',
        event_type: 'filters',
        page_description: (!parameters.departmentId) ? 'All Genders' : parameters.departmentId,
        tealium_event: 'filter_applied',
        data_text: 'rail',
        data_action: railRef.current.appliedRailFilters.length > 1 ? 'multiple' : 'single',
        ...discoverProps,
      };
      $window.digitalData.trigger('search_filter_applied', analyticsEvent);
    }
  };

  /**
   * Replace a single facet with a new one
   * @param {string} facetId - The facet to replace
   * @param {array} filters - The filters to apply
   * @returns void
   */
  const handleFacetToasterChange = (facetId, filters, discoverTrigger = '') => {
    const facetMap = getSelectedFacets(parameters.facet);
    if (filters.length === 0) {
      facetMap.delete(facetId);
    } else {
      facetMap.set(facetId, filters);
    }
    updateFacets(facetMap, discoverTrigger, facetId);
  };

  const handleCheckBoxChange = (event) => {
    const { target: { checked, value } } = event;
    railRef.current.appliedRailFilters = checked
      ? [...railRef.current.appliedRailFilters, value]
      : railRef.current.appliedRailFilters.filter((item) => item !== value);

    const facetMap = getFacetmap(event, parameters.facet);
    updateFacets(facetMap);
  };

  const handleStoreFilter = useCallback((storeIdFromFilter) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.LOCAL_STORE_TOGGLE,
      payload: storeIdFromFilter,
    });
  }, [dispatch, syncSearchTerm]);

  // Need this for backward compatibility
  const handleCRSParameterChange = useCallback((event) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.CRS_UPDATE,
      payload: event.detail,
    });
  }, [dispatch, syncSearchTerm]);

  const handleSortChange = (event) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.SORT_UPDATE,
      payload: event.target.value,
    });
    $window.digitalData.trigger(`${getPageType()}_sort_applied`, {
      event_name: 'sort_applied',
      event_type: 'sort',
      search_filter_applied_value_name: event.target.value,
      tealium_event: 'sort_applied',
    });
  };

  const onPaginationButtonClick = (event, start) => {
    syncSearchTerm();
    dispatch({
      type: ACTION.PAGINATION,
      payload: start,
    });
  };

  const onDepartmentChange = (event) => {
    railRef.current.appliedRailFilters = [];
    syncSearchTerm();
    dispatch({
      type: ACTION.DEPARTMENT_CHANGE,
      payload: event.target,
    });
  };

  /**
   * Submit search term from Editable Search Header or Search Default View
   * @param {Object} submission - Object containing the search term and submit method
   * @param {string} submission.value - Search term
   * @param {string} submission.submitMethod - Method used to submit the search term,
   * which will be undefined from Search Default View
   * @returns {void}
   */
  const onSearchTermChange = (submission) => {
    railRef.current.appliedRailFilters = [];
    const searchTerm = submission?.value;
    const submitMethod = submission?.submitMethod || { trigger: 'toaster' };
    const departmentId = submission?.departmentId || parameters.departmentId;
    dispatch({
      type: ACTION.SEARCHTERM,
      payload: {
        searchTerm,
        departmentId,
        submitMethod,
      },
    });
  };

  const handleCategoryParametersChanged = useCallback((event) => {
    // if facets are on the only possible event we can get from CRS would be a store filter change
    if (facetsLeftRailFlag) {
      const storeIdFromFilter = event.detail.filter?.split(':')[1] ?? '';
      handleStoreFilter(storeIdFromFilter);
    } else {
      handleCRSParameterChange(event);
    }
  }, [facetsLeftRailFlag, handleCRSParameterChange, handleStoreFilter]);

  //  SIDE EFFECTS

  useFilterTriggerAnalytics(storeDetails, parameters.filter);
  // Need this for backward compatibility
  useCRSParameterListener(handleCategoryParametersChanged);

  if (error) {
    console.error('SearchResultsPage : getSearchData : Error executing GraphQL query', {
      searchTermParameter: intlSearchTerm,
      facetParameter: parameters.facet,
      departmentIdParameter: parameters.departmentId,
      error,
    });

    if (isServer()) {
      return null;
    }

    return <SearchErrorPage error={error} />;
  }

  let currentData;

  if (loading) {
    if (!previousData) {
      return null;
    }
    currentData = previousData;
  } else {
    currentData = data;
  }

  const {
    departmentId,
    facets,
    sortData,
    stats,
    departments: elasticDepartmentData,
    searchSuggestions,
  } = currentData?.searchResults || {};

  const showAdditionalControls = stats?.total !== 0 || !departmentSuggestionsFlag;
  const suggestionObject = searchSuggestions?.byName;

  //  RENDER
  const departmentDropdown = (
    <DomNodePortal targetNodeSelector=".mfe-department-selector-container">
      {isDesktop && parameters.searchTerm && showAdditionalControls
        ? (
          <DepartmentSelector
            departmentId={(departmentId !== undefined && departmentId !== null
              ? departmentId : parameters.departmentId || initialDepartmentId)}
            elasticDepartmentData={elasticDepartmentData}
            fromLeftRail
            onDepartmentChange={onDepartmentChange}
            searchTerm={intlSearchTerm}
          />
        ) : null}
      {showCorrectedSearchText ? (
        <span className={style.correctedTerm} data-testid="correctedSearchTerm">{noMatchFoundFor.value}</span>
      ) : null}
    </DomNodePortal>
  );
  const leftRailFacets = isDesktop && facetsLeftRailFlag ? (
    <DomNodePortal targetNodeSelector=".sort-and-filter-facet-container">
      {hasShopMyStoreEnabled && showAdditionalControls && (
        <div className={style.storeToggleWrapper}>
          <StoreToggle
            handleStoreFilter={handleStoreFilter}
            isShopMyStore={parameters.filter !== ''}
            storeDetails={storeDetails}
          />
          <hr />
        </div>
      )}
      {facets?.length > 0 && parameters.searchTerm && (
        <FacetsLeftRail
          brand={parameters.brand}
          facet={parameters.facet}
          facetData={facets}
          hasClearButtonEnabled={isClearAllButtonEnabled}
          onCheckBoxChange={handleCheckBoxChange}
          onClearAllBtnClick={onClearAllBtnClick}
        />
      )}
    </DomNodePortal>
  ) : null;

  const editableSearchHeader = (
    <DomNodePortal targetNodeSelector=".editable-search-container">
      <EditableSearchHeader
        brand={parameters.brand}
        departmentId={parameters.departmentId || departmentId}
        facet={parameters.facet}
        facetData={currentData?.searchResults?.facets}
        filter={parameters.filter}
        isDesktop={isDesktop}
        isFacetSelected={isFacetSelected}
        onCheckBoxChange={handleCheckBoxChange}
        onClearAllBtnClick={onClearAllBtnClick}
        onFacetToasterChange={handleFacetToasterChange}
        onSearchTermChange={onSearchTermChange}
        onSortChange={handleSortChange}
        resultsCount={currentData?.searchResults?.stats.refinedTotal}
        searchTerm={resolvedSearchTerm || parameters.searchTerm}
        selectedSort={parameters.sort
          || currentData?.searchResults?.sortData?.defaultSortOption}
        setResolvedSearchTerm={setResolvedSearchTerm}
        showStickyBar={gridIntersecting && isScrollingUp}
        sortData={currentData?.searchResults?.sortData}
        suggestionObject={suggestionObject}
      />
    </DomNodePortal>
  );

  return (
    <StoreDetailsProvider store={store} storeId={storeId}>
      <BreakpointProvider>
        <DigitalDataProvider
          keys={[
            DD_DISABLE_SWATCH_HOVER,
            DD_HYPERLINK_DESC,
            DD_MODEL_IMAGERY_TEST,
          ]}
        >
          <SearchResultsContext.Provider value={{ userInitiatedAsyncSearch }}>
            <ProductGridScrollHandler
              parameters={parameters}
              scrollOffset={editableSearchFlag === false
                ? HEADER_SCROLL_OFFSET : EDITABLE_SEARCH_SCROLL_OFFSET}
            >
              {editableSearchFlag === false ? null : editableSearchHeader}
              {departmentDropdown}
              {leftRailFacets}
              {editableSearchFlag === false || parameters.searchTerm !== ''
                ? (
                  <div ref={gridWrapper}>
                    <SearchGridWrapper
                      departmentId={(departmentId !== undefined && departmentId !== null
                        ? departmentId : parameters.departmentId || initialDepartmentId)}
                      editableSearchFlag={editableSearchFlag}
                      elasticDepartmentData={elasticDepartmentData}
                      facets={facets}
                      gridIntersecting={gridIntersecting}
                      handleCheckBoxChange={handleCheckBoxChange}
                      handleSortChange={handleSortChange}
                      handleStoreFilter={handleStoreFilter}
                      onClearAllBtnClick={onClearAllBtnClick}
                      onClearFacetTag={onClearFacetTag}
                      onDepartmentChange={onDepartmentChange}
                      onPaginationButtonClick={onPaginationButtonClick}
                      parameters={parameters}
                      sortData={sortData}
                      stats={stats}
                      storeDetails={storeDetails}
                    />
                  </div>
                )
                : (
                  <SearchDefaultView
                    listItemSubmitHandler={onSearchTermChange}
                    popularSearchesList={popularSearchesList}
                    wrapperId="editable-search-list"
                  >
                    <RecommendationSlider
                      placementId="search_page.m_rr1|search_page.rr1"
                      styles={recsContainerStyles.searchPageRecsWrapper}
                    />
                  </SearchDefaultView>
                )}
            </ProductGridScrollHandler>
          </SearchResultsContext.Provider>
        </DigitalDataProvider>
      </BreakpointProvider>
    </StoreDetailsProvider>
  );
}

SearchResultsPage.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  // Optional props
  countryFulfillmentStore: PropTypes.string,
  departmentId: PropTypes.string,
  initialDepartmentId: PropTypes.string,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  userInputedSearchTerm: PropTypes.string,
  searchTerm: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  store: PropTypes.string,
  storeId: PropTypes.string,
};

export default SearchResultsPage;
