import { shape, string } from 'prop-types';

const imageSetType = shape({
  primaryFaceOutImage: string.isRequired,
  primaryHoverImage: string,
  prodImage: string,
  modelImage: string,
  lifestyleImage: string,
});

export default imageSetType;
