import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { SLIDE_WIDTH, MAX_SLIDES_VISIBLE, ARROW_WIDTH } from '../../tools/constants';
import ArrowButton from './ArrowButton';
import style from './slider.module.scss';

const Slider = ({
  slideContent,
}) => {
  const sliderRef = useRef(null);
  const [showLeftArrowBtn, setShowLeftArrowBtn] = useState(false);
  const [showRightArrowBtn, setShowRightArrowBtn] = useState(
    slideContent.length > MAX_SLIDES_VISIBLE,
  );
  const slideLeft = () => {
    setShowRightArrowBtn(true);
    sliderRef.current.scrollLeft -= sliderRef.current.offsetWidth;
  };
  const slideRight = () => {
    setShowLeftArrowBtn(true);
    sliderRef.current.scrollLeft += sliderRef.current.offsetWidth;
  };

  const handleScroll = () => {
    const { scrollLeft } = sliderRef.current;
    const totalWidth = slideContent.length * SLIDE_WIDTH;
    const minScrollToShowLeft = SLIDE_WIDTH;
    const maxScrollToShowRight = totalWidth - (MAX_SLIDES_VISIBLE * SLIDE_WIDTH) - ARROW_WIDTH;

    setShowLeftArrowBtn(scrollLeft > minScrollToShowLeft);
    setShowRightArrowBtn(scrollLeft < maxScrollToShowRight);
  };

  const slides = slideContent.map((slide) => (
    <div key={slide.key} aria-hidden="true" className={style.slide} tabIndex="">
      <div className="js-product-card-wrapper product-card-wrapper product-card--anf">
        <div className="product-template ds-standard-vertical-example">
          <div className="product-header" />
          <div className="product-image-section">
            {slide.productCard}
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      {showLeftArrowBtn && (<ArrowButton direction="previous" onClick={slideLeft} />)}
      <div
        ref={sliderRef}
        className={`${style.sliderWrapper}`}
        onScroll={handleScroll}
      >
        <div className={style.sliderTrack} style={{ width: `${slideContent.length * SLIDE_WIDTH}px` }}>
          {slides}
        </div>
      </div>
      {showRightArrowBtn && (<ArrowButton direction="next" onClick={slideRight} />)}
    </>
  );
};

Slider.propTypes = {
  slideContent: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    productCard: PropTypes.element,
  })).isRequired,
};

export default Slider;
