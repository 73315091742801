import { SizeTile, SizeTileGroup } from 'anf-core-react';
import {
  arrayOf, bool, func, string, number, shape,
} from 'prop-types';
import React from 'react';

const FilterButtons = ({
  facetName,
  filters,
  onOptionButtonClick,
  selectedFilters,
  size,
}) => (
  <SizeTileGroup
    legend={(
      <span className="screen-reader-text">
        {facetName}
      </span>
    )}
  >
    {filters.map((option) => (
      <SizeTile
        key={option.name}
        id={option.name}
        isChecked={selectedFilters.includes(option.name)}
        isUnavailable={!option.enabled}
        label={(option.name)}
        name={option.name}
        onChange={() => onOptionButtonClick(option.count, option.name)}
        size={size}
        type="radio"
        value={option.name}
      />
    ))}
  </SizeTileGroup>
);

FilterButtons.propTypes = {
  facetName: string,
  filters: arrayOf(shape({
    count: number,
    name: string,
    enabled: bool,
  })),
  onOptionButtonClick: func,
  selectedFilters: arrayOf(string),
  size: string,
};

export default FilterButtons;
