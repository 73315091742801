import Cookies from 'js-cookie';

const useSessionCookies = () => {
  const getCookies = () => Cookies.get() || {};

  const isStorePreview = () => {
    const cookies = getCookies();
    if (!cookies) return false;
    return Object.keys(cookies).some((key) => key.includes('WCP'));
  };

  const getPersistentUserId = (storeId) => {
    const cookies = getCookies();
    const cookie = cookies.WCP_PERSISTENT || cookies.WC_PERSISTENT;
    if (!cookie) return null;
    const match = cookie.match(new RegExp(`${storeId}_[-{0-9}]*[0-9]+`));

    if (!match) return null;

    const userId = match[0].split('_')[1];

    return userId !== '-1002' ? userId : null;
  };

  const pullUserIdAndStoreFromUserActivityCookie = (cookie) => decodeURIComponent(cookie).split(',');

  const getActiveUserId = (storeId) => {
    const cookies = getCookies();
    const cookieSubKey = isStorePreview() ? 'WCP_USERACTIVITY' : 'WC_USERACTIVITY';

    const activityCookies = Object.entries(cookies)
      .filter(([key]) => key.includes(cookieSubKey))
      .map(([, cookie]) => pullUserIdAndStoreFromUserActivityCookie(cookie));

    if (!activityCookies.length) {
      return null;
    }

    const [userId] = activityCookies.find(
      ([, currentStoreId]) => currentStoreId === storeId?.toString(),
    ) || [null];
    return userId === '-1002' ? null : userId;
  };

  const getSessionId = (storeId) => getPersistentUserId(storeId) ?? getActiveUserId(storeId);

  return { getSessionId };
};

export default useSessionCookies;
