import { FACET_DICTIONARY } from './constants';
import getSelectedFacets from './getSelectedFacets';

/**
 * Get single facet from full facet data returned from Search API
 * @param {Array} facetData - All facet data returned from Search API
 * @param {string} facetId - The ID of the facet
 * @returns {Object} - An object containing the facetId, facetName, and filters for the facet
 */
const getIndividualFacet = (facetData, facetId) => {
  const facet = facetData?.find(({ id }) => id === facetId || id === FACET_DICTIONARY[facetId]);
  const facetName = facet?.name;
  const filters = facet?.facetGroups?.flatMap((facetGroup) => facetGroup?.facetValues?.map(
    ({ name, count, enabled }) => ({ name, count, enabled }),
  )) || [];
  return {
    facetId: facet?.id,
    facetName,
    filters,
  };
};

/**
 * Get all applied filters for a specific facet
 * @param {Array} appliedFacets - The facets that are currently applied
 * @param {string} facetId - The ID of the facet
 * @returns {Array} - An array of the applied filters for the facet
 */
const getAppliedFiltersFromFacet = (appliedFacets, facetId) => {
  const appliedFilters = getSelectedFacets(appliedFacets);
  return Array.from(appliedFilters)
    .filter(([id]) => id === facetId)
    .flatMap(([, filters]) => filters);
};

export { getIndividualFacet, getAppliedFiltersFromFacet };
