import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ProductGridContextProvider from '../ProductGridContextProvider';
import style from './ProductGrid.module.scss';

const ProductGrid = ({
  children,
  mobileColumnCount = 2,
  desktopColumnCount = 3,
  breakpoint = 'medium',
}) => (
  <ProductGridContextProvider>
    <ul
      className={classNames(
        'product-grid__products',
        style[`mobile-columns-${mobileColumnCount}`],
        style[`desktop-columns-${desktopColumnCount}-${breakpoint}`],
        style.grid,
      )}
      data-testid="product-grid"
    >
      {children}
    </ul>
  </ProductGridContextProvider>
);

ProductGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  mobileColumnCount: PropTypes.oneOf([1, 2, 3, 4]),
  desktopColumnCount: PropTypes.oneOf([1, 2, 3, 4]),
  breakpoint: PropTypes.oneOf(['medium', 'large']),
};

export default ProductGrid;
