import { Button, Link as LinkAsButton } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

export default function ClearAllButton({ onClick, isBordered = false }) {
  const clearAll = useTranslatedText('clearAll', { fallback: 'clear all' });
  return isBordered
    ? (
      <Button onClick={onClick} variant="tertiary-light">
        {clearAll.value}
      </Button>
    )
    : (
      <LinkAsButton onClick={onClick}>
        {clearAll.value}
      </LinkAsButton>
    );
}

ClearAllButton.propTypes = {
  isBordered: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
