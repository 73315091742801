/**
 * Converts a comma separated string into an Array of strings.
 * @param {any} inputString default value is an Array of the supplied string.
 * @returns {Array} Array of trimmed strings.
 */
const getCommaSeparatedStringValues = (inputString = '') => {
  const normalizedString = inputString.replaceAll(/([，、])/g, ',');

  if (!normalizedString.includes(',')) {
    return [normalizedString];
  }

  return normalizedString.split(',')
    .filter((item) => item !== '')
    .map((item) => item.trim());
};

export default getCommaSeparatedStringValues;
