import PropTypes from 'prop-types';
import React from 'react';
import style from './productCard.module.scss';

function ProductCardBadge({
  text,
  theme = undefined,
}) {
  return (
    <div className={theme === 'earlyAccess' ? style.earlyAccessBadge : style.defaultBadge}>
      {text}
    </div>
  );
}

ProductCardBadge.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default ProductCardBadge;
