import { gql } from '@apollo/client';

const PAGINATION_FRAGMENT = gql`
  fragment PaginationInfo on Pagination {
    currentPage
    totalPages
  }
`;

export default PAGINATION_FRAGMENT;
