import PropTypes from 'prop-types';
import useTranslatedText from '../../hooks/useTranslatedText';

const Text = ({ pairKey, options }) => {
  const { value } = useTranslatedText(pairKey, options);

  return value || null;
};

export default Text;

Text.propTypes = {
  pairKey: PropTypes.string.isRequired,
  options: PropTypes.shape({
    fallback: PropTypes.string,
    replacements: PropTypes.arrayOf(PropTypes.string),
    skip: PropTypes.bool,
  }),
};
