import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

const ProductGridNoResults = () => {
  const productNoResultsError = useTranslatedText('productNoResultsError', { fallback: 'No results found' });
  return (
    <p className="product-grid__no-results-text" data-property={productNoResultsError.key}>
      {productNoResultsError.value}
    </p>
  );
};

export default ProductGridNoResults;
