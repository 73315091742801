import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

const SearchInputField = forwardRef(({
  autoComplete = 'off',
  blurHandler = () => { },
  buttonLabelText = 'Search',
  clearButtonIcon = 'close-circle',
  clearButtonLabelText = 'Clear search',
  clearButtonOnClick,
  describedBy = '',
  id,
  isClearButton = false,
  isFocused = false,
  labelText = 'Search',
  name = '',
  onChange = () => { },
  placeholder = '',
  searchButtonOnClick = () => { },
  value = '',
}, ref) => {
  const inputRef = useRef(ref);
  const clearButtonRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
    clearButtonFocus: () => {
      clearButtonRef.current.focus();
    },
  }));

  return (
    <div className="search-input-field">
      <input
        ref={inputRef}
        aria-describedby={describedBy}
        aria-label={labelText}
        autoComplete={autoComplete}
        data-filled={value ? 'true' : undefined}
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required
        role="searchbox"
        type="text"
        value={value}
      />
      <label
        className="float-label"
        htmlFor={id}
      >
        {labelText}
      </label>
      {isClearButton
        ? (
          <button
            ref={clearButtonRef}
            aria-label={clearButtonLabelText}
            className="input-field-action-button clear-button show-clear-button noBlur"
            data-icon={clearButtonIcon}
            onClick={clearButtonOnClick}
            type="button"
          />
        ) : null}
      {isFocused ? (
        <button
          aria-label="back"
          className="input-field-action-button back-button noBlur"
          data-icon="arrow-left-anf"
          onClick={blurHandler}
          type="button"
        />
      ) : (
        <button
          aria-label={buttonLabelText}
          className="input-field-action-button search-button"
          data-icon="search"
          onClick={searchButtonOnClick}
          type="submit"
        />
      )}
    </div>
  );
});

SearchInputField.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  blurHandler: PropTypes.func,
  buttonLabelText: PropTypes.string,
  clearButtonIcon: PropTypes.string,
  clearButtonLabelText: PropTypes.string,
  clearButtonOnClick: PropTypes.func.isRequired,
  describedBy: PropTypes.string,
  id: PropTypes.string.isRequired,
  isClearButton: PropTypes.bool,
  isFocused: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchButtonOnClick: PropTypes.func,
  value: PropTypes.string,
};

export default SearchInputField;
