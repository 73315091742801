import Button from 'anf-core-react/components/Button';
import IconButton from 'anf-core-react/components/IconButton';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import useTranslatedText from '../../hooks/useTranslatedText';
import style from './productCard.module.scss';

const ProductCardQuickviewButton = ({ onClick }) => {
  const quickview = useTranslatedText('quickview', { fallback: 'Quickview' });
  const { small, medium, large } = useContext(BreakpointContext);

  const icon = 'zoom';
  const label = quickview.value;

  if (medium) {
    return (
      <Button classList={`product-card__button--qv-aui ${style.quickview}`} onClick={onClick} theme="inverse" variant="overlay">
        {large ? <span data-icon={icon} /> : null}
        <span data-aui="product-card-qv-button-text">{label}</span>
      </Button>
    );
  }
  if (small) {
    return (
      <IconButton
        classList="product-card__button--qv-aui"
        icon={icon}
        labelText={label}
        onClick={onClick}
        theme="inverse"
      />
    );
  }
  return null;
};

ProductCardQuickviewButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ProductCardQuickviewButton;
