import { gql, useQuery } from '@apollo/client';
import { Accordion } from 'anf-core-react';
import Icon from 'anf-core-react/components/Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import BrandLinks from '../Footer/FooterGrid/BrandLinks';
import HighContrastToggle from '../Footer/FooterGrid/HighContrastToggle';
import FooterLink from '../Footer/FooterLink';
import OneTrustModalLink from '../Footer/OneTrustModalLink';
import UserPreferences from '../UserPreferences/UserPreferences';
import style from './SmallScreenNavFooter.module.scss';

const getFooterData = gql`
  query GetFooterData {
    footer (isDesktop: false){
      footerLogos {
        id
        url
      }
      helpLinks {
        title
        url
        external
        id
      }
      aboutLinks {
        title
        url
        external
        id
      }
      utilityLinks {
        title
        url
        external
        id
      }
      contactUs {
        title
        url
        external
        id
      }
    }
  }
`;

const SmallScreenNavFooter = ({
  defaultCountry = '',
}) => {
  const ourBrands = useTranslatedText('ourBrands', { fallback: 'Our Brands' });
  const aboutUs = useTranslatedText('aboutUs', { fallback: 'About Us' });
  const help = useTranslatedText('help', { fallback: 'Help' });
  const privacy = useTranslatedText('privacy', { fallback: 'Privacy & Terms' });

  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const accordionClickHandler = (id) => {
    setExpandedAccordion((prevExpandedAccordion) => (prevExpandedAccordion === id ? null : id));
  };

  const { data } = useQuery(getFooterData);
  if (!data) return null;

  const {
    helpLinks, aboutLinks, utilityLinks, contactUs, footerLogos,
  } = data.footer;

  const accordionData = [
    { id: 'help', title: help.value, links: helpLinks },
    { id: 'privacy', title: privacy.value, links: utilityLinks },
    { id: 'aboutUs', title: aboutUs.value, links: aboutLinks },
  ];

  return (
    <div className={classNames(style.footer, 'scope-1892')}>
      {accordionData.map(({ id, title, links }) => (
        <div key={id} className={style.accordionGroup}>
          <Accordion
            headingLevel={2}
            id={`${id}-accordion`}
            isExpanded={expandedAccordion === id}
            onClick={() => accordionClickHandler(id)}
            theme="secondary-light"
            title={<div className={style.accordionTitle}>{title}</div>}
            variant="floating"
          >
            <div>
              <ul className={style.linkList}>
                {links.map((link) => (
                  <li key={link.id}>
                    {link.id === 'personalInfo' ? <OneTrustModalLink className={style.oneTrustModalLink} link={link} /> : <FooterLink link={link} />}
                  </li>
                ))}
              </ul>
            </div>
          </Accordion>
        </div>
      ))}
      <div className={style.contactUs}>
        <a href={contactUs.url}>
          <h2>{contactUs.title}</h2>
        </a>
      </div>
      <div className={style.highContrastToggle}>
        <HighContrastToggle id="high-contrast-toggle-small-screen-nav" />
      </div>
      <div className={style.countrySelector}>
        <UserPreferences defaultCountry={defaultCountry} />
      </div>
      {footerLogos && (
        <>
          <h2
            className={style.brandLinksHeading}
            data-property={ourBrands.key}
          >
            {ourBrands.value}
          </h2>
          <BrandLinks isSmallNav links={footerLogos} />
        </>
      )}
      <div className={style.mooseBackground}>
        <div className={style.moose} data-testid="moose">
          <Icon
            icon="moose"
            size="s"
          />
        </div>
      </div>
    </div>
  );
};

SmallScreenNavFooter.propTypes = {
  defaultCountry: PropTypes.string,
};

export default SmallScreenNavFooter;
