import getSelectedFacets from './getSelectedFacets';

const manipulateFacetMap = (facet, {
  marketingFilterType, facetId, facetValues, elementIsSelected,
}) => {
  const facetMap = getSelectedFacets(facet);
  let checkedFacetValues = [];
  // Clear all filters if multi-select is not enabled
  if (marketingFilterType !== 'multi') {
    facetMap.clear();
  }

  if (!facetMap.has(facetId) && elementIsSelected) {
    facetMap.set(facetId, facetValues);
  } else {
    checkedFacetValues = facetMap.get(facetId);
    facetValues.forEach((value) => {
      if (elementIsSelected && !checkedFacetValues.includes(value)) {
        checkedFacetValues.push(value);
      } else if (!elementIsSelected) {
        checkedFacetValues.splice(checkedFacetValues.indexOf(value), 1);
      }
    });
    if (checkedFacetValues.length === 0) {
      facetMap.delete(facetId);
    } else {
      facetMap.set(facetId, checkedFacetValues);
    }
  }

  return facetMap;
};

export default manipulateFacetMap;
