import { gql } from '@apollo/client';

const DEPARTMENT_FRAGMENT = gql`
  fragment DepartmentFragment on Department {
    id
    name
    defaultCategoryIdBySite
  }
`;

const DEPARTMENT_DATA = gql`
  query DEPARTMENT_DATA {
    departments {
      ...DepartmentFragment
    }
  }
  ${DEPARTMENT_FRAGMENT}
`;

export { DEPARTMENT_DATA, DEPARTMENT_FRAGMENT };

export default DEPARTMENT_DATA;
