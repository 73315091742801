import {
  arrayOf, bool, func, number, oneOfType, shape, string,
} from 'prop-types';
import React from 'react';
import { useLocalStorage } from 'react-use';
import ClickedButtons from './ClickedButtons';
import PreferenceButtons from './PreferenceButtons';
import SuggestionButtons from './SuggestionButtons';

export default function SuggestedFilters({
  addSuggestion = () => {},
  appliedFacets = [],
  clickedSuggestions = new Set(),
  dbfFilters = {},
  hasDiscoverByFacet = false,
  numberOfAffinityButtons = 8,
  numberOfSingleSuggestions = 3,
  numberOfMultiSuggestions = 3,
  onFacetToasterChange = () => {},
  removeSuggestion = () => {},
  searchTerm,
  showTapToDiscover = false,
  suggestionObject = {
    rankedWords: { words: [] },
    rankedPhrases: { phrases: [] },
  },
}) {
  const phraseString = suggestionObject?.rankedPhrases?.phrases?.slice(0, numberOfMultiSuggestions)?.join(' ');
  const showSuggestions = showTapToDiscover && searchTerm.split(' ').length < 4;
  const showPhraseButtons = showSuggestions && phraseString;
  const [affinities] = useLocalStorage('visitor_affinities');

  const clickedButtons = (
    <ClickedButtons
      clickedSuggestions={clickedSuggestions}
      removeSuggestion={removeSuggestion}
    />
  );

  const preferenceButtons = hasDiscoverByFacet && numberOfAffinityButtons > 0 ? (
    <PreferenceButtons
      appliedFacets={appliedFacets}
      dbfFilters={dbfFilters}
      numberOfSuggestions={numberOfAffinityButtons}
      onFacetToasterChange={onFacetToasterChange}
      visitorAffinities={affinities}
    />
  ) : null;

  const wordButtons = showSuggestions && numberOfSingleSuggestions > 0 ? (
    <SuggestionButtons
      addSuggestion={addSuggestion}
      clickedSuggestions={clickedSuggestions}
      dupeCheckString={showPhraseButtons ? phraseString : null}
      numberOfSuggestions={numberOfSingleSuggestions}
      suggestions={suggestionObject?.rankedWords?.words}
    />
  ) : null;

  const phraseButtons = showPhraseButtons && numberOfMultiSuggestions > 0 ? (
    <SuggestionButtons
      addSuggestion={addSuggestion}
      numberOfSuggestions={numberOfMultiSuggestions}
      suggestions={suggestionObject?.rankedPhrases?.phrases}
    />
  ) : null;

  return (
    <>
      {clickedButtons}
      {preferenceButtons}
      {phraseButtons}
      {wordButtons}
    </>
  );
}

SuggestedFilters.propTypes = {
  addSuggestion: func,
  appliedFacets: oneOfType([
    arrayOf(string),
    string,
  ]),
  clickedSuggestions: shape({
    size: number,
  }),
  dbfFilters: shape({
    sizeFilters: shape({
      facetId: string,
      facetName: string,
      filters: arrayOf(shape({
        count: number,
        name: string,
        enabled: bool,
      })),
    }),
  }),
  hasDiscoverByFacet: bool,
  numberOfAffinityButtons: number,
  numberOfSingleSuggestions: number,
  numberOfMultiSuggestions: number,
  onFacetToasterChange: func,
  removeSuggestion: func,
  searchTerm: string.isRequired,
  showTapToDiscover: bool,
  suggestionObject: shape({
    rankedWords: shape({
      words: arrayOf(string),
      wordObjects: arrayOf(shape({
        word: string,
        count: number,
      })),
    }),
    rankedPhrases: shape({
      phrases: arrayOf(string),
      phraseObjects: arrayOf(shape({
        phrase: string,
        count: number,
      })),
    }),
  }),
};
