import { Icon } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import useDigitalData from '../../../hooks/useDigitalData';
import { DD_TIK_TOK_FOOTER_ICON_FLAG } from '../../DigitalDataProvider';
import style from '../footer.module.scss';

const SocialIcons = ({
  links = [{ external: false }],
}) => {
  const {
    [DD_TIK_TOK_FOOTER_ICON_FLAG]: hasTikTokIcon,
  } = useDigitalData([
    DD_TIK_TOK_FOOTER_ICON_FLAG,
  ]);

  const filteredLinks = useMemo(() => (
    hasTikTokIcon ? links : links.filter((link) => link.id !== 'tiktok')),
  [hasTikTokIcon, links]);

  return (
    <ul className={style.social}>
      {filteredLinks.map((link) => (
        <li key={link.id}>
          <a
            aria-label={link.title}
            className={style.iconLink}
            data-property={link.id}
            href={link.url}
            target={link.external && '_blank'}
          >
            <Icon icon={link.icon} size="s" />
          </a>
        </li>
      ))}
    </ul>
  );
};

SocialIcons.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    external: PropTypes.bool,
  })),
};

export default SocialIcons;
