const data = {
  anf: {
    'men\'s': {
      list1: {
        'best dressed guest collection': '/shop/us/mens-best-dressed-guest-weddings-and-events',
        'a&f x trevor project': '/shop/us/pride',
        'gender inclusive': '/shop/us/lookbook-a-and-f-lookbook',
        'trend edit': '/shop/us/search?filtered=true&rows=90&searchTerm=Trend+Edit&start=0&departmentId=12202-ANF',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=Online+Exclusive&start=0&departmentId=12202-ANF',
        'matching set': '/shop/us/mens-coordinated-sets',
        'getaway shop': '/shop/us/mens-summer-clothes',
        'vol. 28': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=Vol.+28',
        'golf shop': '/shop/us/mens-golf-shop',
        'for justice collection': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=For+Justice+Collection',
      },
      list2: {
        '6 inch | 15 cm': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=6+inch',
        'a&f vintage stretch denim': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=A%26F+Vintage+Stretch+Denim',
        'a&f signature stretch denim': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=A%26F+Signature+Stretch+Denim',
        'a&f authentic rigid denim': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=Authentic+Rigid+Denim',
        'a&f basic stretch denim': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=basic+stretch+Denim',
        'a&f signature stretch twill': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=Signature+Stretch+Twill',
        '7 inch | 18 cm': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=7+inch',
        '9 inch | 23 cm': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=9+inch',
        '3 inch | 7 cm': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=3+inch',
      },
    },
    'women\'s': {
      list1: {
        'best dressed guest collection': '/shop/us/womens-best-dressed-guest-weddings-and-events',
        'a&f x trevor project': '/shop/us/pride',
        'gender inclusive': '/shop/us/lookbook-a-and-f-lookbook',
        'trend edit': '/shop/us/search?filtered=true&rows=90&searchTerm=Trend+Edit&start=0&departmentId=12203-ANF',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=Online+Exclusive&start=0&departmentId=12203-ANF',
        'matching set': '/shop/us/womens-matching-sets-dresses-and-rompers',
        'getaway shop': '/shop/us/womens-summer-clothes',
        'vol. 28': '/shop/us/search?departmentCategoryId=12202-ANF&searchTerm=Vol.+28',
        'premium footwear collection': '/shop/us/search?departmentCategoryId=12203-ANF&searchTerm=premium+footwear+collection',
        'abercrombie x tia booth': '/shop/womens-abercrombie-x-tia-booth-new-arrivals',
        'for justice collection': '/shop/us/search?filtered=true&rows=90&searchTerm=For+Justice+Collection&start=0&departmentId=12203-ANF',
      },
      list2: {
        'a&f all day comfort stretch denim': '/shop/us/search?departmentCategoryId=12203-ANF&searchTerm=A%26F+All+Day+Comfort+Stretch+Denim',
        'a&f signature stretch denim': '/shop/us/search?departmentCategoryId=12203-ANF&searchTerm=A%26F+Signature+Stretch+Denim',
        'a&f vintage stretch denim': '/shop/us/search?departmentCategoryId=12203-ANF&searchTerm=A%26F+Vintage+Stretch+Denim',
        'authentic rigid denim': '/shop/us/search?departmentCategoryId=12203-ANF&searchTerm=Authentic+Rigid+Denim',
        'hold-you-in stretch denim': '/shop/us/search?departmentCategoryId=12203-ANF&searchTerm=Hold-You-In+Stretch+Denim',
      },
    },
  },
  kids: {
    boys: {
      list1: {
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=179205-KIDS',
        'matching set': '/shop/us/kids/boys-matching-sets',
        'the cool stuff': '/shop/us/search?filtered=true&rows=90&searchTerm=THE+COOL+STUFF&start=0&departmentId=179205-KIDS',
        'vol. 28': '/shop/us/search?departmentCategoryId=179205-KIDS&searchTerm=Vol.+28',
        'everybody collection': '/shop/us/kids/kids-everybody-collection',
      },
      list2: {},
    },
    girls: {
      list1: {
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=179206-KIDS',
        'matching set': '/shop/us/kids/girls-matching-sets',
        'the cool stuff': '/shop/us/search?filtered=true&rows=90&searchTerm=THE+COOL+STUFF&start=0&departmentId=179206-KIDS',
        'vol. 28': '/shop/us/search?departmentCategoryId=179206-KIDS&searchTerm=Vol.+28',
        'everybody collection': '/shop/us/kids/kids-everybody-collection',
      },
      list2: {},
    },
  },
  hol: {
    'men\'s': {
      list1: {
        'pride collection': '/shop/us/pride-collection',
        'gender inclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=gender+inclusive&start=0&departmentId=12551-HOL',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=12551-HOL',
        'must-have collection': '/shop/us/mens-must-have-collection',
        'all day gameplay': '/shop/us/mens-all-day-gameplay',
        'part of a matching set': '/shop/us/search?departmentCategoryId=12551-HOL&searchTerm=part+of+a+matching+set',
      },
      list2: {},
    },
    'women\'s': {
      list1: {
        'pride collection': '/shop/us/pride-collection',
        'gender inclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=gender+inclusive&start=0&departmentId=12552-HOL',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=12552-HOL',
        'all day gameplay': '/shop/us/mens-all-day-gameplay',
        'part of a matching set': '/shop/us/womens-matching-sets',
      },
      list2: {
        'built in shorts': '/shop/us/search?departmentCategoryId=12552-HOL&searchTerm=Built+in+Shorts',
        'i have pockets': '/shop/us/search?departmentCategoryId=12552-HOL&searchTerm=I+have+pockets',
        'classic stretch': '/shop/us/search?departmentCategoryId=12552-HOL&searchTerm=Classic+Stretch',
        'advanced stretch': '/shop/us/search?departmentCategoryId=12552-HOL&searchTerm=Advanced+Stretch',
        'soft stretch': '/shop/us/search?departmentCategoryId=12552-HOL&searchTerm=Soft+Stretch',
      },
    },
  },
  gh: {
    'men\'s': {
      list1: {
        'pride collection': '/shop/us/gilly-hicks/pride-collection',
        'gender inclusive': '/shop/us/gilly-hicks/unisex',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=65014360-GH',
        'part of a matching set': '/shop/us/search?filtered=true&rows=90&searchTerm=part+of+a+matching+set&start=0&departmentId=65014360-GH',
      },
      list2: {},
    },
    'women\'s': {
      list1: {
        'pride collection': '/shop/us/gilly-hicks/pride-collection',
        'gender inclusive': '/shop/us/gilly-hicks/unisex',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=65014366-GH',
        'part of a matching set': '/shop/us/search?filtered=true&rows=90&searchTerm=part+of+a+matching+set&start=0&departmentId=65014366-GH',
      },
      list2: {},
    },
  },
  st: {
    'men\'s': {
      list1: {
        'gender inclusive': '/shop/us/social-tourist/shop-all-unisex',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=65014738-ST',
      },
      list2: {},
    },
    'women\'s': {
      list1: {
        'gender inclusive': '/shop/us/social-tourist/shop-all-unisex',
        'online exclusive': '/shop/us/search?filtered=true&rows=90&searchTerm=online+exclusive&start=0&departmentId=65014738-ST',
      },
      list2: {},
    },
  },
};

const jsonToMap = (json) => (new Map(Object.entries(json)));

/**
 * @param {string} testSegment
 * @returns {Map<string, Map<string, Map<string, string>}
 */
const computeWhitelist = (testSegment) => {
  const whitelist = new Map();
  Object.entries(data).forEach(([brandKey, brandValue]) => {
    const brandMap = new Map();
    Object.entries(brandValue).forEach(([deptKey, deptValue]) => {
      const { list1, list2 } = deptValue;
      const list = testSegment === 'C' ? { ...list1, ...list2 } : list1;
      const deptMap = jsonToMap(list);
      brandMap.set(deptKey, deptMap);
    });
    whitelist.set(brandKey, brandMap);
  });
  return whitelist;
};

const whitelistB = computeWhitelist('B');
const whitelistC = computeWhitelist('C');

/**
 * @param {string} brand
 * @param {string} deptName
 * @param {string} testSegment
 * @returns {Map<string, string>}
 */
const getDepartmentWhitelist = (brand, deptName, testSegment) => {
  const whitelist = testSegment === 'C' ? whitelistC : whitelistB;
  if (whitelist.has(brand) && whitelist.get(brand).has(deptName)) {
    return whitelist.get(brand).get(deptName);
  }
  return new Map();
};

export default getDepartmentWhitelist;
