function getPreparedFacetArrayParameter(facets = []) {
  return facets.filter((facet) => typeof facet !== 'undefined').map((facet) => {
    if (typeof facet !== 'string') {
      return `${facet.name}:${facet.value}`;
    }
    return facet;
  });
}

module.exports = { getPreparedFacetArrayParameter };
