import { gql, useQuery } from '@apollo/client';
import { DropDown } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

const getSubcategoriesForCategory = gql`
  query CategorySubcategories($categoryId: String!) {
    category(categoryId: $categoryId) {
      categoryId
      hideChildren
      name
      url
      dropdownTopValue
      subCategories {
        categoryId
        displayType
        hideCategory
        name
        url
      }
    }
  }
`;

const CategoryDropdown = ({
  currentCategoryId = '',
  parentCategoryId,
}) => {
  const { data } = useQuery(getSubcategoriesForCategory, {
    variables: {
      categoryId: parentCategoryId,
    },
  });

  const menuButtonCategory = useTranslatedText('menuButtonCategory', { fallback: 'Category' });

  const regularSubcategories = [];
  const clearanceSubcategories = [];
  const featuredSubcategories = [];
  let dropdownDefaultInfo;

  if (!data?.category?.hideChildren) {
    data?.category?.subCategories.forEach(({
      categoryId, displayType, hideCategory, name, url,
    }, index) => {
      if (!hideCategory) {
        switch (displayType) {
          case 'regular':
            regularSubcategories.push({ label: name, value: url });
            break;
          case 'clearance':
            clearanceSubcategories.push({ label: name, value: url });
            break;
          case 'featured':
            featuredSubcategories.push({ label: name, value: url });
            break;
          default:
            break;
        }

        // Check if subcategory matches current page to use as default option
        if (categoryId === currentCategoryId) {
          dropdownDefaultInfo = { value: url, index };
        }
      }
    });
  }

  const dropdownOptions = [
    ...(data?.category?.dropdownTopValue ? [{ label: `${data.category.dropdownTopValue}`, value: data?.category?.url }] : []),
    ...regularSubcategories,
    ...clearanceSubcategories,
    ...featuredSubcategories,
  ];

  const handleChange = (e) => {
    window.location = e.target.value;
  };

  return (
    <DropDown
      id="grid-nav__sub-cat-dropdown"
      label={menuButtonCategory.value}
      onChange={handleChange}
      options={dropdownOptions}
      value={dropdownDefaultInfo?.value}
    />
  );
};

CategoryDropdown.propTypes = {
  /** Category ID for the current page */
  currentCategoryId: PropTypes.string,
  /** Category ID used to generate dropdown options */
  parentCategoryId: PropTypes.string.isRequired,
};

export default CategoryDropdown;
