import { Button } from 'anf-core-react';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import style from './SkipLink.module.scss';

function SkipLink() {
  const linkText = useTranslatedText('skipToContent', { fallback: 'Skip to Content' });

  return (
    <Button classList={style.link} href="#main-content-start" variant="secondary">
      {linkText.value}
    </Button>
  );
}

export default SkipLink;
