import { Button, ProgressBar } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import Loader from '../Loader';
import ProductGridScrollButton from '../ProductGridScrollButton/ProductGridScrollButton';
import style from './gridButtonPagination.module.scss';

function GridButtonPagination({
  buttonTypeNext,
  productTotalCount,
  currentViewed,
  isLoading,
  onButtonClick,
  showProgressBar = false,
}) {
  // Don't disable the whole button just because TMNTs won't load - pagination is vital
  const loadMoreText = useTranslatedText('loadMore', { fallback: 'Load More' });
  const loadPreviousText = useTranslatedText('loadPrevious', { fallback: 'Load Previous' });
  // Progress count text isn't vital
  const progressCountText = useTranslatedText('progressCount',
    {
      fallback: `You've Viewed ${currentViewed} of ${productTotalCount} Products`,
      replacements: [currentViewed, productTotalCount],
    });
  const isEndOfCategory = (currentViewed >= productTotalCount);

  return (
    <div className={style.gridButtonPagination}>
      {progressCountText && <div className={style.progressText}>{progressCountText.value}</div>}
      {showProgressBar && (
        <ProgressBar currentValue={currentViewed} maximumValue={productTotalCount} />
      )}
      <>
        {!buttonTypeNext && (
          <Button
            isDisabled={isLoading}
            onClick={onButtonClick}
            type="button"
            variant="tertiary-dark"
          >
            <span data-aui="grid-pagination-button-text">{isLoading ? <Loader variant="dots" /> : loadPreviousText.value}</span>
          </Button>
        )}
        {(buttonTypeNext && !isEndOfCategory) && (
          <Button
            isDisabled={isLoading}
            onClick={onButtonClick}
            type="button"
            variant="tertiary-dark"
          >
            <span data-aui="grid-pagination-button-text">{isLoading ? <Loader variant="dots" /> : loadMoreText.value}</span>
          </Button>
        )}
        {(buttonTypeNext && isEndOfCategory) && <ProductGridScrollButton />}
      </>
    </div>
  );
}
GridButtonPagination.propTypes = {
  buttonTypeNext: PropTypes.bool.isRequired,
  currentViewed: PropTypes.number.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  productTotalCount: PropTypes.number.isRequired,
  showProgressBar: PropTypes.bool,
};

export default GridButtonPagination;
