import { gql } from '@apollo/client';

const productsFragment = gql`
  fragment Products on Product {
    id
    gender
    name
    badges {
      text
      theme
    }
    price {
      description
      originalPrice
      discountPrice
      discountText
      variant
    }
    memberPrice {
      description
      originalPrice
      discountText
      variant
    }
    imageSet {
      primaryFaceOutImage
      primaryHoverImage
      prodImage
      modelImage
    }
    shortDescriptors
    promoMessaging {
      type
      message
    }
    partNumber
    swatchList {
      id
      name
      product {
        id
        imageSet {
          primaryFaceOutImage
        }
      }
      swatchImage
    }
    defaultSwatchSequence
    collection
    kic
    departmentName
    productPageUrl
  }
`;

export default productsFragment;
