import { DropDown } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import BreakpointContext from '../../context/breakpoint';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';

function SortBy({
  onSortChange = () => {},
  selectedSort = '',
  sortData = {
    sortOptions: [],
  },
}) {
  const sortBy = useTranslatedText('sortBy', { fallback: 'Sort By' });
  const elementChangeWarning = useTranslatedText('elementChangeWarning', { fallback: 'Activating this element will cause content on the page to be updated.' });
  const breakpoints = useContext(BreakpointContext);
  const { large: isDesktop } = breakpoints;
  const [sortValue, setSortValue] = useState(selectedSort);

  useUpdateEffect(() => {
    setSortValue(selectedSort);
  }, [selectedSort]);

  if (sortData.sortOptions.length < 1) return null;

  const dropDownOptions = sortData.sortOptions
    .filter((item) => item.value.length)
    .map((item) => (
      { label: item.value, value: item.id }
    ));
  const sortDropdownId = (isDesktop) ? 'sort-by-dropdown-largescreen' : 'sort-by-dropdown-smallscreen';

  const sortChangeHandler = (event) => {
    setSortValue(event.target.value);
    onSortChange(event);
  };

  return (
    <>
      <h2 aria-live="assertive" className="screen-reader-text">
        <TmntHtml
          property={elementChangeWarning.key || ''}
          value={elementChangeWarning.value || ''}
        />
      </h2>
      <div data-testid="sort-by-dropdown">
        <DropDown
          id={sortDropdownId}
          label={sortBy.value}
          onChange={sortChangeHandler}
          options={dropDownOptions}
          value={sortValue}
        />
      </div>
    </>
  );
}

SortBy.propTypes = {
  onSortChange: PropTypes.func,
  selectedSort: PropTypes.string,
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
};

export default SortBy;
