const getMySavesFromLocalStorage = () => {
  const savesListInStorage = localStorage.getItem('savesList');
  let mySavesObject = null;

  if (savesListInStorage) {
    mySavesObject = JSON.parse(savesListInStorage);
    if (mySavesObject.timeStamp <= (new Date()).getTime()) {
      mySavesObject = null;
    }
  }

  return mySavesObject;
};

module.exports = getMySavesFromLocalStorage;
