import { Link, Logo } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import style from '../footer.module.scss';

const BrandLinks = ({
  isSmallNav = true,
  links,
}) => (
  <ul className={isSmallNav ? style.smallNavBrandLinksList : style.brandLinksList}>
    {links.map((brand) => (
      <li key={brand.id}>
        <Link href={brand.url} target="_blank">
          <Logo kind={brand.id} />
        </Link>
      </li>
    ))}
  </ul>
);

BrandLinks.propTypes = {
  isSmallNav: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

export default BrandLinks;
