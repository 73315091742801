import { useRef, useEffect } from 'react';
import { useIntersection } from 'react-use';
import { SCROLL_THRESHOLD } from './constants';

const useFirstScrollIntoView = (onFirstScrollIntoView) => {
  const ref = useRef();
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: `${SCROLL_THRESHOLD} 0px ${SCROLL_THRESHOLD} 0px`,
    threshold: 0,
  });

  const hasScrolledIntoView = useRef();
  useEffect(() => {
    if (!hasScrolledIntoView.current && intersection?.isIntersecting && onFirstScrollIntoView) {
      onFirstScrollIntoView();
      hasScrolledIntoView.current = true;
    }
  }, [intersection, onFirstScrollIntoView]);

  return { ref };
};

export default useFirstScrollIntoView;
