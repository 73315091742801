import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import useDesignSystemInit from '../../hooks/useDesignSystemInit';
import useMarketingFilters from '../../hooks/useMarketingFilters';

const query = gql`
  query cmcContent($espotId: String!, $page: String!, $categoryId: String) {
    content: cmcContent(name: $espotId, page: $page, categoryId: $categoryId ) {
      htmlContent
    }
  }
`;

export default function SingleCmcEspot({
  categoryId = '',
  espotId,
  page = 'CategoryDisplay',
}) {
  const { data } = useQuery(query, {
    variables: { espotId, categoryId, page },
  });

  const htmlContent = data?.content?.htmlContent;
  const espotRef = useRef(null);

  useDesignSystemInit(espotRef);

  useMarketingFilters(espotRef, espotId);

  const genericContentClass = (!htmlContent)
    ? 'genericESpot genericESpot--empty'
    : 'genericESpot js-aem-content aem-content aem-enabled';

  return (
    <div
      ref={espotRef}
      className={genericContentClass}
      dangerouslySetInnerHTML={{ __html: htmlContent ?? '' }}
      data-espotname={espotId}
      id={espotId}
    />
  );
}

SingleCmcEspot.propTypes = {
  categoryId: PropTypes.string,
  espotId: PropTypes.string.isRequired,
  page: PropTypes.string,
};
