import { useEffectOnce } from 'react-use';

const usePersistedSearchTerm = (
  setSearchTerm,
  getSuggestions,
  setListPosition,
  useGenderFlyoutTabs,
) => {
  useEffectOnce(() => {
    const persistedSearchTerm = (new URLSearchParams(window.location.search)).get('searchTerm');
    if (persistedSearchTerm) {
      setSearchTerm(() => persistedSearchTerm);
      getSuggestions({
        variables: { searchTerm: persistedSearchTerm, genderTabsFlag: useGenderFlyoutTabs },
      });
      setListPosition(-1);
    }
  });
};

export default usePersistedSearchTerm;
