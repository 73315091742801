import PropTypes from 'prop-types';
import React, {
  forwardRef,
} from 'react';
import SearchResultsContext from '../../context/SearchResultsContext';
import {
  EDITABLE_SEARCH_SCROLL_OFFSET,
  HEADER_SCROLL_OFFSET,
} from '../../tools/constants';
import BreakpointProvider from '../BreakpointProvider';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
} from '../DigitalDataProvider';
import ProductGridScrollHandler from '../ProductGridScrollHandler';
import RecommendationSlider from '../RecommendationSlider/RecommendationSlider';
import recsContainerStyles from '../RecommendationSlider/recommendationSlider.module.scss';
import { usePopularSearches } from '../SearchBox/hooks/useQueries';
import SearchDefaultView from '../SearchDefaultView';
import SearchGridWrapper from './SearchGridWrapper';

const SearchMainContent = forwardRef(({
  editableSearchFlag = true,
  elasticDepartmentData = null,
  facets = [],
  gridIntersecting = false,
  handleCheckBoxChange,
  handleSortChange,
  handleStoreFilter,
  onClearAllBtnClick,
  onClearFacetTag = () => { },
  onDepartmentChange,
  onPaginationButtonClick = () => { },
  onSearchTermChange,
  parameters = {},
  searchGridData = {},
  sortData = {},
  stats = {},
  storeDetails = { storeName: '', storeId: '' },
}, ref) => {
  const popularSearchesList = usePopularSearches();

  // derived state:
  const userInitiatedAsyncSearch = parameters.submitMethod === 'user-initiated';

  return (
    <div className="product-grid-container show-promo-badge scope-1892">
      <BreakpointProvider>
        <DigitalDataProvider
          keys={[
            DD_DISABLE_SWATCH_HOVER,
            DD_HYPERLINK_DESC,
            DD_MODEL_IMAGERY_TEST,
          ]}
        >
          <SearchResultsContext.Provider value={{ userInitiatedAsyncSearch }}>
            <ProductGridScrollHandler
              parameters={parameters}
              scrollOffset={editableSearchFlag === false
                ? HEADER_SCROLL_OFFSET : EDITABLE_SEARCH_SCROLL_OFFSET}
            >
              {editableSearchFlag === false || parameters.searchTerm !== ''
                ? (
                  <div ref={ref}>
                    <SearchGridWrapper
                      editableSearchFlag={editableSearchFlag}
                      elasticDepartmentData={elasticDepartmentData}
                      facets={facets}
                      gridIntersecting={gridIntersecting}
                      handleCheckBoxChange={handleCheckBoxChange}
                      handleSortChange={handleSortChange}
                      handleStoreFilter={handleStoreFilter}
                      onClearAllBtnClick={onClearAllBtnClick}
                      onClearFacetTag={onClearFacetTag}
                      onDepartmentChange={onDepartmentChange}
                      onPaginationButtonClick={onPaginationButtonClick}
                      parameters={parameters}
                      searchGridData={searchGridData}
                      sortData={sortData}
                      stats={stats}
                      storeDetails={storeDetails}
                    />
                  </div>
                )
                : (
                  <SearchDefaultView
                    listItemSubmitHandler={onSearchTermChange}
                    popularSearchesList={popularSearchesList}
                    wrapperId="editable-search-list"
                  >
                    <RecommendationSlider
                      placementId="search_page.m_rr1|search_page.rr1"
                      styles={recsContainerStyles.searchPageRecsWrapper}
                    />
                  </SearchDefaultView>
                )}
            </ProductGridScrollHandler>
          </SearchResultsContext.Provider>
        </DigitalDataProvider>
      </BreakpointProvider>
    </div>
  );
});

SearchMainContent.propTypes = {
  // Required props
  // Optional props
  editableSearchFlag: PropTypes.bool,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
  facets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  gridIntersecting: PropTypes.bool,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleSortChange: PropTypes.func,
  handleStoreFilter: PropTypes.func.isRequired,
  onClearAllBtnClick: PropTypes.func.isRequired,
  onClearFacetTag: PropTypes.func,
  onDepartmentChange: PropTypes.func.isRequired,
  onPaginationButtonClick: PropTypes.func,
  onSearchTermChange: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    brand: PropTypes.string,
    searchTerm: PropTypes.string,
    countryFulfillmentStore: PropTypes.string,
    departmentId: PropTypes.string,
    facet: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    filter: PropTypes.string,
    rows: PropTypes.string,
    sort: PropTypes.string,
    start: PropTypes.string,
  }),
  searchGridData: PropTypes.shape({
    loading: PropTypes.bool,
    pagination: PropTypes.shape({
      currentPage: PropTypes.number,
      totalPages: PropTypes.number,
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.number,
      }),
      productImage: PropTypes.shape({
        altText: PropTypes.string,
        url: PropTypes.string,
      }),
      productPageUrl: PropTypes.string,
    })),
  }),
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  stats: PropTypes.shape({
    refinedTotal: PropTypes.number,
    total: PropTypes.number,
  }),
  storeDetails: PropTypes.shape({
    storeId: PropTypes.string,
    storeName: PropTypes.string,
  }),
};

export default SearchMainContent;
