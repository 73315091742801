import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import style from './MiniGridHeader.module.scss';

function MiniGridFilters({ facetValues, handleClick }) {
  const filterButtons = [];

  facetValues.forEach((facetValue) => {
    filterButtons.push(
      <Button
        key={facetValue.id}
        index={facetValue.id}
        onClick={handleClick}
        type="button"
        value={facetValue.id}
        variant="tertiary-light"
      >
        {facetValue.name}
      </Button>,
    );
  });

  return (
    <div aria-label="Filter Options" className={style.miniGridFilterBar} role="group">
      {filterButtons}
    </div>
  );
}

MiniGridFilters.propTypes = {
  facetValues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default MiniGridFilters;
