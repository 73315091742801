import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import StoreDetailsContext from '../context/StoreDetailsContext';
import getMySavesFromLocalStorage from '../tools/getMySavesFromLocalStorage';
import fetchGuestSession from '../tools/userState/fetchGuestSession';
import useUserState from '../tools/userState/useUserState';
import $window from '../tools/window';

export const addToListMutation = gql`
mutation addToMySaves(
  $productId: String!, 
  $kic: String!, 
  $collectionId: String, 
  $swatchSequence: String, 
  $longSku: String, 
  $colorCode: String
) {
  addToMySaves(
    productId: $productId, 
    kic: $kic, 
    collectionId: $collectionId, 
    swatchSequence: $swatchSequence, 
    longSku: $longSku, 
    colorCode: $colorCode
  ) {
    success
    isFirstTimeSave
    errors {
      message {
          key
          value
      }
      status
    }
  }
}
`;

export const removeFromListMutation = gql`
mutation removeFromMySaves(
  $productId: String!, 
  $kic: String!, 
  $collectionId: String, 
  $swatchSequence: String, 
  $longSku: String, 
  $colorCode: String
) {
  removeFromMySaves(
    productId: $productId, 
    kic: $kic, 
    collectionId: $collectionId, 
    swatchSequence: $swatchSequence, 
    longSku: $longSku, 
    colorCode: $colorCode
  ) {
    success
    errors {
      message {
          key
          value
      }
      status
    }
  }
}
`;

const getStoreAttributeData = gql`
  query StoreAttribute {
    config {
      hasMfeMySavesEnabled: genericType(name: "hasMfeMySavesEnabled")
    }
  }
`;

function getSettings(addToList, removeFromList) {
  return {
    ADDING: {
      mutationToMake: addToList,
      listDataType: 'addToMySaves',
      analyticsVariation: 'addTo',
    },
    REMOVING: {
      mutationToMake: removeFromList,
      listDataType: 'removeFromMySaves',
      analyticsVariation: 'removeFrom',
    },
  };
}

const useUpdateMySaves = ({
  productId,
  kic,
  collectionId,
  swatchSequence,
  longSku,
  colorCode,
  price,
  brand,
  categoryId,
  name,
  isMiniGrid,
  mySavesItems,
  refetch,
}) => {
  const [isCurrentProductSaved, setIsCurrentProductSaved] = useState(false);

  const [isSaved, setSaved] = useState(typeof mySavesItems[productId] !== 'undefined');

  const { data: storeAttributeData } = useQuery(getStoreAttributeData);
  const hasMfeMySavesEnabled = storeAttributeData?.config?.hasMfeMySavesEnabled?.value;

  const { store, storeId } = useContext(StoreDetailsContext);

  const { isGenericUser } = useUserState(storeId);

  const [addToList] = useMutation(addToListMutation);
  const [removeFromList] = useMutation(removeFromListMutation);
  const settings = useMemo(() => getSettings(addToList, removeFromList),
    [addToList, removeFromList]);

  useEffect(() => {
    if (!hasMfeMySavesEnabled) {
      const mySavesObject = getMySavesFromLocalStorage();
      const savedStatus = !!(mySavesObject?.list?.[productId]);
      setIsCurrentProductSaved(savedStatus);
    } else {
      setSaved(typeof mySavesItems[productId] !== 'undefined');
    }
  }, [hasMfeMySavesEnabled, productId, mySavesItems]);

  const sendAnalyticsData = (analyticTriggerVariation) => {
    $window?.dispatchEvent(
      new CustomEvent(`analytics.${analyticTriggerVariation}WishListSuccess`, { detail: { collectionId, longSku, colorCode } }),
    );

    if ($window.digitalData) {
      const eventData = isMiniGrid ? {
        brand: brand ?? 'null',
        category_id: categoryId ?? 'null',
        collection_id: collectionId ?? 'null',
        key_item_color: kic ?? 'null',
        long_sku: 'null',
        price_flag: price.priceFlag ?? 'null',
        price_list_local: price.originalPrice ?? 'null',
        price_list_usd: 'null',
        price_offer_local: price.discountPrice ?? 'null',
        price_offer_usd: 'null',
        product_id: productId ?? 'null',
        product_name: name ?? 'null',
        short_sku: 'null',
      } : {
        product_id: productId ?? 'null',
        collection_id: collectionId ?? 'null',
        price_flag: price.priceFlag ?? 'null',
        key_item_color: kic ?? 'null',
        price_list_local: price.originalPrice ?? 'null',
        price_offer_local: price.discountPrice ?? 'null',
        short_sku: 'null',
        long_sku: longSku ?? 'null',
        product_name: name ?? 'null',
        price_list_usd: 'null',
        price_offer_usd: 'null',
      };

      $window.digitalData.trigger(analyticTriggerVariation === 'addTo' ? 'analytics.save_for_later' : 'analytics.remove_from_saves', eventData);
    }
  };

  const executeMutation = () => {
    const setting = isSaved ? settings.REMOVING : settings.ADDING;
    setSaved((currIsSaved) => !currIsSaved);

    setting.mutationToMake({
      variables: {
        productId, kic, collectionId, swatchSequence, longSku, colorCode,
      },
      onCompleted: async (data) => {
        await refetch();

        if (setting.listDataType === 'addToMySaves') {
          $window.dispatchEvent(new CustomEvent('crs:mySaveSuccess',
            { detail: { isFirstTimeSave: data[setting.listDataType].isFirstTimeSave } }));
        }

        sendAnalyticsData(setting.analyticsVariation);
      },
      onError: () => {
        setSaved((currIsSaved) => !currIsSaved);
      },
    });
  };

  const handleSavesClick = async () => {
    try {
      if (isGenericUser()) await fetchGuestSession(store);
      executeMutation();
    // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const handleSavesClickLegacy = () => {
    const eventType = isCurrentProductSaved ? `mfe:productRemoveSave${isMiniGrid ? 'MiniGrid' : ''}` : `mfe:productAddSave${isMiniGrid ? 'MiniGrid' : ''}`;
    const saveEvent = new CustomEvent(eventType, {
      detail: {
        productId,
        kic,
        collectionId,
        swatchSequence,
        longsku: '',
        colorCode,
      },
    });
    $window.dispatchEvent(saveEvent);

    if (isMiniGrid && $window.digitalData) {
      const eventData = {
        brand: brand ?? 'null',
        category_id: categoryId ?? 'null',
        collection_id: collectionId ?? 'null',
        key_item_color: kic ?? 'null',
        long_sku: 'null',
        price_flag: price.priceFlag ?? 'null',
        price_list_local: price.originalPrice ?? 'null',
        price_list_usd: 'null',
        price_offer_local: price.discountPrice ?? 'null',
        price_offer_usd: 'null',
        product_id: productId ?? 'null',
        product_name: name ?? 'null',
        short_sku: 'null',
      };
      $window.digitalData.trigger(isCurrentProductSaved ? 'analytics.remove_from_saves' : 'analytics.save_for_later', eventData);
    }
    setIsCurrentProductSaved(!isCurrentProductSaved);
  };

  return hasMfeMySavesEnabled ? {
    isSaved,
    handleSavesClick,
  } : {
    isSaved: isCurrentProductSaved,
    handleSavesClick: handleSavesClickLegacy,
  };
};

export default useUpdateMySaves;
