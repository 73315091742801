import $window from './window';

function getDigitalDataSubscriptionPromise(keyName, waitTime = 0) {
  let timeoutId = null;
  let resolver;
  let rejector;
  const thePromise = new Promise((res, rej) => {
    resolver = res;
    rejector = rej;
  });
  const subscription = $window.digitalData.subscribe(keyName,
    function keySubscriptionHandler(value) {
      if (typeof value !== 'undefined') {
        this.unsubscribe();
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
        resolver(value);
      }
    });
  timeoutId = setTimeout(() => {
    subscription?.unsubscribe();
    rejector();
  }, waitTime);
  return thePromise;
}

export default getDigitalDataSubscriptionPromise;
