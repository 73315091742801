import { collectOnLoadAnalyticsData, runAnalyticsOnLoad } from '@xp-utilities/web';
import { Tabs } from 'anf-core-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import style from './CategoryRecommendations.module.scss';
import CategoryRecommendationsPanel from './CategoryRecommendationsPanel';

const isTitlePlacement = ({ id }) => id === 'home_page.rr8' || id === 'home_page.m_rr8';

function CategoryRecommendationsTabs({
  placementData, mvtDetailed, rcs, userId,
}) {
  const initialTabOrder = placementData
    .filter((placement) => !isTitlePlacement(placement))
    .map(({ id }) => id);
  const [selectedTab, setSelectedTab] = useState(initialTabOrder[0]);
  const titlePlacement = placementData.find(isTitlePlacement);
  const title = titlePlacement?.title || 'Picked For You';

  useEffect(() => {
    /*
      Only checking for mvtDetailed here as, per notes in collectOnLoadAnalyticsData,
      "expecting rcs and userId to be null when consent isn't given by the BFF"
    */
    if (mvtDetailed) {
      const mvtDetailedArray = mvtDetailed?.map((MVTDetails) => ({
        control: MVTDetails.control === null ? '' : MVTDetails.control,
        eligible: MVTDetails.eligible === null ? '' : MVTDetails.eligible,
        testId: MVTDetails.testId === null ? '' : MVTDetails.testId,
        treatmentId: MVTDetails.treatmentId === null ? '' : MVTDetails.treatmentId,
        treatmentName: MVTDetails.treatmentName || '',
      }));

      const onLoadAnalyticsObj = { mvtDetailed: mvtDetailedArray, rcs, userId };

      const analyticsData = collectOnLoadAnalyticsData(onLoadAnalyticsObj);
      runAnalyticsOnLoad(analyticsData);
    }
  }, [mvtDetailed, rcs, userId]);

  const handleKeyDown = (event) => {
    const currentIndex = initialTabOrder.indexOf(selectedTab);
    if (event.key === 'ArrowRight' && currentIndex < initialTabOrder.length - 1) {
      setSelectedTab(initialTabOrder[currentIndex + 1]);
    } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
      setSelectedTab(initialTabOrder[currentIndex - 1]);
    }
  };

  const handleTabClick = (id, tabTitle) => {
    setSelectedTab(id);
    if (window.digitalData) {
      window.digitalData.trigger('universal_click', {
        event_name: 'click',
        data_text: `category recs - ${tabTitle}: click`,
        data_action: 'click',
      });
    }
  };

  const categoryTabs = () => placementData
    .filter((placement) => !isTitlePlacement(placement))
    .map(({ id, title: tabTitle }) => ({
      ariaControls: `${id}-tabpanel`,
      id: `${id}-tab`,
      isSelected: selectedTab === id,
      label: tabTitle,
      onClick: () => handleTabClick(id, tabTitle),
    }));

  return (
    <div className={classNames('scope-1892', style.container)}>
      <h1 className={style.recommendationsTitle}>{title}</h1>
      <Tabs
        controls={{
          onKeyDown: handleKeyDown,
          tabs: categoryTabs(),
        }}
        labelText="Category Recommendations"
      >
        {placementData
          .filter((placement) => !isTitlePlacement(placement))
          .map((placement) => (
            <CategoryRecommendationsPanel
              key={placement.id}
              placement={placement}
              selectedTab={selectedTab}
            />
          ))}
      </Tabs>
    </div>
  );
}

export default CategoryRecommendationsTabs;

CategoryRecommendationsTabs.propTypes = {
  placementData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ).isRequired,
  mvtDetailed: PropTypes.arrayOf(
    PropTypes.shape({
      control: PropTypes.bool,
      eligible: PropTypes.bool,
      testId: PropTypes.number,
      treatmentId: PropTypes.number,
      treatmentName: PropTypes.string,
    }),
  ),
  rcs: PropTypes.string,
  userId: PropTypes.string,
};
