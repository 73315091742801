import PropTypes from 'prop-types';
import React from 'react';

export default function CmcEspotCard({
  name,
  content = '',
}) {
  return (
    (content)
      ? (
        <li
          className="genericESpot category--header__ems"
          data-espotname={name}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </li>
      )
      : (
        <li
          className="genericESpot genericESpot--empty"
          data-espotname={name}
        />
      )
  );
}

CmcEspotCard.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string,
};
