import { gql, useQuery } from '@apollo/client';
import Logo from 'anf-core-react/components/Logo';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import useTranslatedText from '../../hooks/useTranslatedText';
import getMyListLink from '../../tools/getMyListLink';
import BreakpointProvider from '../BreakpointProvider/BreakpointProvider';
import DigitalDataProvider, {
  DD_SHOW_ANF_NAVIGATION_L3S_TEST,
} from '../DigitalDataProvider';
import GmsEspotBanner from '../GmsEspotBanner/GmsEspotBanner';
import MyListButton from '../Header/MyListButton';
import MyListNotifications from '../Header/MyListNotifications';
import SearchDataProvider from '../SearchBox/SearchDataProvider';
import Nav from '../SmallScreenNav';
import style from './SmallScreenNavBar.module.scss';
import SmallScreenNavMenu from './SmallScreenNavMenu';

const getData = gql`
  query SmallScreenNavBarData($categoryId: String!) {
    category(categoryId: $categoryId) {
      brandLogoType
      url
    }
  }
`;

const SmallScreenNavBar = ({
  brand,
  categoryId,
  country: defaultCountry = '',
  catalogId,
  langId,
  storeId,
  urlRoot,
}) => {
  const myListLink = getMyListLink(storeId, catalogId, langId);
  const openMenu = useTranslatedText('openMenu', { fallback: 'Open Menu' });
  const openMyList = useTranslatedText('openMyList', { fallback: 'View My List' });
  const { data } = useQuery(getData, {
    variables: {
      categoryId,
    },
  });
  const [isSticky, setSticky] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [firstTimeSave, setFirstTimeSave] = useState(false);
  const { y } = useWindowScroll();
  const handleNavToggle = () => setNavOpen((navState) => !navState);

  useEffect(() => {
    /* Other components still in CRS likely need this class to know when the nav is open */
    const { body } = document;
    if (navOpen) {
      body.classList.add('rs-nav--open');
      const event = new Event('render-signin-block');
      document.dispatchEvent(event);
    } else {
      body.classList.remove('rs-nav--open');
    }
  }, [navOpen]);

  useEffect(() => {
    const handleMySaveSuccess = (event) => {
      setShowNotification(true);
      setFirstTimeSave(event.detail?.isFirstTimeSave);
      setTimeout(() => {
        setShowNotification(false);
        setFirstTimeSave(false);
      }, 8000);
    };

    window.addEventListener('crs:mySaveSuccess', handleMySaveSuccess);

    return () => {
      window.removeEventListener('crs:mySaveSuccess', handleMySaveSuccess);
    };
  }, []);

  useEffect(() => {
    // DOM structure prevents us from setting position sticky on the nav
    // So we'll do it the old fashioned way
    // Brand tabs are 60px tall
    if (y > 60) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [y]);

  return (
    <DigitalDataProvider
      keys={[
        DD_SHOW_ANF_NAVIGATION_L3S_TEST,
      ]}
    >
      <BreakpointProvider>
        <div className="scope-1892">
          <header
            className={classNames(
              style.navBar,
              { [style.sticky]: isSticky },
            )}
          >
            {/* Left-aligned icons */}
            <div className={style.left}>
              <div
                className={style.toggle}
                data-testid="open-nav-menu-btn"
              >
                {/* Hamburger menu */}
                <SmallScreenNavMenu
                  labelText={openMenu.value}
                  onClick={handleNavToggle}
                />
              </div>
              {/* Heart Icon for Saves List */}
              <MyListButton
                isActive={showNotification}
                label={openMyList.value}
                myListLink={myListLink}
              />
              {/* My Saves Notification  */}
              {showNotification && (
              <MyListNotifications
                brand={brand}
                createAccountLink={`/shop/AccountRouterView?storeId=${storeId}&isUserLogin=false&catalogId=${catalogId}&langId=${langId}`}
                isFirstTimeSave={firstTimeSave}
                myListLink={myListLink}
                signInLink={`/shop/AccountRouterView?storeId=${storeId}&isUserLogin=true&catalogId=${catalogId}&langId=${langId}`}
              />
              )}
            </div>
            {/* Center-aligned logo */}
            {
              !!data?.category
                && (
                  <div className={style.center}>
                    <a aria-label={brand} className={style.logo} href={data.category.url}>
                      <Logo kind={data.category.brandLogoType} />
                    </a>
                  </div>
                )
            }
            <div className={style.right}>
              <SearchDataProvider brand={brand} country={defaultCountry} searchUrl={`${urlRoot}/search`} />
              <section id="checkout-mfe-mobile-minibag-portal" />
            </div>
          </header>
          <Nav
            brand={brand}
            defaultCountry={defaultCountry}
            handleNavToggle={handleNavToggle}
            l0CategoryId={categoryId}
            navOpen={navOpen}
          />
        </div>
        <div className={style.gmsBanner}>
          <GmsEspotBanner brand={brand} />
        </div>
        <div id="main-content-start" />
      </BreakpointProvider>
    </DigitalDataProvider>
  );
};

SmallScreenNavBar.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  country: PropTypes.string,
  catalogId: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  urlRoot: PropTypes.string.isRequired,
};

export default SmallScreenNavBar;
