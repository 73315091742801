import isEmpty from 'lodash/isEmpty';

const isMobilePlacementId = (id) => id.split('.').at(-1).startsWith('m_');

/**
 * Parse pipe-delimited string of placement IDs into array of placement IDs based
 * on breakpoints
 */
const parsePlacementIds = (placementIds, breakpoints) => {
  if (isEmpty(breakpoints)) {
    return [];
  }

  return placementIds.split('|').reduce((ids, id) => {
    if (
      !isEmpty(id)
      && (
        breakpoints.medium
          ? !isMobilePlacementId(id)
          : isMobilePlacementId(id)
      )) {
      ids.push(id);
    }

    return ids;
  }, []);
};

export default parsePlacementIds;
