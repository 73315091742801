import { gql, useQuery } from '@apollo/client';

const HELP_LINKS = gql`
  query HelpLinks {
    helpLinks {
        id
        title
        url
    }
  }
`;

const useHelpTopics = (isEnabled = false) => {
  const { data, loading, error } = useQuery(HELP_LINKS, {
    skip: !isEnabled,
  });

  if (loading || error) return null;

  return data;
};

export default useHelpTopics;
