import { useQuery, useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import useTranslatedText from '../../../hooks/useTranslatedText';
import {
  COMPLETIONS_V3,
  GENDER_LIST,
  GENDER_PRESERVATION,
} from '../queries';
import getCommaSeparatedStringValues from '../tools/getCommaSeparatedStringValues';

/**
 * Returns the Brand Specific Popular Searches TMNT. Abercrombie Popular
 * Searches are returned if no brand is provided.
 * @returns {any} Array of brand Specific Popular searches spliced from TMNT
 * or null if the TMNT is blank.
 */
const usePopularSearches = () => {
  const popularSearches = useTranslatedText('popularSearches');
  const popularSearchesList = popularSearches?.value;

  return popularSearchesList ? (getCommaSeparatedStringValues(popularSearchesList)) : [];
};

const useSuggestions = () => {
  const [suggestions, setSuggestions] = useState({});
  const [getSuggestions] = useLazyQuery(COMPLETIONS_V3, {
    ssr: false,
    onCompleted: (autoSuggestions) => {
      const productTypes = autoSuggestions?.productTypes.suggestedItems || [];
      const products = autoSuggestions?.products.suggestedItems || [];
      const combinedSuggestions = [...productTypes, ...products];

      setSuggestions({
        ...suggestions,
        productTypes,
        products,
        combinedSuggestions,
      });
    },
  });
  return [getSuggestions, suggestions];
};

const useGenderList = (useGenderFlyoutTabs) => {
  const { loading, error, data: genderListData } = useQuery(GENDER_LIST, {
    skip: !useGenderFlyoutTabs,
  });
  if (loading || error) return [];

  return genderListData?.genderList ?? [];
};

const useDepartmentPreservation = () => {
  const { loading, error, data: storeAttributesData } = useQuery(GENDER_PRESERVATION);
  if (loading || error) return false;

  const {
    hasGenderPreservationEnabled,
    hasElasticSearchAPIEnabled,
  } = storeAttributesData.config ?? {
    hasGenderPreservationEnabled: { value: false },
    hasElasticSearchAPIEnabled: { value: false },
  };

  // We are only able to support this feature if Elastic Search is enabled.
  return hasGenderPreservationEnabled.value && hasElasticSearchAPIEnabled.value;
};

export {
  usePopularSearches,
  useSuggestions,
  useGenderList,
  useDepartmentPreservation,
};
