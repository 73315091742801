import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

function DomNodePortal({ targetNodeSelector, children }) {
  const [isDomLoaded, setIsDomLoaded] = useState(false);

  useEffect(() => {
    setIsDomLoaded(true);
  }, []);
  if (!isDomLoaded) return null;

  const targetNode = document.querySelector(targetNodeSelector);
  if (targetNode) {
    return createPortal(
      <>
        {children}
      </>,
      targetNode,
    );
  }
  return null;
}

DomNodePortal.propTypes = {
  children: PropTypes.node.isRequired,
  targetNodeSelector: PropTypes.string.isRequired,
};

export default DomNodePortal;
