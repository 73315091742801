import PropTypes from 'prop-types';
import React from 'react';
import style from './slider.module.scss';

const ArrowButton = ({ direction, onClick }) => (
  <button aria-label={`See ${direction} slide`} className={`${style.sliderButton} ${style[`slider-${direction}`]}`} onClick={onClick}>
    <span className="slider-arrow-background" />
    <span className="slider-arrow-icon" data-icon={`${direction === 'previous' ? 'left' : 'right'}-thin`} />
  </button>
);

ArrowButton.propTypes = {
  direction: PropTypes.oneOf([
    'previous',
    'next',
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ArrowButton;
