import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import style from './SearchDetails.module.scss';

export default function SearchDetails({
  editableSearchFlag = true,
  searchTerm,
  count = 0,
}) {
  const showFullText = editableSearchFlag === false;
  const resultsFoundFor = useTranslatedText(
    'resultsFoundFor',
    {
      replacements: [count.toString(), searchTerm],
      fallback: `${count.toString()} results found for "${searchTerm}"`,
    },
  );

  return (
    <div
      className={style['rs-search-details']}
    >
      <h1 className="h3 rs-search-details__product-count" data-testid="search-product-count">
        { showFullText
          ? <TmntHtml property={resultsFoundFor.key} value={resultsFoundFor.value} />
          : <NumberOfResults count={count} numberFirst />}
      </h1>
      {/* we must tell a screen reader anytime the results or search term has been changed
       https://hub.accessible360.com/kb/articles/922#heading-results-available-announcement-for-screen-reader-users */}
      <span aria-live="assertive" className="screen-reader-text">
        <TmntHtml property={resultsFoundFor.key} value={resultsFoundFor.value} />
      </span>
    </div>
  );
}

SearchDetails.propTypes = {
  editableSearchFlag: PropTypes.bool,
  searchTerm: PropTypes.string.isRequired,
  count: PropTypes.number,
};
