import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import espotFragment from '../../queries/fragments/Espot';
import paginationFragment from '../../queries/fragments/Pagination';
import productsFragment from '../../queries/fragments/Products';
import GridPagination from '../GridPagination/GridPagination';
import { ERROR_MESSAGE } from '../Messages/Messages';
import FullPageGrid from '../ProductGrid/FullPageGrid';
import NoResults from '../ProductGrid/ProductGridNoResults';
import SkeletonProductGrid from '../ProductGrid/SkeletonGrid';
import style from './GroupedCategoryGridWrapper.module.scss';

const getCategoryData = gql`
  ${productsFragment}
  ${espotFragment}
  ${paginationFragment}
  query CategoryGrid(
    $categoryId: String!,
    $facet: [String],
    $filter: String,
    $requestSocialProofData: Boolean,
    $rows: String!,
    $sort: String,
    $start: String!,
    $storePreview: String,
    $uPrefShipTo: String,
  ) {
    groupedCategory(
      categoryId: $categoryId,
      facet: $facet,
      filter: $filter,
      requestSocialProofData: $requestSocialProofData,
      rows: $rows,
      sort: $sort,
      start: $start,
      storePreview: $storePreview,
      uPrefShipTo: $uPrefShipTo,
    ) {
      name
      categoryId
      pagination {
        ...PaginationInfo
      }
      productTotalCount
      fullCatHead
      subCategories {
        categoryId
        name
        products {
          ...Products
          socialProofMessage
        }
        ...Espot
      }
    }
  }
`;

function GroupedCategoryGridWrapper({
  categoryId,
  facet = [],
  filter = '',
  handleProductCount,
  onPaginationButtonClick,
  rows = '90',
  sort = '',
  start = '0',
  storePreview = 'false',
  uPrefShipTo = '',
}) {
  const {
    loading, error, data,
  } = useQuery(getCategoryData, {
    variables: {
      categoryId,
      facet,
      filter,
      requestSocialProofData: true,
      rows,
      sort,
      start,
      storePreview,
      uPrefShipTo,
    },
  });

  useEffect(() => {
    handleProductCount(data?.groupedCategory.productTotalCount);
  }, [data?.groupedCategory.productTotalCount, handleProductCount]);

  if (loading) return <SkeletonProductGrid cardAmount={12} />;
  if (error) return ERROR_MESSAGE;

  const {
    fullCatHead,
    pagination: {
      currentPage,
      totalPages,
    },
    subCategories,
  } = data.groupedCategory;

  return (
    <>
      {subCategories.length > 0
        ? (
          <>
            {subCategories.map((group) => (
              <React.Fragment key={group.categoryId}>
                <h2 className={style.subCategoryHeading}>{group.name}</h2>
                <FullPageGrid
                  categoryId={group.categoryId}
                  espotContent={group.espotContent}
                  espotIdentifier={group.espotIdentifier}
                  fullCatHead={fullCatHead}
                  products={group.products}
                />
              </React.Fragment>
            ))}
            <GridPagination
              currentPage={currentPage}
              onButtonClick={onPaginationButtonClick}
              rows={rows}
              start={start}
              totalPages={totalPages}
            />
          </>
        )
        : (
          <NoResults />
        )}
    </>
  );
}
GroupedCategoryGridWrapper.propTypes = {
  // Required props
  categoryId: PropTypes.string.isRequired,
  handleProductCount: PropTypes.func.isRequired,
  onPaginationButtonClick: PropTypes.func.isRequired,
  // Optional props
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  rows: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
  storePreview: PropTypes.string,
  uPrefShipTo: PropTypes.string,
};

export default GroupedCategoryGridWrapper;
