const SORTED_DEPARTMENTS = [
  12203, // ANF WOMENS
  12552, // HCO WOMENS
  12202, // ANF MENS
  12551, // HCO MENS
  179206, // KIDS GIRLS
  179205, // KIDS BOYS
];

/**
 * Sorts an array of department options based on a predefined order.
 *
 * @param {Array} departments - The array of department options to be sorted.
 * @returns {Array} - The sorted array of department options.
 */
const sortDepartmentOptions = (departments) => {
  // Add numericID to each department
  const departmentsWithNumericID = departments.map((department) => {
    const numericID = department.id.replace(/\D/g, '');
    return {
      ...department,
      numericID: parseInt(numericID, 10),
    };
  });

  // Sort departments based on the order in SORTED_DEPARTMENTS
  return departmentsWithNumericID.sort(
    (a, b) => SORTED_DEPARTMENTS.indexOf(a.numericID) - SORTED_DEPARTMENTS.indexOf(b.numericID),
  );
};

export default sortDepartmentOptions;
