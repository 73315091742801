const { IMAGE_SIZE_POLICY } = require('./constants');

/**
 * Constructs an image URL with optional image size parameter.
 *
 * @param {string} imageHostName - The image hostname prefix to the URL
 * @param {string} imageId - The image identifier such as KIC_XXX_XXX_XXX.
 * @param {string} [imageSize] - The desired image size such as 'medium' or 'small'.
 * @returns {string} The generated image URL.
 */
const getImageUrl = (imageHostName, imageId, imageSize) => {
  const imageUrl = `${imageHostName}/is/image/anf/${imageId}?`;
  const params = new URLSearchParams();

  params.append('policy', `product-${imageSize ?? IMAGE_SIZE_POLICY.MEDIUM}`);
  return imageUrl + params.toString();
};

export default getImageUrl;
