import { useEffect } from 'react';

const useDesignSystemInit = (containerRef, dependencies = [], skip = false) => {
  /* global ANF */

  useEffect(() => {
    if (containerRef.current && typeof ANF !== 'undefined' && !skip) {
      ANF.globalV2?.designsystem?.init?.();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, ...dependencies, skip]);
};

export default useDesignSystemInit;
