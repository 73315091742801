import { useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import syncStateWithUrl from '../tools/syncStateWithUrl';
import updateUrl from '../tools/updateUrl';

const useUrlSync = (parameters, dispatch) => {
  const handleHistoryNavigation = useCallback(() => {
    syncStateWithUrl(dispatch);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('popstate', handleHistoryNavigation);
    return () => {
      window.removeEventListener('popstate', handleHistoryNavigation);
    };
  }, [handleHistoryNavigation]);

  useUpdateEffect(() => {
    if (!parameters.historyNavigation) updateUrl(parameters);
  }, [parameters]);
};

export default useUrlSync;
