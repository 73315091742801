import {
  arrayOf, bool, func, string, oneOfType, number, shape,
} from 'prop-types';
import React, { useCallback, useState } from 'react';
import { getAppliedFiltersFromFacet } from '../../tools/getIndividualFacet';
import FacetToaster from './FacetToaster';
import LaunchToasterButton from './LaunchToasterButton';
import getAppliedFilterText from './tools/getAppliedFilterText';

/**
 * renders a button and a toaster component that slides up from the bottom of the screen
 * and displays size information for the current search results
 * @param {object} props - the props object
 * @param {Array} props.appliedFacets - the filters that are currently applied
 * @param {string} props.departmentId - the id of the department
 * @param {string} props.facetId - the id of the facet
 * @param {string} props.facetName - the name of the facet
 * @param {string} props.filter - any store filters
 * @param {function} props.onCheckboxChange - the function to call when a checkbox is changed
 * @param {Array} props.options - the options to display in the toaster
 * @param {string} props.options.name - the name of the option
 * @param {number} props.options.count - the count of the option
 * @param {boolean} props.options.enabled - whether the option is enabled
 * @param {number} props.resultCount - the number of results for the current search
 * @param {object} props.searchTerm - the current search term
 */
const DiscoverByFacet = ({
  appliedFacets = [],
  departmentId = '',
  facetId = '',
  facetName = '',
  filter = '',
  onFacetToasterChange = () => {},
  options = [],
  searchTerm = '',
  resultCount = 0,
}) => {
  const appliedFiltersFromFacet = getAppliedFiltersFromFacet(appliedFacets, facetId);
  const appliedFilterText = getAppliedFilterText(appliedFiltersFromFacet);
  const [toasterState, setToasterState] = useState({
    isOpen: false,
    count: resultCount,
    selectedFilters: appliedFiltersFromFacet,
  });

  const openToaster = useCallback(() => {
    setToasterState((prevState) => ({
      ...prevState,
      isOpen: true,
      count: resultCount,
      selectedFilters: appliedFiltersFromFacet,
    }));
  }, [appliedFiltersFromFacet, resultCount]);

  return resultCount === 0 ? null : (
    <>
      <LaunchToasterButton
        appliedFiltersFromFacet={appliedFiltersFromFacet}
        appliedFilterText={appliedFilterText}
        facetName={facetName}
        openToaster={openToaster}
      />
      <FacetToaster
        appliedFacets={appliedFacets}
        departmentId={departmentId}
        facetId={facetId}
        facetName={facetName}
        filter={filter}
        onFacetToasterChange={onFacetToasterChange}
        options={options}
        resultCount={resultCount}
        searchTerm={searchTerm}
        setToasterState={setToasterState}
        toasterState={toasterState}
      />
    </>
  );
};

DiscoverByFacet.propTypes = {
  appliedFacets: oneOfType([
    arrayOf(string),
    string,
  ]),
  departmentId: string,
  facetId: string,
  facetName: string,
  filter: string,
  onFacetToasterChange: func,
  options: arrayOf(shape({
    name: string,
    count: number,
    enabled: bool,
  })),
  resultCount: number,
  searchTerm: string,
};

export default DiscoverByFacet;
