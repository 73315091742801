import isServer from '../../tools/isServer';
import $window from '../../tools/window';

const triggerAnalytics = ({
  completionType,
  isInitialSearch,
  previousSearchterm,
  resultCount,
  searchTerm,
  submitMethod,
  userInputedSearchTerm,
}) => {
  if (previousSearchterm === searchTerm || isServer() || !$window.digitalData) return;

  const isAutoSuggestClick = isInitialSearch && completionType;
  const isAttributePill = submitMethod.trigger === 'attributePill';
  const analyticsData = {
    key: 'search.search_bar_used',
    data: {
      name: 'Search bar used',
      search_bar_category: isAutoSuggestClick ? completionType : null,
      search_bar_suggestion: isAutoSuggestClick ? searchTerm : null,
      search_corrected_search_term: null,
      search_results: resultCount,
      search_term: searchTerm,
    },
  };

  if (searchTerm !== userInputedSearchTerm && String(userInputedSearchTerm).trim() !== '') {
    analyticsData.data.search_corrected_search_term = searchTerm;
    analyticsData.data.search_term = userInputedSearchTerm;
  }

  if (!isInitialSearch) {
    analyticsData.data.data_text = 'editable search';
    analyticsData.data.data_action = 'text';
  }

  if (isAttributePill) {
    analyticsData.data = {
      event_name: 'search',
      search_term: searchTerm,
      search_results: resultCount,
      data_text: 'editable search',
      data_action: 'visual text',
      search_attribute: submitMethod.attribute,
    };
  }
  $window.digitalData.trigger(analyticsData.key, analyticsData.data);
};

export default triggerAnalytics;
