import {
  Button,
  FlagBlock,
  Icon,
  LegalText,
  Modal,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import $window from '../../tools/window';
import style from './FranchisePortal.module.scss';
import useFranchiseModalText from './hooks/useFranchiseModalText';

const FranchisePortalModal = ({
  brand = '',
  countryCode = '',
  countryName = '',
  setViewedFranchisePortal = () => {},
  shipToLink = '',
}) => {
  const [isFranchiseModalOpen, setIsFranchiseModalOpen] = useState(true);
  const franchiseModalText = useFranchiseModalText(countryName, !isFranchiseModalOpen);

  if (!isFranchiseModalOpen) return null;

  const fireAnalytics = (action) => {
    if ($window.digitalData) {
      const analyticsEvent = {
        event_name: 'click',
        data_text: 'franchise modal',
        data_action: action,
      };
      $window.digitalData.trigger('franchise_modal', analyticsEvent);
    }
  };

  const handleNoclick = () => {
    setIsFranchiseModalOpen(false);
    setViewedFranchisePortal(true);
    fireAnalytics('close');
  };

  return (
    <Modal
      id="franchise-modal"
      isFullscreen
      isOpen={isFranchiseModalOpen}
      onClose={() => {
        fireAnalytics('close');
        setIsFranchiseModalOpen(false);
      }}
      onOpen={() => {
        fireAnalytics('open');
      }}
      title="Franchise Modal"
    >
      <div className={`${style.modalWrapper} scope-1892`}>
        <FlagBlock countryCode={countryCode} size="l" />
        <h1 className={style.textCenter}>
          {franchiseModalText.shipToHeading.value}
        </h1>
        <h2 className={style.textCenter}>
          {franchiseModalText.shopLocalSite.value}
        </h2>
        <hr />
        <div
          className={style.container}
        >
          <Icon icon="exclamation" />
          <span>
            {franchiseModalText.itemsNotSaved.value}
          </span>
        </div>
        <Button classList={style.button} href={shipToLink} isFullWidth target="_blank" variant={brand === 'hol' ? 'primary' : 'secondary'}>
          <Icon icon="external-link" />
          {franchiseModalText.confirmButtonText.value}
        </Button>
        <Button isFullWidth onClick={handleNoclick} variant={brand === 'hol' ? 'secondary' : 'tertiary-dark'}>
          {franchiseModalText.declineButtonText.value}
        </Button>
        <LegalText>
          {franchiseModalText.legalText.value}
        </LegalText>
      </div>
    </Modal>
  );
};

FranchisePortalModal.propTypes = {
  brand: PropTypes.string,
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  shipToLink: PropTypes.string,
  setViewedFranchisePortal: PropTypes.func,
};

export default FranchisePortalModal;
