import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

import NullSearchRecommendations from './NullSearchRecommendations';
import style from './SearchErrorPage.module.scss';
import SuggestDepartments from './SuggestDepartments';

function SearchErrorPage({
  elasticDepartmentData = null,
  error = null,
  departmentId = '',
  departmentSuggestionsFlag = false,
  searchTerm = '',
  onDepartmentChange = () => {},
}) {
  const emptySearchTerm = useTranslatedText('emptySearchTerm', { fallback: 'Please enter a search term' });
  const invalidSearchTerm = useTranslatedText('invalidSearchTerm', { fallback: 'Please enter a valid search term' });
  const errorMessage = useTranslatedText('errorMessage', { fallback: 'Looks like we are having a problem, and are working to resolve the issue. Please try again in a few minutes.' });
  const productNoResultsError = useTranslatedText('productNoResultsError', { fallback: 'Sorry, no match found for your search criteria.' });

  const showSuggestedDepartments = departmentId && searchTerm && departmentSuggestionsFlag;

  let errorResponseText = error?.graphQLErrors?.[0]?.extensions?.response?.body?.errorMessage
  || error?.networkError?.result?.errors?.[0]?.message || error?.message;
  const errorResponseDetails = error?.graphQLErrors?.[0]?.extensions?.response?.body?.details;
  if (errorResponseDetails?.length > 0) {
    errorResponseText = errorResponseDetails[0]?.message;
  }
  const statusCode = error?.graphQLErrors?.[0]?.status ?? error?.networkError?.statusCode;

  //  Start with generic error message for 'search unavailable' or other internal errors
  let errorText = errorMessage.value;
  let errorKey = errorMessage.key;

  // Give feedback if it's a user input error
  if (errorResponseText === "Missing query parameter with name 'searchTerm'"
    || errorResponseText === 'Variable "$searchTerm" of required type "String!" was not provided.') {
    errorText = emptySearchTerm.value;
    errorKey = emptySearchTerm.key;
  } else if (errorResponseText === "Bad parameter with name 'searchTerm'") {
    errorText = invalidSearchTerm.value;
    errorKey = invalidSearchTerm.key;
  } else if (statusCode === 400) {
    errorText = productNoResultsError.value;
    errorKey = productNoResultsError.key;
  }

  return (
    error ? (
      <div className="search-title--wrapper scope-1892">
        <h1 className={`search-title no-results h3 ${style.title}`} data-property={errorKey}>
          {errorText}
        </h1>
        <div className="hr" />
      </div>
    ) : (
      <>
        <input name="resultsQty" type="hidden" value="0" />
        <div className="search-contents">
          <div className="search-title--wrapper scope-1892">
            {showSuggestedDepartments ? (
              <SuggestDepartments
                activeDepartment={departmentId}
                elasticDepartmentData={elasticDepartmentData}
                onDepartmentChange={onDepartmentChange}
                searchTerm={searchTerm}
              />
            )
              : (
                <h1 className={`search-title no-results h3 ${style.title}`} data-property={productNoResultsError.key}>
                  {productNoResultsError.value}
                </h1>
              )}
          </div>
        </div>
        <NullSearchRecommendations />
      </>
    )
  );
}
SearchErrorPage.propTypes = {
  error: PropTypes.shape({
    graphQLErrors: PropTypes.arrayOf(PropTypes.shape({
      extensions: PropTypes.shape({
        response: PropTypes.shape({
          body: PropTypes.shape({
            details: PropTypes.arrayOf(PropTypes.shape({
              field: PropTypes.string,
              message: PropTypes.string,
            })),
            errorCode: PropTypes.string,
            errorMessage: PropTypes.string,
          }),
          status: PropTypes.number,
        }),
      }),
      status: PropTypes.number,
      statusText: PropTypes.string,
    })),
    networkError: PropTypes.shape({
      result: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          errors: PropTypes.arrayOf(PropTypes.shape({
            extensions: PropTypes.shape({
              code: PropTypes.string,
            }),
            message: PropTypes.string,
          })),
        }),
      ]),
      statusCode: PropTypes.number,
    }),
    message: PropTypes.string,
  }),
  departmentId: PropTypes.string,
  searchTerm: PropTypes.string,
  onDepartmentChange: PropTypes.func,
  departmentSuggestionsFlag: PropTypes.bool,
  elasticDepartmentData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    defaultCategoryIdBySite: PropTypes.string,
  })),
};

export default SearchErrorPage;
