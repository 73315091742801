import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

const ERROR_MESSAGES = () => {
  const errorMessage = useTranslatedText('errorMessage', { fallback: 'An error occurred' });

  return (
    <p data-property={errorMessage.key}>
      {errorMessage.value}
    </p>
  );
};
const LOADING_MESSAGES = () => {
  const loadingMessage = useTranslatedText('loadingMessage', { fallback: 'Loading...' });

  return (
    <p data-property={loadingMessage.key}>
      {loadingMessage.value}
    </p>
  );
};

export const ERROR_MESSAGE = <ERROR_MESSAGES />;
export const LOADING_MESSAGE = <LOADING_MESSAGES />;
