import { MAX_SIMILAR_CATEGORY_LENGTH } from './constants';

const getSimilarCategory = (breadcrumbArray) => {
  if (!breadcrumbArray) return { id: '', name: '' };
  const similarCategory = breadcrumbArray[MAX_SIMILAR_CATEGORY_LENGTH] || breadcrumbArray.at(-1);
  return {
    id: similarCategory.categoryId,
    name: similarCategory.categoryName,
  };
};
export default getSimilarCategory;
