import getEncodedValue from './getEncodedValue';

const getParameter = (name, value = '') => (value ? `&${name}=${getEncodedValue(value.toString())}` : '');

const getQueryString = (facet) => {
  let queryString = '';
  facet?.forEach((value, index) => {
    if (index === 0) queryString += getParameter('filtered', 'true');
    queryString += getParameter('facet', value);
  });

  return queryString;
};

export default getQueryString;
