import PropTypes from 'prop-types';
import React from 'react';
import SingleAemEspot from '../SingleAemEspot';

export default function GmsEspotBanner({ brand }) {
  return (
    <SingleAemEspot espotId={`GMSBanner-${brand}`} />
  );
}

GmsEspotBanner.propTypes = {
  brand: PropTypes.string.isRequired,
};
