import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import SingleAemEspot from '../SingleAemEspot';
import NavFlyoutCategoryHeader from './NavFlyoutCategoryHeader';
import NavFlyoutCategoryListItem from './NavFlyoutCategoryListItem';

const NavFlyoutPanel = ({
  allText,
  brand,
  categoryData,
  showL3Categories,
  onBlur = () => {},
  onKeyDown = () => {},
}) => {
  const regularSubcatsToRender = [];
  const featuredSubcatsToRender = [];
  const clearanceSubcatsToRender = [];
  const subcatsWithL3sToRender = [];
  const { width } = useWindowSize();
  const [shouldRenderEspots, setShouldRenderEspots] = useState(true);

  /**
   * Do not render eSpots when mobile nav category accordions can be displayed because they may
   * contain problematic and unused accordion panel elements with duplicate IDs that cause mobile
   * nav accordions not to work; see https://anfcorp.atlassian.net/browse/BROWSE-1551.
   * shouldRenderEspots is true in SSR and initial CSR to prevent hydration mismatch error.
   */
  useEffect(() => {
    setShouldRenderEspots(width >= 1025);
  }, [width]);

  categoryData.subCategories?.forEach((subcategory) => {
    if (!subcategory?.categoryId) {
      return;
    }
    switch (subcategory.displayType) {
      case 'regular':
        regularSubcatsToRender.push(subcategory);
        break;
      case 'clearance':
        clearanceSubcatsToRender.push(subcategory);
        break;
      case 'featured':
        featuredSubcatsToRender.push(subcategory);
        break;
      default:
        break;
    }
    if (subcategory.subCategories?.length && showL3Categories) {
      subcatsWithL3sToRender.push(subcategory);
    }
  });

  let leftMarketingContent = null;
  let rightMarketingContent = null;
  let bottomMarketingContent = null;
  let topRegularColumnContent = null;
  let bottomRegularColumnContent = null;
  let topFeaturedColumnContent = null;
  let bottomFeaturedColumnContent = null;
  let topClearanceColumnContent = null;
  let bottomClearanceColumnContent = null;

  if (categoryData.topNavEspotContent) {
    categoryData.topNavEspotContent.forEach((espot) => {
      if (!shouldRenderEspots || !espot) {
        return;
      }
      switch (espot.espotId) {
        case `${categoryData.espotIdentifier}-nav-left-desktop`:
          leftMarketingContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-nav-right-desktop`:
          rightMarketingContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-nav-bottom-desktop`:
          bottomMarketingContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-cat-column-regular-top-desktop`:
          topRegularColumnContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-cat-column-regular-bottom-desktop`:
          bottomRegularColumnContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-cat-column-featured-top-desktop`:
          topFeaturedColumnContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-cat-column-featured-bottom-desktop`:
          bottomFeaturedColumnContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-cat-column-clearance-top-desktop`:
          topClearanceColumnContent = espot.htmlContent;
          break;
        case `${categoryData.espotIdentifier}-cat-column-clearance-bottom-desktop`:
          bottomClearanceColumnContent = espot.htmlContent;
          break;
        default:
          break;
      }
    });
  }

  const columnClasses = subcatsWithL3sToRender.length ? 'nav-flyout-column thin-column' : 'nav-flyout-column';

  return (
    <>
      <div
        className="nav-flyout-container"
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        role="presentation"
      >
        {
          leftMarketingContent && (
            <div className="nav-flyout-marketing-content--left">
              <SingleAemEspot
                content={leftMarketingContent}
                espotId={`${categoryData.espotIdentifier}-nav-left-desktop`}
              />
            </div>
          )
        }
        {
          categoryData.subCategories?.length > 0 && (
            <div className="nav-flyout-panel" data-level="2">
              <div className="nav-column-vertical-container">
                {
                  regularSubcatsToRender.length > 0 && (
                    <div className={`${columnClasses} regular`}>
                      {
                        brand !== 'gh' && (brand !== 'hol' || subcatsWithL3sToRender.length > 0)
                          && <NavFlyoutCategoryHeader headerPairKey="navCategoriesLabel" />
                      }
                      {
                        topRegularColumnContent && (
                          <div className="nav-flyout-marketing-content--top-regular">
                            <SingleAemEspot
                              content={topRegularColumnContent}
                              espotId={`${categoryData.espotIdentifier}-cat-column-regular-top-desktop`}
                            />
                          </div>
                        )
                      }
                      <ul className="nav-flyout-list" data-testid="regular-categories">
                        {regularSubcatsToRender.map((subcat) => (
                          <NavFlyoutCategoryListItem
                            key={subcat.categoryId}
                            name={subcat.name}
                            url={subcat.url}
                          />
                        ))}
                      </ul>
                      {
                        bottomRegularColumnContent && (
                          <div className="nav-flyout-marketing-content--bottom-regular">
                            <SingleAemEspot
                              content={bottomRegularColumnContent}
                              espotId={`${categoryData.espotIdentifier}-cat-column-regular-bottom-desktop`}
                            />
                          </div>
                        )
                      }
                    </div>
                  )
                }
                {
                  (topClearanceColumnContent
                  || clearanceSubcatsToRender.length > 0
                  || bottomClearanceColumnContent)
                    && (
                      <div className={classNames(
                        columnClasses,
                        'clearance',
                      )}
                      >
                        {
                          brand !== 'gh'
                            && <NavFlyoutCategoryHeader additionalClasses="screen-reader-text" headerPairKey="navClearanceGroupHeader" />
                        }
                        {
                          topClearanceColumnContent && (
                            <div className="nav-flyout-marketing-content--top-clearance">
                              <SingleAemEspot
                                content={topClearanceColumnContent}
                                espotId={`${categoryData.espotIdentifier}-cat-column-clearance-top-desktop`}
                              />
                            </div>
                          )
                        }
                        {
                          clearanceSubcatsToRender.length > 0 && (
                            <ul className="nav-flyout-list" data-testid="clearance-categories">
                              {clearanceSubcatsToRender.map((subcat) => (
                                <NavFlyoutCategoryListItem
                                  key={subcat.categoryId}
                                  name={subcat.name}
                                  url={subcat.url}
                                />
                              ))}
                            </ul>
                          )
                        }
                        {
                          bottomClearanceColumnContent && (
                            <div className="nav-flyout-marketing-content--bottom-clearance">
                              <SingleAemEspot
                                content={bottomClearanceColumnContent}
                                espotId={`${categoryData.espotIdentifier}-cat-column-clearance-bottom-desktop`}
                              />
                            </div>
                          )
                        }
                      </div>
                    )
                }
              </div>
              {subcatsWithL3sToRender.map((subcat) => (
                <div key={`${subcat.categoryId}-w-l3s`} className={`${columnClasses} regular`}>
                  <h3 className="nav-category-column-header">
                    <a className="category-link" href={subcat.url}>
                      {subcat.name}
                    </a>
                  </h3>
                  <ul aria-label="regular categories">
                    {subcat.subCategories.map((l3Subcat) => l3Subcat && l3Subcat.categoryId && (
                      <NavFlyoutCategoryListItem
                        key={l3Subcat.categoryId}
                        name={l3Subcat.name}
                        url={l3Subcat.url}
                      />
                    ))}
                    <li className="category-list-item">
                      <a className="category-link" href={subcat.url}>
                        {`${allText} ${subcat.name}`}
                      </a>
                    </li>
                  </ul>
                </div>
              ))}
              {
                (topFeaturedColumnContent
                || featuredSubcatsToRender.length > 0
                || bottomFeaturedColumnContent)
                  && (
                    <div className={`${columnClasses} featured`}>
                      {
                        brand !== 'gh'
                          && <NavFlyoutCategoryHeader headerPairKey="navFeaturedGroupHeader" />
                      }
                      {
                        topFeaturedColumnContent && (
                          <div className="nav-flyout-marketing-content--top-featured">
                            <SingleAemEspot
                              content={topFeaturedColumnContent}
                              espotId={`${categoryData.espotIdentifier}-cat-column-featured-top-desktop`}
                            />
                          </div>
                        )
                      }
                      {
                        featuredSubcatsToRender.length > 0 && (
                          <ul className="nav-flyout-list" data-testid="featured-categories">
                            {featuredSubcatsToRender.map((subcat) => (
                              <NavFlyoutCategoryListItem
                                key={subcat.categoryId}
                                name={subcat.name}
                                url={subcat.url}
                              />
                            ))}
                          </ul>
                        )
                      }
                      {
                        bottomFeaturedColumnContent && (
                          <div className="nav-flyout-marketing-content--bottom-featured">
                            <SingleAemEspot
                              content={bottomFeaturedColumnContent}
                              espotId={`${categoryData.espotIdentifier}-cat-column-featured-bottom-desktop`}
                            />
                          </div>
                        )
                      }
                    </div>
                  )
              }
            </div>
          )
        }
        {
          rightMarketingContent && (
            <div className="nav-flyout-marketing-content--right">
              <SingleAemEspot
                content={rightMarketingContent}
                espotId={`${categoryData.espotIdentifier}-nav-right-desktop`}
              />
            </div>
          )
        }
      </div>
      {
        bottomMarketingContent && (
          <div className="nav-flyout-marketing-content--bottom">
            <SingleAemEspot
              content={bottomMarketingContent}
              espotId={`${categoryData.espotIdentifier}-nav-bottom-desktop`}
            />
          </div>
        )
      }
    </>
  );
};

NavFlyoutPanel.propTypes = {
  allText: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  categoryData: PropTypes.shape({
    categoryId: PropTypes.string.isRequired,
    espotIdentifier: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
    topNavEspotContent: PropTypes.arrayOf(
      PropTypes.shape({
        espotId: PropTypes.string,
        htmlContent: PropTypes.string,
      }),
    ),
    subCategories: PropTypes.arrayOf(
      PropTypes.shape({
        categoryId: PropTypes.string.isRequired,
        displayType: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        subCategories: PropTypes.arrayOf(
          PropTypes.shape({
            categoryId: PropTypes.string.isRequired,
            name: PropTypes.string,
            url: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
  showL3Categories: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default NavFlyoutPanel;
