import { Accordion, Link } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';

function PopularSearchesAccordion({ brand, popularSearches }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const clickHandler = () => {
    setIsExpanded(!isExpanded);
  };

  const popularSearchHeader = useTranslatedText('popularSearchesHeader', { fallback: 'Popular Searches' });

  const getPopularSearchesList = popularSearches.map((popularSearch) => (
    <li key={popularSearch.name}>
      <Link href={popularSearch.url}>
        {popularSearch.name}
      </Link>
    </li>
  ));

  return (
    <Accordion
      brand={brand}
      headingLevel={2}
      id="popular-searches-accordion"
      isExpanded={isExpanded}
      onClick={clickHandler}
      theme="secondary-light"
      title={popularSearchHeader.value}
      variant="boxed"
    >
      <ul style={{ listStyleType: 'none' }}>
        {getPopularSearchesList}
      </ul>
    </Accordion>
  );
}

export default PopularSearchesAccordion;

PopularSearchesAccordion.propTypes = {
  brand: PropTypes.string.isRequired,
  popularSearches: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
