import PropTypes from 'prop-types';
import React from 'react';
import SingleAemEspot from '../SingleAemEspot/SingleAemEspot';

const HomepageWrapper = ({ espotId, storePreview }) => (
  <SingleAemEspot
    espotId={espotId}
    storePreview={storePreview}
  />
);

export default HomepageWrapper;

HomepageWrapper.propTypes = {
  espotId: PropTypes.string.isRequired,
  storePreview: PropTypes.string,
};
