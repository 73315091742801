import PropTypes from 'prop-types';
import React from 'react';
import ClearAllButton from '../ClearAllButton/ClearAllButton';
import ProductGridTagGroup from '../ProductGridTagGroup/ProductGridTagGroup';
import style from './ProductGridHeader.module.scss';

function HeaderFilters({
  facet = [],
  isClearAllButtonEnabled = false,
  onClearAllBtnClick = () => {},
  onFacetTagClick = () => {},
}) {
  return (
    <div className={style.filters}>
      <div className={style.filterTrack}>
        <ProductGridTagGroup
          facet={facet}
          hasMobileGridHeader
          setFacetState={onFacetTagClick}
        />
        {isClearAllButtonEnabled && (
          <div className={style.clearFiltersBtn} data-testid="clear-filters-button">
            <ClearAllButton onClick={onClearAllBtnClick} />
          </div>
        )}
      </div>
    </div>
  );
}

HeaderFilters.propTypes = {
  // Required props
  // Optional props
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  isClearAllButtonEnabled: PropTypes.bool,
  onClearAllBtnClick: PropTypes.func,
  onFacetTagClick: PropTypes.func,
};

export default HeaderFilters;
