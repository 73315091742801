// @ts-check
import { FACET_DICTIONARY } from '../../../tools/constants';
import { getAppliedFiltersFromFacet } from '../../../tools/getIndividualFacet';

/**
 * Remove filters that are already applied or not relevant to current search
 * @param {Array} appliedFacets
 * @param {Array} affinities
 * @param {string} facet
 * @returns {Array} array of objects with value and facet
 */
const removeIrrelevantFilters = (appliedFacets, affinities, facet, dbfFilters) => {
  const filters = affinities.map((affinity) => ({ value: affinity.v, facet }));
  const facetId = FACET_DICTIONARY[facet];
  const appliedFiltersFromFacet = getAppliedFiltersFromFacet(appliedFacets, facet);
  return filters.filter(
    (filter) => !appliedFiltersFromFacet.includes(filter.value)
      && dbfFilters[facetId]?.filters?.some((f) => f.name === filter.value),
  );
};

const combineSizeFilters = (affinities) => {
  if (!affinities.alpha_size && !affinities.numeric_size) return [];
  if (affinities.alpha_size.length === 0) return affinities.numeric_size;
  if (affinities.numeric_size.length === 0) return affinities.alpha_size;
  return affinities.alpha_size.concat(affinities.numeric_size);
};

/**
 * Get button values and texts
 * @param {Array} appliedFacets
 * @param {Object} affinities arrays of affinities
 * @param {Object} dbfFilters
 * @returns {Array} array of objects with text and value
 */
const getPreferenceButtonData = (appliedFacets, affinities, dbfFilters) => {
  if (Object.keys(affinities).length === 0) return [];
  const sizeFilters = combineSizeFilters(affinities);
  const modifiedAffinities = {
    ...affinities,
    fit: affinities.fit,
    lengths: affinities.length,
    sizes: sizeFilters,
  };

  // Remove unnecessary properties
  ['alpha_size', 'numeric_size', 'length'].forEach((prop) => delete modifiedAffinities[prop]);

  return Object.keys(modifiedAffinities)
    .flatMap((facet) => (
      removeIrrelevantFilters(
        appliedFacets,
        modifiedAffinities[facet],
        facet,
        dbfFilters,
      ))).map((filter) => ({
      text: filter.value,
      value: `${filter.facet}:${filter.value}`,
    }));
};

export default getPreferenceButtonData;
