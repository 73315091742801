import PropTypes from 'prop-types';
import React from 'react';
import CategoryPage from '../CategoryPage/CategoryPage';
import SearchResultsPage from '../SearchResultsPage/SearchResultsPage';
import style from './PrimaryContentWrapper.module.scss';

export default function PrimaryContentWrapper({
  brand: intlBrand,
  categoryId: intlCategoryId = '',
  countryFulfillmentStore: intlCountryFulfillmentStore = '',
  departmentId: intlDepartmentId = '',
  facet: intlFacet = [],
  filter: intlFilter = '',
  userInputedSearchTerm = '',
  rows: intlRows = '90',
  searchTerm: intlSearchTerm = '',
  sort: intlSort = '',
  start: intlStart = '0',
  storePreview = 'false',
  store = '',
  storeId = '',
}) {
  return (
    <>
      <div className="editable-search-container" />
      <div id="primary-content">
        <div className={style.wrapper}>
          <aside className={style.aside}>
            <div className="rs-facets-leftrail__wrapper" id="facet-leftrail-wrapper-largescreen">
              <div className="mfe-department-selector-container" />
              <div className="filters-wrapper">
                <div className="sort-and-filter-facet-container" />
              </div>
            </div>
          </aside>
          <main className={style.main} tabIndex="-1">
            {intlSearchTerm || userInputedSearchTerm
              ? (
                <SearchResultsPage
                  brand={intlBrand}
                  countryFulfillmentStore={intlCountryFulfillmentStore}
                  departmentId={intlDepartmentId}
                  facet={intlFacet}
                  filter={intlFilter}
                  rows={intlRows}
                  searchTerm={intlSearchTerm}
                  sort={intlSort}
                  start={intlStart}
                  store={store}
                  storeId={storeId}
                  storePreview={storePreview}
                  userInputedSearchTerm={userInputedSearchTerm}
                />
              )
              : (
                <CategoryPage
                  brand={intlBrand}
                  categoryId={intlCategoryId}
                  facet={intlFacet}
                  filter={intlFilter}
                  rows={intlRows}
                  sort={intlSort}
                  start={intlStart}
                  store={store}
                  storeId={storeId}
                  storePreview={storePreview}
                />
              )}

          </main>
        </div>
      </div>
    </>
  );
}

PrimaryContentWrapper.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  // Optional props
  categoryId: PropTypes.string,
  searchTerm: PropTypes.string,
  countryFulfillmentStore: PropTypes.string,
  departmentId: PropTypes.string,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  userInputedSearchTerm: PropTypes.string,
  rows: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  storePreview: PropTypes.string,
  store: PropTypes.string,
  storeId: PropTypes.string,
};
