import { gql } from '@apollo/client';
import paginationFragment from '../../../queries/fragments/Pagination';
import productsFragment from '../../../queries/fragments/Products';

const SEARCH_RESULTS_PRODUCT_DATA = gql`
  ${productsFragment}
  ${paginationFragment}
  query Search(
    $countryFulfillmentStore: String,
    $departmentId: String,
    $initialDepartmentId: String,
    $facet: [String],
    $filter: String,
    $rows: String!,
    $searchTerm: String!,
    $sort: String,
    $start: String!,
    $initialSearchTerm: String,
  ) {
    searchResults(
      countryFulfillmentStore: $countryFulfillmentStore,
      departmentId: $departmentId,
      initialDepartmentId: $initialDepartmentId,
      facet: $facet,
      filter: $filter,
      rows: $rows,
      searchTerm: $searchTerm,
      sort: $sort,
      start: $start,
      initialSearchTerm: $initialSearchTerm,
    ) {
      brand
      pagination {
        ...PaginationInfo
      }
      products {
        ...Products
      }
    }
  }
`;

export default SEARCH_RESULTS_PRODUCT_DATA;
