import { useState } from 'react';
import { useWindowSize, useIntersection } from 'react-use';

function useStickyIntersection(elementRef) {
  const { height } = useWindowSize();
  // need to set initial window height to avoid flickering on safari
  const [initWindowHeight] = useState(height);
  const intersection = useIntersection(elementRef, {
    rootMargin: `0px 0px -${initWindowHeight}px`,
  });

  return intersection?.isIntersecting;
}

export default useStickyIntersection;
