import { gql, useQuery } from '@apollo/client';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';
import style from './footer.module.scss';

const getTmntData = gql`
  query FooterLinks {
    footer {
      weUseCookiesContent
    }
  }
`;

const FooterCookies = () => {
  const { data: linkData } = useQuery(getTmntData);
  const weUseCookiesContent = linkData?.footer?.weUseCookiesContent;
  const mobileWeUseCookies = useTranslatedText('mobileWeUseCookies', { fallback: 'WE USE COOKIES ON OUR WEBSITE' });

  if (!weUseCookiesContent) return null;

  return (
    <>
      <div className={style.cookieTitle}>
        <h2>{mobileWeUseCookies.value}</h2>
      </div>
      <div className={style.cookieContent}>
        <TmntHtml property="weUseCookiesContent" value={weUseCookiesContent} />
      </div>
    </>
  );
};

export default FooterCookies;
