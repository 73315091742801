import { useEffect } from 'react';
import $window from '../tools/window';

export default function useCRSParameterListener(handleCategoryParametersChanged) {
  useEffect(() => {
    $window.addEventListener('mfe:categoryParametersChanged', handleCategoryParametersChanged);

    return () => {
      $window.removeEventListener('mfe:categoryParametersChanged', handleCategoryParametersChanged);
    };
  }, [handleCategoryParametersChanged]);
}
