import { collectOnClickAnalyticsData, runAnalyticsOnClick } from '@xp-utilities/web';

const buildTreatments = (treatment) => ({
  ...treatment,
  control: treatment.control ?? '',
  testId: treatment.testId ?? '',
});

const runAnalyticsForQuickView = ({ placementObj, productIndex }) => {
  if (!placementObj || typeof productIndex === 'undefined') {
    return;
  }

  const analyticsData = collectOnClickAnalyticsData({
    id: placementObj.id,
    name: placementObj.name,
    treatments: placementObj.treatments?.map(buildTreatments) ?? [],
  }, productIndex);
  runAnalyticsOnClick(analyticsData);
};

export default runAnalyticsForQuickView;
