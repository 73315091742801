import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DigitalDataContext from '../../context/digitalData';
import getDigitalDataSubscriptionPromise from '../../tools/getDigitalDataSubscriptionPromise';

// the useDigitalData hook has a tie into session storage to allow
// LD segment rules to overwrite the value and be saved.
// potentially port that funcationality in here.
const DigitalDataProvider = ({ children, keys }) => {
  const [digitalData, setDigitalData] = useState({});

  useEffect(() => {
    if (window.digitalData) {
      keys.forEach((key) => {
        getDigitalDataSubscriptionPromise(key, 5000).then((value) => {
          setDigitalData((prevDigitalData) => ({
            ...prevDigitalData,
            [key]: value,
          }));
        }).catch(() => {
          // noop
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DigitalDataContext.Provider value={digitalData}>
      {children}
    </DigitalDataContext.Provider>
  );
};

DigitalDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DigitalDataProvider;
