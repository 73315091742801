import Icon from 'anf-core-react/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import style from './footer.module.scss';

const FooterMobileLogo = ({ brand }) => {
  const fallback = brand === 'anf' ? 'Abercrombie' : 'Abercrombie Kids';
  const labelText = useTranslatedText(brand, { fallback });

  return (
    <div className={style.mobileLogo}>
      <Icon
        icon={brand === 'anf' ? 'logo-stacked' : 'logo-kids-full'}
        labelText={labelText.value}
      />
    </div>
  );
};

FooterMobileLogo.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default FooterMobileLogo;
