import PropTypes from 'prop-types';
import React from 'react';
import SingleCmcEspot from '../SingleCmcEspot/SingleCmcEspot';

const LandingExperience = ({ categoryId, brand }) => (
  <>
    <div className="hero-wrap">
      <SingleCmcEspot
        categoryId={categoryId}
        espotId={`${brand}-BrandContentHomepageHeroEMS`}
      />
    </div>
    <div className="lifestyle-wrap">
      <SingleCmcEspot categoryId={categoryId} espotId={`${brand}-LifestyleTouts`} />
    </div>
  </>
);

LandingExperience.propTypes = {
  categoryId: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
};

export default LandingExperience;
