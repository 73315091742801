import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import ProductGridContext from '../../context/ProductGridContext';
import DigitalDataContext from '../../context/digitalData';
import useFocusAfterSearch from '../../hooks/useFocusAfterSearch';
import { IMAGE_SIZE_POLICY, SMALLEST_POSSIBLE_BLANK_IMAGE } from '../../tools/constants';
import getImageUrl from '../../tools/getImageUrl';
import tryImageLoad from '../../tools/tryImageLoad';
import { DD_MODEL_IMAGERY_TEST } from '../DigitalDataProvider';
import { imageSetType, swatchType } from '../types';
import style from './productCard.module.scss';

function ProductCardImages({
  forceLifestyleImage = false,
  hover = false,
  hoveredSwatch = null,
  imageSet: {
    primaryFaceOutImage,
    primaryHoverImage,
    prodImage,
    modelImage,
    lifestyleImage,
  },
  isFirstProduct = false,
  onClick = () => {},
  productName = '',
  productPageUrl = null,
  selectedSwatchName = null,
  showPlaceholder = false,
}) {
  const { [DD_MODEL_IMAGERY_TEST]: modelImageryTestValue } = useContext(DigitalDataContext);
  const { noImageFoundImgUrl, imageHostName } = useContext(ProductGridContext);
  const productCardLink = useRef(null);
  useFocusAfterSearch(productCardLink, !isFirstProduct);

  const imageRef = useRef();

  // if we have no hope of displaying these images let's not even try
  if (!noImageFoundImgUrl || !imageHostName) return null;

  const computedPrimaryFaceOutImage = () => {
    if (modelImageryTestValue === 'prod') {
      return prodImage || primaryFaceOutImage;
    } if (modelImageryTestValue === 'model') {
      return modelImage || primaryFaceOutImage;
    } if (forceLifestyleImage) {
      return lifestyleImage || primaryFaceOutImage;
    }
    return primaryFaceOutImage;
  };

  // Try prodImage, otherwise use the No Image Found image
  // Choosing not to tryImageLoad elsewhere because
  // most of the time images should load successfully
  const handleOnError = () => {
    imageRef.current.onerror = null; // prevents looping
    const prodImageUrl = getImageUrl(imageHostName, prodImage, IMAGE_SIZE_POLICY.MEDIUM);
    tryImageLoad(prodImageUrl)
      .then(() => { imageRef.current.src = prodImageUrl; })
      .catch(() => {
        tryImageLoad(noImageFoundImgUrl)
          .then(() => { imageRef.current.src = noImageFoundImgUrl; })
          // eslint-disable-next-line no-console
          .catch(() => console.log('No image could be loaded'));
      });
  };

  const computeImageUrl = () => {
    let imageId;
    if (hoveredSwatch) {
      imageId = hoveredSwatch.product.imageSet.primaryFaceOutImage;
    } else {
      imageId = (hover && primaryHoverImage) ? primaryHoverImage : computedPrimaryFaceOutImage();
    }
    return showPlaceholder
      ? SMALLEST_POSSIBLE_BLANK_IMAGE
      : getImageUrl(imageHostName, imageId, IMAGE_SIZE_POLICY.MEDIUM);
  };

  const computeAltText = () => {
    let altText = productName;
    if (hoveredSwatch) {
      altText = `${productName}, ${hoveredSwatch.name}`;
    } else if (selectedSwatchName) {
      altText = `${productName}, ${selectedSwatchName}`;
    }
    return altText;
  };

  const computeClassName = () => {
    let calcFadeClass = '';
    if (hoveredSwatch && hoveredSwatch.name !== selectedSwatchName) {
      calcFadeClass = style.swatchFade;
    } else if (hover && primaryHoverImage && primaryHoverImage !== computedPrimaryFaceOutImage()) {
      calcFadeClass = style.hoverFade;
    }
    return calcFadeClass;
  };
  const computeDataAuiText = () => {
    let dataAuiText = 'product-card-image';
    if (hoveredSwatch) {
      dataAuiText = 'product-card-hovered-swatch-image';
    } else if (hover && primaryHoverImage) {
      dataAuiText = 'product-card-hover-image';
    }
    return dataAuiText;
  };

  return (
    <div>
      <a ref={productCardLink} href={productPageUrl} onClick={onClick}>
        <img
          ref={imageRef}
          alt={computeAltText()}
          className={computeClassName()}
          data-aui={computeDataAuiText()}
          onError={handleOnError}
          src={computeImageUrl()}
          width="400"
        />
      </a>
    </div>
  );
}

ProductCardImages.propTypes = {
  forceLifestyleImage: PropTypes.bool,
  imageSet: imageSetType.isRequired,
  hover: PropTypes.bool,
  hoveredSwatch: swatchType,
  isFirstProduct: PropTypes.bool,
  onClick: PropTypes.func,
  productName: PropTypes.string,
  productPageUrl: PropTypes.string,
  selectedSwatchName: PropTypes.string,
  showPlaceholder: PropTypes.bool,
};

export default ProductCardImages;
