import { useDebounce } from 'react-use';
import { AUTOSUGGEST_THRESHOLD, SEARCH_FLYOUT_DEBOUNCE } from '../../../tools/constants';
import { useSuggestions } from '../../SearchBox/hooks/useQueries';

export default function useAutoSuggest(
  blurHandler,
  searchTerm,
) {
  const [getSuggestions, suggestions] = useSuggestions();
  const { combinedSuggestions } = suggestions;
  const suggestionsVisible = searchTerm.trim().length >= AUTOSUGGEST_THRESHOLD;
  const displayedData = suggestionsVisible ? combinedSuggestions : [];

  useDebounce(() => {
    if (searchTerm && searchTerm.length >= AUTOSUGGEST_THRESHOLD) {
      getSuggestions({ variables: { searchTerm } });
    }
  },
  SEARCH_FLYOUT_DEBOUNCE,
  [searchTerm]);

  const keyDownHandler = (e) => {
    const { key } = e;

    const keyboardActions = {
      Escape: () => {
        blurHandler();
      },
      Enter: () => {
        blurHandler();
      },
    };
    keyboardActions[key]?.();
  };

  return {
    displayedData, keyDownHandler,
  };
}
