import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import getBrandFamily from '../../tools/getBrandFamily';
import SingleAemEspot from '../SingleAemEspot/SingleAemEspot';
import SingleCmcEspot from '../SingleCmcEspot/SingleCmcEspot';

const getStoreAttributeData = gql`
  query storeAttributes {
    config {
      hasAEMCategoryHero: genericType(name: "hasAEMCategoryHero")
    }
  }
`;

const CategoryHeroEspot = ({ brand, categoryId }) => {
  const { data: storeAttributeData } = useQuery(getStoreAttributeData);

  const brandFamily = getBrandFamily(brand);

  const hasAEMCategoryHero = storeAttributeData?.config?.hasAEMCategoryHero?.value || false;

  const espotId = `${categoryId}-${brand.toUpperCase()}-category-hero`;

  return (
    <>
      {hasAEMCategoryHero
        ? <SingleAemEspot espotId={espotId} />
        : <SingleCmcEspot categoryId={categoryId} espotId={`${brandFamily}-CategoryHeroEMS`} />}
    </>
  );
};

CategoryHeroEspot.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default CategoryHeroEspot;
