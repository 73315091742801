import getSelectedFacets from './getSelectedFacets';

const getFacetMap = (event, facet) => {
  const selectedCheckBox = event.target.value.split(',');
  const facetMap = getSelectedFacets(facet);
  let checkedFacets = [];
  // Check if selected checkbox is in already selected facet filters
  if (facetMap.has(selectedCheckBox[0])) {
    checkedFacets = facetMap.get(selectedCheckBox[0]);
  }
  // if checked, add it to the checkedFacets or remove it
  if (event.target.checked) {
    checkedFacets.push(selectedCheckBox[1]);
  } else {
    const index = checkedFacets.indexOf(selectedCheckBox[1]);
    if (index !== -1) {
      checkedFacets.splice(index, 1);
    }
  }

  // if no checked values exist for a facet (i.e. "Category", etc),
  // delete from map or set it to selected facet
  if (checkedFacets.length === 0) {
    facetMap.delete(selectedCheckBox[0]);
  } else {
    facetMap.set(selectedCheckBox[0], checkedFacets);
  }
  return facetMap;
};

export default getFacetMap;
