import Switch from 'anf-core-react/components/Switch';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import useTranslatedText from '../../../hooks/useTranslatedText';

const HighContrastToggle = ({
  id = 'switcher-contrast-footer',
}) => {
  const highContrastLabel = useTranslatedText('highContrast', { fallback: 'High Contrast' });
  const [isHighContrast, setIsHighContrast] = useState(false);

  useEffect(() => {
    const { localStorage } = window || {};
    if (localStorage) {
      const contrast = localStorage.getItem('contrast');
      setIsHighContrast(contrast === 'high');
    }
  }, []);

  useEffect(() => {
    document.documentElement.dataset.contrast = isHighContrast ? 'high' : 'default';
    const { localStorage } = window || {};
    if (localStorage) {
      localStorage.setItem('contrast', isHighContrast ? 'high' : 'default');
    }
  }, [isHighContrast]);

  const handleHighContrast = () => {
    setIsHighContrast((prevHighContrast) => !prevHighContrast);
  };

  const highContrast = highContrastLabel.value;
  return (
    <div>
      <Switch
        id={id}
        isChecked={isHighContrast}
        onChange={handleHighContrast}
      >
        {highContrast}
      </Switch>
    </div>
  );
};

HighContrastToggle.propTypes = {
  id: PropTypes.string,
};

export default HighContrastToggle;
