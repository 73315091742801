import { Button } from 'anf-core-react';
import React, { useContext } from 'react';
import gridTopContext from '../../context/gridTopContext';
import useTranslatedText from '../../hooks/useTranslatedText';

const ProductGridScrollButton = () => {
  const backToTop = useTranslatedText('backToTop', { fallback: 'Back to Top' });
  const gridElement = useContext(gridTopContext);

  const scrollToGrid = () => {
    let gridTop = 0;
    const { current } = gridElement;
    if (current) {
      // Find top of element relative to the viewport
      const { top } = current.getBoundingClientRect();
      // Calculate top relative to the document
      gridTop = top + window.scrollY;
    }

    // Scroll to top of grid with room for approx 110px sticky header
    window.scrollTo({
      left: 0,
      top: (gridTop - 115),
      behavior: 'smooth',
    });
  };

  return (
    <Button
      onClick={scrollToGrid}
      type="button"
      variant="tertiary-dark"
    >
      {backToTop.value}
    </Button>
  );
};

export default ProductGridScrollButton;
