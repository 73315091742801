import { Button } from 'anf-core-react';
import {
  arrayOf, bool, func, number, shape, string,
} from 'prop-types';
import React from 'react';
import { getAppliedFiltersFromFacet } from '../../tools/getIndividualFacet';
import getPreferenceButtonData from './tools/getPreferenceButtonData';

export default function PreferenceButtons({
  appliedFacets = [],
  dbfFilters = {},
  numberOfSuggestions = 3,
  onFacetToasterChange = () => {},
  visitorAffinities = {},
}) {
  if (visitorAffinities?.keys?.length === 0) {
    return null;
  }
  const buttonData = getPreferenceButtonData(appliedFacets, visitorAffinities, dbfFilters);
  const handlePreferenceButtonClick = (event) => {
    const [facet, filter] = event.target.value.split(':');
    const appliedFiltersFromFacet = getAppliedFiltersFromFacet(appliedFacets, facet);
    onFacetToasterChange(facet, [
      ...appliedFiltersFromFacet,
      filter,
    ], 'preserved');
  };

  const buttons = [];
  buttonData.some((button) => {
    buttons.push(
      <Button
        key={button.text}
        onClick={handlePreferenceButtonClick}
        type="button"
        value={button.value}
        variant="tertiary-light"
      >
        {button.text}
      </Button>,
    );

    return buttons.length >= numberOfSuggestions;
  });

  return buttons;
}

PreferenceButtons.propTypes = {
  appliedFacets: arrayOf(string),
  dbfFilters: shape({
    sizeFilters: shape({
      facetId: string,
      facetName: string,
      filters: arrayOf(shape({
        count: number,
        name: string,
        enabled: bool,
      })),
    }),
  }),
  numberOfSuggestions: number,
  onFacetToasterChange: func,
  visitorAffinities: shape({
    alpha_size: arrayOf(shape({ v: string })),
    fit: arrayOf(shape({ v: string })),
    length: arrayOf(shape({ v: string })),
    numeric_size: arrayOf(shape({ v: string })),
  }),
};
