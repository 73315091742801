const loadImage = (imageUrl = '', fetchPriority = '') => (
  new Promise((resolve, reject) => {
    const img = document.createElement('img');
    if (fetchPriority) img.fetchPriority = fetchPriority;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(imageUrl);
    };
    img.onabort = () => {
      reject(imageUrl);
    };
    img.src = imageUrl;
  })
);

// https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
// Added fetchPriority to try to make sure PFO images load before others on scroll
const loadImages = async (imageUrls = [], fetchPriority = '') => {
  const imagePromises = [];
  imageUrls.forEach((imageUrl) => {
    imagePromises.push(loadImage(imageUrl, fetchPriority));
  });
  return Promise.allSettled(imagePromises);
};

export default loadImages;
