import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import getBrandFamily from '../../tools/getBrandFamily';
import SingleAemEspot from '../SingleAemEspot/SingleAemEspot';
import SingleCmcEspot from '../SingleCmcEspot/SingleCmcEspot';

const getStoreAttributeData = gql`
  query storeAttributes {
    config {
      hasAEMCategoryBottomSpot: genericType(name: "hasAEMCategoryBottomSpot")
    }
  }
`;

const CategoryBottomEspot = ({ brand, categoryId }) => {
  const { data: storeAttributeData } = useQuery(getStoreAttributeData);

  return (
    <>
      {
        storeAttributeData?.config?.hasAEMCategoryBottomSpot?.value
          ? <SingleAemEspot espotId={`${categoryId}-${brand.toUpperCase()}-categorybottomspot`} />
          : (
            <SingleCmcEspot
              categoryId={categoryId}
              espotId={getBrandFamily(brand) === 'hol' ? 'gridCategoryBottomSpot' : 'Grid_CategoryBottomSpot'}
            />
          )
      }
    </>
  );
};

CategoryBottomEspot.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default CategoryBottomEspot;
