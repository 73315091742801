import PropTypes from 'prop-types';
import React from 'react';
import {
  useLocalStorage,
} from 'react-use';
import useDigitalData from '../../hooks/useDigitalData';
import useTranslatedText from '../../hooks/useTranslatedText';
import { RECENT_SEARCHES_KEY } from '../../tools/constants';
import removeFalsyItems from '../../tools/removeFalsyItems';
import $window from '../../tools/window';
import { DD_SEARCH_HELP_TOPICS } from '../DigitalDataProvider';
import SearchDefaultList from './SearchDefaultList';
import listStyles from './SearchDefaultView.module.scss';
import useHelpTopics from './hooks/useHelpTopics';

export default function SearchDefaultView({
  children = null,
  isFormFocused = false,
  listItemSubmitHandler = () => {},
  popularSearchesList,
  wrapperId = '',
}) {
  const {
    [DD_SEARCH_HELP_TOPICS]: helpTopicsFlag,
  } = useDigitalData([
    DD_SEARCH_HELP_TOPICS,
  ]);

  const popularSearchHeader = useTranslatedText('popularSearchesHeader', { fallback: 'Popular Searches' });
  const recentSearchesLabel = useTranslatedText('recentSearchesLabel', { fallback: 'Recent Searches' });
  const searchLabel = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });
  const helpLinksLabel = useTranslatedText('helpLinks', { fallback: 'Helpful Links' });

  const isList = wrapperId === 'editable-search-list';
  const [recentSearchesList, setRecentSearchesList] = useLocalStorage(RECENT_SEARCHES_KEY, []);

  const helpLinksData = useHelpTopics(helpTopicsFlag ?? false);

  const onSubmit = (event) => {
    if (event.type === 'click' || event.key === 'Enter') {
      listItemSubmitHandler(event.target);
    }
  };

  const handleHelpButtonClick = (event) => {
    const { target } = event;
    if ($window.digitalData) {
      $window.digitalData.trigger('universal_click', {
        event_type: 'click',
        data_text: `Search-${target?.innerText}`,
        data_action: 'click',
      });
    }
    // Redirect to the user selected help topic
    window.location.href = target.value;
  };

  const removeRecentSearches = () => {
    setRecentSearchesList([]);
  };

  const listData = [
    recentSearchesList.length > 0 && {
      clearAllButtonHandler: removeRecentSearches,
      dataType: 'recent search',
      hasClearAllButton: true,
      headingLabel: recentSearchesLabel?.value,
      options: recentSearchesList.map(
        (item) => ({
          key: item,
          title: item,
        }),
      ),
      eventHandler: onSubmit,
    },
    popularSearchesList.length > 0 && {
      dataType: 'popular search',
      headingLabel: popularSearchHeader?.value,
      options: popularSearchesList.map(
        (item) => ({
          key: item,
          title: item,
        }),
      ),
      eventHandler: onSubmit,
    },
    helpTopicsFlag && {
      dataType: 'help topics',
      headingLabel: helpLinksLabel?.value,
      options: helpLinksData?.helpLinks?.map(
        (item) => ({
          key: item.id,
          title: item.title,
          url: item.url,
        }),
      ),
      eventHandler: handleHelpButtonClick,
    },
  ];

  return (
    <div
      aria-label={searchLabel.value}
      className={listStyles.listWrapper}
      data-expanded={!!isFormFocused}
      data-focused={!!isFormFocused}
      id={wrapperId}
      role="listbox"
      tabIndex="-1"
    >
      {removeFalsyItems(listData).map((list) => (
        <SearchDefaultList
          key={list.dataType}
          clearAllButtonHandler={list.clearAllButtonHandler}
          dataType={list.dataType}
          hasClearAllButton={list.hasClearAllButton}
          headingLabel={list.headingLabel}
          isList={isList}
          optionClickHandler={list.eventHandler}
          options={list.options}
        />
      ))}
      {children}
    </div>
  );
}

SearchDefaultView.propTypes = {
  children: PropTypes.node,
  isFormFocused: PropTypes.bool,
  listItemSubmitHandler: PropTypes.func.isRequired,
  popularSearchesList: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  wrapperId: PropTypes.string,
};
