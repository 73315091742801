import React from 'react';
import SimpleProductCard from '../../SimpleProductCard/SimpleProductCard';
import style from '../styles/similarModal.module.scss';

/**
 * @description Get modified product object for simple product card
 * @param {Object} product
 * @returns {Object}
 */
const getSimplifiedProduct = (product) => ({
  ...product,
  imageId: product.kic,
  primaryFaceOutImage: product.imageSet.primaryFaceOutImage,
});

/**
 * @description Get product card jsx from API data
 * @param {[Object]} products array of products
 * @param {Function} setIsQuickViewOpen state setter for quickview
 * @param {String} productId
 * @param {String} brand
 * @param {String} rows
 * @param {String} imageHostName
 * @returns {{key: String, productCard: React.JSX.Element}[]} array of productCards with keys
 */
const getDesktopProductCards = (
  products,
  setIsQuickViewOpen,
  productId,
  brand,
  rows,
  imageHostName,
) => products
  .filter((item) => productId !== item.id)
  .slice(0, parseInt(rows, 10))
  .map((item) => ({
    key: item.id.toString(),
    productCard: (
      <SimpleProductCard
        key={item.id.toString()}
        brand={brand}
        imageHostName={imageHostName}
        index={parseInt(item.id, 10)}
        item={getSimplifiedProduct(item)}
        setIsQuickViewOpen={() => setIsQuickViewOpen(true)}
        showQuickviewOnClick
      />
    ),
  }));

/**
 * @description Get mobile product card nodes
 * @param {Object[]} productCardData array of objects with key and productCard properties
 * @returns {React.JSX.Element[]} array of mobile card nodes
 */
const getMobileProductCards = (productCardData) => productCardData.map((card) => (
  <div key={card.key} className={style.mobileCard}>
    {card.productCard}
  </div>
));

export {
  getDesktopProductCards,
  getMobileProductCards,
};
