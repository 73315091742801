import { gql } from '@apollo/client';

const TEXT_DATA = gql`
  query Text($pairKey: String!, $replacements: [String]) {
    textFor {
      pairWithReplacements(pairKey: $pairKey, replacements: $replacements) {
        key
        value
      }
    }
  }
`;

const IMAGE_HOST_NAME = gql`
  query ImageHostName {
    imageHost {
      brand
      imageHostName
    }
  }
`;

const COUNTRY_DATA = gql`
  query getCountriesData {
    countries {
      countryCode
      name
    }
  }
`;

export {
  COUNTRY_DATA,
  IMAGE_HOST_NAME,
  TEXT_DATA,
};
