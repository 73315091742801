import { useEffect } from 'react';
import getPageType from '../tools/getPageType';
import $window from '../tools/window';

export default function useFilterTriggerAnalytics(storeDetails, filter) {
  useEffect(() => {
    // When the toggle is on and we have a store, send to dataLayer
    if (filter === `localStoreId:${storeDetails?.storeId}` && storeDetails?.storeName) {
      $window.digitalData.trigger(`${getPageType()}_filter_applied`, {
        search_filter_applied_category: storeDetails.storeName,
        search_filter_applied_value_name: 'Same Day Delivery',
        event_name: 'filter_applied',
        tealium_event: 'filters_applied',
      });
    }
  }, [storeDetails, filter]);
}
