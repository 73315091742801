import Icon from 'anf-core-react/components/Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import style from './MyListButton.module.scss';

const MyListButton = ({ myListLink, label, isActive = false }) => (
  <a
    aria-label={label}
    className={classNames(style.saves, { [style.active]: isActive })}
    href={myListLink}
  >
    <Icon icon="heart" size="m" />
  </a>
);

MyListButton.propTypes = {
  myListLink: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
};

export default MyListButton;
