import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';

const propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.node.isRequired,
  hasVisibleLegend: PropTypes.bool,
  horizontalAlignment: PropTypes.oneOf([
    'left',
    'center',
    'right',
  ]),
  layout: PropTypes.oneOf([
    '',
    '2-up',
    '3-up',
    '4-up',
    '5-up',
    '6-up',
  ]),
  size: PropTypes.oneOf([
    'default',
    'small',
  ]),
  verticalAlignment: PropTypes.oneOf([
    'top',
    'middle',
    'bottom',
  ]),
};

function SwatchTileGroup({
  children,
  hasVisibleLegend = false,
  horizontalAlignment = null,
  layout = null,
  legend,
  size = 'default',
  verticalAlignment = null,
}) {
  const elementChangeWarning = useTranslatedText('elementChangeWarning', { fallback: 'Activating this element will cause content on the page to be updated.' });
  return (
    <>
      <h2 aria-live="assertive" className="screen-reader-text">
        <TmntHtml
          property={elementChangeWarning.key || ''}
          value={elementChangeWarning.value || ''}
        />
      </h2>
      <div
        className="swatch-tile-group"
        data-horizontal-alignment={horizontalAlignment}
        data-layout={layout}
        data-size={size}
        data-testid="swatch-tile-group"
        data-vertical-alignment={verticalAlignment}
      >
        <fieldset>
          <legend className={hasVisibleLegend ? 'h4' : 'screen-reader-text'}>
            {legend}
          </legend>
          <div
            className="swtg-input-outer-wrapper"
            data-testid="swtg-input-outer-wrapper"
          >
            {children}
          </div>
        </fieldset>
      </div>
    </>
  );
}

SwatchTileGroup.propTypes = propTypes;

export default SwatchTileGroup;
