import PropTypes from 'prop-types';

const mySavesItemType = PropTypes.shape({
  id: PropTypes.shape({
    productId: PropTypes.string,
    kic: PropTypes.string,
    sku: PropTypes.string,
  }),
});

export default mySavesItemType;
