import { Button, Icon } from 'anf-core-react';
import { arrayOf, func, string } from 'prop-types';
import React from 'react';
import style from './FacetToaster.module.scss';

const LaunchToasterButton = ({
  appliedFiltersFromFacet,
  facetName,
  openToaster,
  appliedFilterText,
}) => (
  <Button
    classList={style.openToaster}
    onClick={openToaster}
    variant={appliedFiltersFromFacet?.length > 0 ? 'secondary' : 'tertiary-light'}
  >
    {`${facetName}${appliedFilterText}`}
    <Icon icon="down-thin" />
  </Button>
);

LaunchToasterButton.propTypes = {
  appliedFiltersFromFacet: arrayOf(string),
  facetName: string,
  openToaster: func,
  appliedFilterText: string,
};

export default LaunchToasterButton;
