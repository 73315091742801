import { useQuery, useMutation } from '@apollo/client';
import {
  DropDown, Toaster, Button, Icon,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import $window from '../../tools/window';
import { ERROR_MESSAGE } from '../Messages/Messages';
import style from './BOBToaster.module.scss';
import { USERCONFIG_DATA, USER_PREFERENCES_REQUEST } from './queries';
import getDropDownOptions from './tools/getDropDownOptions';

const BOBToaster = ({
  defaultCountry,
  handleToasterClose,
  isBOBToasterOpen,
}) => {
  const shipTo = useTranslatedText('shipto', { fallback: 'Ship To' });
  const regionalPreferences = useTranslatedText('regionalPreferences', {
    fallback: 'Regional Preferences',
  });
  const regionalPreferencesNote = useTranslatedText(
    'regionalPreferencesNote',
    {
      fallback:
        'Pricing, promotions, and product availability may vary by location. Items in your bag will be transferred based on availability.',
    },
  );
  const language = useTranslatedText('language', { fallback: 'Language' });
  const currency = useTranslatedText('currency', { fallback: 'Currency' });
  const updatePreferencesTmnt = useTranslatedText('updatePreferences', { fallback: 'Update Preferences' });
  const updatedTmnt = useTranslatedText('updated', { fallback: 'Updated' });

  const browserLanguageCode = window?.navigator?.language || '';
  const hostName = window?.location.hostname || '';
  const url = window?.location.href || '';

  const [languages, setLanguages] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState(defaultCountry);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [changedDropdowns, setChangedDropdowns] = useState({
    countryDropDown: false,
    languageDropDown: false,
    currencyDropDown: false,
  });
  // State for managing dropdown disabling
  const [isCountryDisabled, setIsCountryDisabled] = useState(false);
  const [isLanguageDisabled, setIsLanguageDisabled] = useState(false);
  const [isCurrencyDisabled, setIsCurrencyDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const reloadPage = () => {
    window.location.reload();
  };

  const {
    previousData, loading, error, data: userConfigData,
  } = useQuery(USERCONFIG_DATA, {
    variables: {
      countryCode: selectedCountryOption,
      languageCodeFromBrowser: browserLanguageCode,
    },
  });

  const [updateUserPreferences] = useMutation(USER_PREFERENCES_REQUEST);

  useEffect(() => {
    if (!hasFetched && userConfigData && userConfigData.userConfig) {
      const {
        languages: fetchedLanguages,
        currencies: fetchedCurrencies,
      } = userConfigData.userConfig;

      if (fetchedLanguages.length > 0) {
        const [activeLanguage] = fetchedLanguages.filter((languageObj) => languageObj.isActive);
        setLanguages(fetchedLanguages);
        setSelectedLanguage(activeLanguage.languageCode);
      }

      if (fetchedCurrencies.length > 0) {
        const [firstCurrency] = fetchedCurrencies;
        setCurrencies(fetchedCurrencies);
        setSelectedCurrency(firstCurrency.name);
      }
      setHasFetched(true);
    }
  }, [userConfigData, hasFetched]);

  const handleMutationError = (mutationError) => {
    setIsUpdated(false);
    console.error('Error:', mutationError);
  };

  const handleMutationComplete = (mutationData) => {
    const { updateUserPreferencesRequest } = mutationData.data;
    setIsUpdated(true);

    const redirectUrl = updateUserPreferencesRequest?.redirectingUrl;
    if (updateUserPreferencesRequest.language && redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      });
    } else {
      setTimeout(reloadPage);
    }
  };

  const handleCountryMutationComplete = (mutationData) => {
    const { updateUserPreferencesRequest } = mutationData.data;
    setIsUpdated(true);

    if (updateUserPreferencesRequest.url && updateUserPreferencesRequest.transferBag) {
      window.location.href = updateUserPreferencesRequest.url;
    }

    if (
      updateUserPreferencesRequest.response.success
      || updateUserPreferencesRequest.refresh
    ) {
      setTimeout(reloadPage);
    }
  };

  const handleCountryChangeEvent = (event) => {
    const newCountry = event.target.value;
    setSelectedCountryOption(newCountry);
    setChangedDropdowns((prev) => ({ ...prev, countryDropDown: true }));
    setIsLanguageDisabled(true);
    setIsCurrencyDisabled(true);
    setIsDisabled(false);
  };

  const handleLanguageChangeEvent = (event) => {
    const newLanguageCode = event.target.value;
    setSelectedLanguage(newLanguageCode);
    setChangedDropdowns((prev) => ({ ...prev, languageDropDown: true }));
    setIsCountryDisabled(true);
    setIsCurrencyDisabled(true);
    setIsDisabled(false);
  };

  const handleCurrencyChangeEvent = (event) => {
    const newCurrency = event.target.value;
    setSelectedCurrency(newCurrency);
    setChangedDropdowns((prev) => ({ ...prev, currencyDropDown: true }));
    setIsCountryDisabled(true);
    setIsLanguageDisabled(true);
    setIsDisabled(false);
  };

  const handleUpdatePreferencesClick = () => {
    const dropDownLanguage = changedDropdowns.languageDropDown
      ? languages.find((languageObj) => languageObj.languageCode === selectedLanguage)
      : null;

    const dataToSend = {
      ...(changedDropdowns.countryDropDown && {
        countryCode: selectedCountryOption,
      }),
      ...(changedDropdowns.languageDropDown && {
        languageCode: selectedLanguage,
        languageId: dropDownLanguage.languageId,
        hostName,
        url,
      }),
      ...(changedDropdowns.currencyDropDown && { currencyCode: selectedCurrency }),
    };

    const isCountryOnlyUpdate = dataToSend.countryCode
      && !dataToSend.languageCode
      && !dataToSend.currencyCode;

    if (isCountryOnlyUpdate) {
      $window.dispatchEvent(new CustomEvent('mfe:resetFranchiseCountry'));
      updateUserPreferences({
        variables: { countryCode: dataToSend.countryCode },
      })
        .then(handleCountryMutationComplete)
        .catch(handleMutationError);
    } else {
      updateUserPreferences({ variables: dataToSend })
        .then(handleMutationComplete)
        .catch(handleMutationError);
    }
  };

  const renderCurrencyElement = () => {
    if (currencies.length < 2) {
      const [firstCurrency] = currencies;
      return <p>{`${currency.value}: ${selectedCurrency} (${firstCurrency?.code})`}</p>;
    }
    return (
      <DropDown
        id="currency-option-dropdown"
        isDisabled={isCurrencyDisabled}
        label={currency.value}
        onChange={handleCurrencyChangeEvent}
        options={getDropDownOptions(currencies || [], 'code', 'code')}
        value={selectedCurrency}
      />
    );
  };

  if (error) return <div>{ERROR_MESSAGE}</div>;
  if (loading && !previousData) return null;

  return (
    <Toaster
      closeButtonLabelKey=""
      direction="from-right"
      heading={(
        <div className="scope-1892">
          <span key="standard-header-span" className="h2">{regionalPreferences.value}</span>
        </div>
      )}
      id="user-preference-toaster"
      isOpen={isBOBToasterOpen}
      onClose={handleToasterClose}
    >
      <div className={`scope-1892 ${style.modalWrapper}`}>
        <span aria-level="2" id="user-preference-note" role="heading">
          {regionalPreferencesNote.value}
        </span>
        <DropDown
          id="country-option-dropdown"
          isDisabled={isCountryDisabled}
          label={shipTo.value}
          onChange={handleCountryChangeEvent}
          options={getDropDownOptions(userConfigData?.userConfig.countries || [], 'name', 'countryCode')}
          value={selectedCountryOption}
        />
        <DropDown
          id="language-option-dropdown"
          isDisabled={isLanguageDisabled}
          label={language.value}
          onChange={handleLanguageChangeEvent}
          options={getDropDownOptions(languages || [], 'name', 'languageCode')}
          value={selectedLanguage}
        />
        {renderCurrencyElement()}
        <Button isDisabled={isDisabled} isFullWidth onClick={handleUpdatePreferencesClick} variant="secondary">
          <span>
            {isUpdated ? (
              <>
                <Icon
                  icon="checkmark"
                  labelText="labelText-Check"
                  size="s"
                />
                {updatedTmnt.value}
              </>
            ) : (
              updatePreferencesTmnt.value
            )}
          </span>
        </Button>
      </div>
    </Toaster>
  );
};

BOBToaster.propTypes = {
  isBOBToasterOpen: PropTypes.bool,
  handleToasterClose: PropTypes.func,
  defaultCountry: PropTypes.string,
};

BOBToaster.defaultProps = {
  isBOBToasterOpen: false,
  handleToasterClose: () => { },
  defaultCountry: '',
};

export default BOBToaster;
