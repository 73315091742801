import { useLazyQuery } from '@apollo/client';
import {
  Button, Toaster,
} from 'anf-core-react';
import {
  arrayOf, bool, func, string, number, oneOfType, shape,
} from 'prop-types';
import React, { useCallback } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import { FACET_DICTIONARY } from '../../tools/constants';
import getSelectedFacets from '../../tools/getSelectedFacets';
import { getStringifiedFacetArray, buildFacetArrayString } from '../../tools/getStringifiedFacetArray';
import ClearAllButton from '../ClearAllButton/ClearAllButton';
import Loader from '../Loader';
import style from './FacetToaster.module.scss';
import FilterButtons from './FilterButtons';
import Heading from './Heading';
import RESULT_COUNT_QUERY from './queries';

/**
 * renders a toaster component that slides up from the bottom of the screen
 * and displays size information for the current search results
 * @param {object} props - the props object
 * @param {Array} props.appliedFacets - the filters that are currently applied
 * @param {string} props.departmentId - the id of the department
 * @param {string} props.facetId - the id of the facet
 * @param {string} props.facetName - the name of the facet
 * @param {string} props.filter - any store filters
 * @param {function} props.onCheckboxChange - the function to call when a checkbox is changed
 * @param {Array} props.options - the options to display in the toaster
 * @param {string} props.options.name - the name of the option
 * @param {number} props.options.count - the count of the option
 * @param {boolean} props.options.enabled - whether the option is enabled
 * @param {number} props.resultCount - the number of results for the current search
 * @param {object} props.searchTerm - the current search term
 * @param {function} props.setToasterState - the function to set the toaster state
 * @param {object} props.toasterState - the current state of the toaster
 */
const FacetToaster = ({
  appliedFacets = [],
  departmentId = '',
  facetId = '',
  facetName = '',
  filter = '',
  onFacetToasterChange = () => { },
  options = [],
  searchTerm = '',
  resultCount = 0,
  setToasterState,
  toasterState,
}) => {
  const shopBy = useTranslatedText('departmentDropdownLabel', { fallback: 'Shop By' });
  const showResults = useTranslatedText(
    'showResults',
    {
      fallback: `Show ${toasterState.count} Results`,
      replacements: [(toasterState?.count ?? '').toString()],
    },
  );

  const getFacetString = useCallback(() => {
    const facetArray = getSelectedFacets([
      ...appliedFacets,
      buildFacetArrayString(facetId, toasterState.selectedFilters),
    ]);
    return getStringifiedFacetArray([...facetArray]);
  }, [appliedFacets, facetId, toasterState.selectedFilters]);

  const [getResultsCount, { loading }] = useLazyQuery(RESULT_COUNT_QUERY, {
    variables: {
      departmentId,
      facet: toasterState.selectedFilters.length === 0 ? null : getFacetString(),
      filter,
      searchTerm,
    },
    onCompleted: (data) => {
      setToasterState((prevState) => (
        {
          ...prevState,
          count: data?.searchResults?.stats?.refinedTotal || data?.searchResults?.stats?.total || 0,
        }
      ));
    },
  });

  const clearSelections = useCallback(() => {
    setToasterState((prevState) => ({
      ...prevState,
      count: resultCount,
      selectedFilters: [],
    }));
  }, [resultCount, setToasterState]);

  const onOptionButtonClick = useCallback((buttonCount, name) => {
    getResultsCount();
    setToasterState((prevState) => ({
      ...prevState,
      count: buttonCount,
      selectedFilters: [...toasterState.selectedFilters, name],
    }));
  }, [getResultsCount, setToasterState, toasterState.selectedFilters]);

  const applyFilter = useCallback((event) => {
    event.preventDefault();
    onFacetToasterChange(facetId, toasterState.selectedFilters, 'drawer');
    setToasterState((prevState) => ({ ...prevState, isOpen: false }));
  }, [facetId, onFacetToasterChange, setToasterState, toasterState.selectedFilters]);

  const toasterHeading = <Heading facetName={facetName} shopBy={shopBy?.value} />;
  return (
    <>
      <Toaster
        closeButtonLabelKey=""
        direction="from-bottom"
        heading={toasterHeading}
        id="facet-toaster"
        isOpen={toasterState.isOpen}
        onClose={() => setToasterState({ ...toasterState, isOpen: false })}
      >
        <form className="scope-1892" onSubmit={applyFilter}>
          <div className={`${style.optionButtonWrapper}`}>
            <FilterButtons
              filters={options}
              onOptionButtonClick={onOptionButtonClick}
              selectedFilters={toasterState.selectedFilters}
              size={facetId === 'sizes' || facetId === FACET_DICTIONARY.sizes ? 'default' : 'long'}
            />
          </div>

          <Button
            classList={style.submitButton}
            isFullWidth
            onClick={applyFilter}
            type="submit"
            variant="secondary"
          >
            {loading ? <Loader variant="inverse-dots" /> : showResults?.value}
          </Button>
          <ClearAllButton
            isBordered
            onClick={clearSelections}
          />
        </form>
      </Toaster>
    </>
  );
};

FacetToaster.propTypes = {
  appliedFacets: oneOfType([
    arrayOf(string),
    string,
  ]),
  departmentId: string,
  facetId: string,
  facetName: string,
  filter: string,
  onFacetToasterChange: func,
  resultCount: number,
  options: arrayOf(shape({
    count: number,
    name: string,
    enabled: bool,
  })),
  searchTerm: string,
  setToasterState: func,
  toasterState: shape({
    count: number,
    isOpen: bool,
    selectedFilters: arrayOf(string),
  }),
};

export default FacetToaster;
