import Cookies from 'js-cookie';
import useSessionCookies from './useSessionCookies';

const useUserState = (storeId) => {
  const { getSessionId } = useSessionCookies();
  const userId = getSessionId(storeId);
  const isGeneric = !userId;
  const userType = Cookies.get('userType');

  const isGenericUser = () => isGeneric;
  const isLoggedIn = () => !isGeneric && userType === 'R';
  const isGuestUser = () => !isGeneric && !isLoggedIn();

  return { isGenericUser, isGuestUser, isLoggedIn };
};

export default useUserState;
