import React from 'react';

export default function AppendIcon() {
  return (
    <svg fill="white" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6026_3186)">
        <path d="M3.74507 3.27385L10.2612 3.33837L10.2678 2.67173L2.93557 2.59914C2.84603 2.59825 2.75989 2.63343 2.69657 2.69675C2.63325 2.76007 2.59807 2.84621 2.59895 2.93575L2.67155 10.268L3.33818 10.2614L3.27367 3.74526L13.0671 13.5387L13.5385 13.0673L3.74507 3.27385Z" fill="#666666" />
      </g>
      <defs>
        <clipPath id="clip0_6026_3186">
          <rect fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
}
