import { Accordion } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import getSelectedFacets from '../../tools/getSelectedFacets';
import FacetsCheckboxGroup from './FacetCheckBoxGroup';

function FacetsAccordion({
  brand,
  facet = [],
  facetItem,
  onCheckBoxChange = () => {},
}) {
  const handleAccordionClick = (event) => {
    window.dispatchEvent(new CustomEvent('analytics.filterAccordionOpened', event.target));
  };

  const [description, setDescription] = useState('');

  const { large: isDesktop } = useContext(BreakpointContext);
  const screenSizeVariable = isDesktop ? 'large-screen' : 'small-screen';

  const [isExpanded, setIsExpanded] = useState(false);
  const clickHandler = (event) => {
    setIsExpanded(!isExpanded);
    handleAccordionClick(event);
  };

  useEffect(() => {
    const facetMap = getSelectedFacets(facet);
    if (facetMap.has(facetItem.id)) {
      setDescription(facetMap.get(facetItem.id).join(', '));
    } else {
      setDescription('');
    }
  }, [facet, facetItem.id]);

  return (
    <div data-aui={`grid-filter-${facetItem.name}-${screenSizeVariable}`} data-testid="facet-accordion">
      <Accordion
        key={facetItem.id}
        brand={brand}
        headingLevel={2}
        id={facetItem.id}
        isExpanded={isExpanded}
        onClick={clickHandler}
        theme="secondary-light"
        title={facetItem.name}
        titleDescription={description}
        variant="boxed"
      >
        <FacetsCheckboxGroup
          facetGroups={facetItem.facetGroups}
          facetItemId={facetItem.id}
          onCheckBoxChange={onCheckBoxChange}
        />
      </Accordion>
    </div>
  );
}

FacetsAccordion.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  facetItem: PropTypes.objectOf(PropTypes.any).isRequired,
  // Optional props
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  onCheckBoxChange: PropTypes.func,
};

export default FacetsAccordion;
