import { gql } from '@apollo/client';

const SEARCH_RESULTS_STATS_DATA = gql`
query Search(
  $departmentId: String,
  $initialDepartmentId: String,
  $searchTerm: String!,
  $rows: String,
  ) {
  searchResults(
    departmentId: $departmentId,
    searchTerm: $searchTerm,
    initialDepartmentId: $initialDepartmentId,
    rows: $rows,
  ) {
    stats {
      total
      refinedTotal
    }
  }
}
`;

export default SEARCH_RESULTS_STATS_DATA;
