const { API_TIMEOUT } = require('../constants');

const fetchGuestSession = async (store) => {
  const response = await fetch(`/api/ecomm/${store}/session/guest`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    dataType: 'json',
    ...AbortSignal?.timeout
      ? { signal: AbortSignal.timeout(API_TIMEOUT) }
      : {},
  });

  if (!response.ok) {
    throw new Error(`Network Error: ${response.status} ${response.statusText}.`);
  }

  return response.json();
};

export default fetchGuestSession;
