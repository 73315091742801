import { node } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import BreakpointContext from '../../context/breakpoint';

const BreakpointProvider = ({ children }) => {
  const { width } = useWindowSize();
  const [breakpoints, setBreakpoints] = useState({});

  useEffect(() => {
    setBreakpoints({
      xxsmall: (width > 360),
      xsmall: (width > 540),
      small: (width > 700),
      medium: (width > 825),
      large: (width > 1025),
      xlarge: (width > 1200),
      xxlarge: (width > 1500),
    });
  }, [width]);

  return (
    <BreakpointContext.Provider value={breakpoints}>
      {children}
    </BreakpointContext.Provider>
  );
};

BreakpointProvider.propTypes = {
  children: node.isRequired,
};

export default BreakpointProvider;
