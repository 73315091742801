import PropTypes from 'prop-types';
import React from 'react';

const NavFlyoutCategoryListItem = ({
  url = '',
  name = '',
}) => (
  <li className="category-list-item">
    <a className="category-link" href={url}>
      {name}
    </a>
  </li>
);

NavFlyoutCategoryListItem.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
};

export default NavFlyoutCategoryListItem;
