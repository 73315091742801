/* eslint-disable camelcase */

import {
  LILY_AI_PRODUCT_ATTRIBUTES,
  LILY_OVERRIDES,
  SIMILAR_FALLBACK_STYLE_NAMES,
} from './constants';
import getSimilarCategory from './getSimilarCategory';
import removeBrandName from './removeBrandNameFromString';

const getLilyAIData = (product) => {
  const getValue = (attribute) => {
    const values = product.productAttrsComplex?.[attribute]?.values;

    if (!values || values.length === 0) return null;

    const firstValue = values[0]?.value;
    const secondValue = values[1]?.value;

    return attribute === 'AI_Style_Silhouette' && secondValue ? `${firstValue} ${secondValue}` : firstValue;
  };

  const result = {};
  LILY_AI_PRODUCT_ATTRIBUTES.forEach((attribute) => {
    const value = getValue(attribute);
    if (value !== null && value !== undefined) result[attribute] = value;
  });

  return result;
};

//  Get manual overrides for products Lily isn't giving good results for
const getOverrides = (productName, styleType) => LILY_OVERRIDES.reduce((accumulator, override) => {
  if (!productName.toLowerCase().includes(override)) return accumulator;
  if (styleType?.toLowerCase().includes(override)) return accumulator ? `${accumulator}, ${styleType}` : styleType;
  return accumulator ? `${accumulator}, ${override}` : override;
}, '');

const getSpecificProductType = (productName, productType, styleType) => {
  const overrides = typeof productName === 'string' ? getOverrides(productName, styleType) : null;
  if (overrides) return overrides;
  const vagueProductTypes = ['Tops', 'Pants'];

  if (productType === 'Jeans') { return `${productType} ${styleType}`; }
  if (vagueProductTypes.includes(productType) || !productType) { return styleType; }
  return productType;
};

const prepareSimilarSearchTerm = (array) => array.filter((value) => value !== undefined).join(', ');

const getSimilarStyleFromProductName = (name, keywords) => {
  const matchedKeyword = keywords.find((keyword) => name.toLowerCase().includes(keyword));
  return matchedKeyword ?? removeBrandName(name)?.split(' ')[0];
};

const getPDPStyle = (pdpStyleAttributes, productAttributes, name) => {
  const {
    AI_Product_Type,
    AI_Style_Silhouette,
    AI_Occasion,
  } = productAttributes;
  pdpStyleAttributes.push(AI_Product_Type);

  if (name.toLowerCase().includes('graphic')) {
    pdpStyleAttributes.push('graphic');
  } else if (AI_Product_Type === 'Jeans' || AI_Product_Type === 'Tops') {
    pdpStyleAttributes.push(AI_Style_Silhouette);
  } else {
    pdpStyleAttributes.push(AI_Occasion);
  }
  return pdpStyleAttributes;
};

const resolveSimilar = (product) => {
  const data = getLilyAIData(product);
  const gender = product.productAttrs.departmentName;
  const {
    breadcrumbCategoryHierarchy,
    colorFamily,
    name_EN: name,
  } = product;
  const {
    AI_Length,
    AI_Product_Type,
    AI_Sleeve_Length,
    AI_Style_Silhouette,
    AI_Dressing_Style,
    AI_Occasion,
  } = data;
  const specificProductType = getSpecificProductType(name, AI_Product_Type, AI_Style_Silhouette);
  const inBackupLogicScenerio = !AI_Product_Type;

  const sharedAttributes = [gender];
  const colorAttributes = [colorFamily];
  const styleAttributes = [];
  const pdpStyleAttributes = [gender];

  if (inBackupLogicScenerio) {
    sharedAttributes.push(getSimilarCategory(breadcrumbCategoryHierarchy).name);
    pdpStyleAttributes.push(getSimilarCategory(breadcrumbCategoryHierarchy).name);
    styleAttributes.push(getSimilarStyleFromProductName(name, SIMILAR_FALLBACK_STYLE_NAMES));
  } else {
    sharedAttributes.push(specificProductType);
    getPDPStyle(pdpStyleAttributes, data, name);
    colorAttributes.push(AI_Length || AI_Sleeve_Length);
    styleAttributes.push(
      AI_Occasion || AI_Dressing_Style,
      AI_Length,
    );
  }

  return {
    color: prepareSimilarSearchTerm([...colorAttributes, ...sharedAttributes]),
    style: prepareSimilarSearchTerm([...styleAttributes, ...sharedAttributes]),
    pdpStyle: prepareSimilarSearchTerm(pdpStyleAttributes),
  };
};

export default resolveSimilar;
