import { useQuery } from '@apollo/client';
import { RECOMMENDATIONS_DATA_QUERY } from '@xp-utilities/web';
import { Button, ButtonGroup } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import BreakpointContext from '../../context/breakpoint';
import useDigitalData from '../../hooks/useDigitalData';
import useTranslatedText from '../../hooks/useTranslatedText';
import { IMAGE_HOST_NAME } from '../../queries';
import { PLACEMENT_IDS } from '../../tools/constants';
import parsePlacementIds from '../../tools/parsePlacementIds';
import {
  DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS,
  DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS,
  DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS_TEST,
} from '../DigitalDataProvider';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import listStyle from '../SearchDefaultView/SearchDefaultView.module.scss';
import SimpleProductCard from '../SimpleProductCard/SimpleProductCard';
import style from './AutoSuggest.module.scss';
import AutoSuggestSubList from './AutoSuggestSubList';

export default function AutoSuggestResult({
  isFormFocused = false,
  isProcessing = false,
  listItemSubmitHandler,
  listPosition,
  productTypesData = [],
  productData = [],
  searchTerm = undefined,
  searchLabel = 'Search',
}) {
  const processing = useTranslatedText('processing', { fallback: 'Processing' });
  const products = useTranslatedText('autoSuggestProducts', { fallback: 'Products' });
  const suggestions = useTranslatedText('autoSuggestSuggestions', { fallback: 'Suggestions' });
  const seeAllResults = useTranslatedText('autoSuggestAllResults', { fallback: 'See all results for' });
  const [, setIsQuickViewOpen] = useState(false);
  const breakpoints = useContext(BreakpointContext);
  const [searchTermForAlgonomy, setSearchTermForAlgonomy] = useState('');

  const prepareSimpleCardData = (item) => ({
    collection: item.collection || item.collectionId,
    defaultSwatchSequence: item.defaultSwatchSequence,
    imageId: item.imageId,
    name: item.name,
    primaryFaceOutImage: `${item.imageId}_${item.primaryFaceOut || 'prod1'}`,
    productId: item.productId,
    imageSet: item.imageSet,
    imageSetProd: item?.imageSetProd ?? [],
    imageSetModel: item?.imageSetModel ?? [],
  });

  useEffect(() => {
    if (productTypesData?.length > 0) {
      setSearchTermForAlgonomy(productTypesData[0].suggestedTerm);
    } else {
      setSearchTermForAlgonomy(searchTerm);
    }
  },
  [productTypesData, searchTerm]);
  const {
    [DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS]: hasProductImagesEnabled,
    [DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS]: hasAlgonomyProductsEnabled,
    [DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS_TEST]: hasAlgonomySuggestionsTestEnabled,
  } = useDigitalData([
    DD_SEARCH_FLYOUT_IMAGE_SUGGESTIONS,
    DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS,
    DD_SEARCH_FLYOUT_ALGONOMY_SUGGESTIONS_TEST,
  ]);

  const queryData = useQuery(IMAGE_HOST_NAME, {
    skip: !hasProductImagesEnabled,
  });

  const shouldGetAlgonomyRecs = hasAlgonomyProductsEnabled && hasAlgonomySuggestionsTestEnabled && searchTermForAlgonomy !== '';

  const placementIds = `${PLACEMENT_IDS.mobile.searchtypeahead}|${PLACEMENT_IDS.desktop.searchtypeahead}`;
  const resolvePlacementIds = parsePlacementIds(placementIds, breakpoints);
  const getProductQuery = useQuery(RECOMMENDATIONS_DATA_QUERY, {
    variables: {
      searchTerm: searchTermForAlgonomy,
      hasUserConsent: true,
      placements: resolvePlacementIds,
    },
    skip: !shouldGetAlgonomyRecs,
    fetchPolicy: 'no-cache',
  });

  if (queryData.loading) {
    return (
      <div
        aria-busy="true"
        aria-live="polite"
        className={`auto-suggest-list-menu-wrapper fixed-list-container ${listStyle.listWrapper}`}
        data-expanded={!!isFormFocused}
        data-focused={!!isFormFocused}
        id="auto-suggest-desktop-list"
        tabIndex="-1"
      >
        <h2 className="h3">{LOADING_MESSAGE}</h2>
      </div>
    );
  }
  if (queryData.error) {
    return (
      <div
        aria-busy="true"
        className={`auto-suggest-list-menu-wrapper fixed-list-container ${listStyle.listWrapper}`}
        data-expanded={!!isFormFocused}
        data-focused={!!isFormFocused}
        id="auto-suggest-desktop-list"
        role="alert"
        tabIndex="-1"
      >
        <h2 className="h3">{ERROR_MESSAGE}</h2>
      </div>
    );
  }

  const productSuggestions = () => {
    if (hasAlgonomyProductsEnabled && hasAlgonomySuggestionsTestEnabled) {
      const searchTypePlacements = getProductQuery?.data?.recommendations?.placements;
      if (searchTypePlacements && searchTypePlacements.length > 0) {
        const algonomyProducts = searchTypePlacements[0].products;
        const { id, name, treatments } = searchTypePlacements[0];
        const algonomyAnalyticsObj = {
          placementObj: {
            id,
            name,
            treatments,
          },
          productIndex: 0,
        };
        return (
          <div className={style.grid}>
            {algonomyProducts?.map((item, index) => {
              const algonomyProduct = JSON.parse(item.response);
              algonomyAnalyticsObj.productIndex = index;
              return (
                <SimpleProductCard
                  key={algonomyProduct.imageId}
                  algonomyOnClickAnalytics={algonomyAnalyticsObj}
                  brand={algonomyProduct.brand}
                  index={index}
                  item={prepareSimpleCardData(algonomyProduct)}
                  listPosition={listPosition - (algonomyProducts?.length ?? 0)}
                  setIsQuickViewOpen={() => setIsQuickViewOpen(true)}
                  showQuickviewOnClick
                />
              );
            })}
          </div>
        );
      }
    }

    return (
      (
        <AutoSuggestSubList
          data={productData}
          listItemSubmitHandler={listItemSubmitHandler}
          listPosition={listPosition - (productTypesData?.length ?? 0)}
          searchTerm={searchTerm}
          suggestionType="products"
        />
      )
    );
  };

  return (
    <div
      aria-label={searchLabel}
      className={`auto-suggest-list-menu-wrapper fixed-list-container ${listStyle.listWrapper}`}
      data-expanded={!!isFormFocused}
      data-focused={!!isFormFocused}
      id="auto-suggest-desktop-list"
      role="listbox"
      tabIndex="-1"
    >
      <div className={listStyle.listContainer} data-testid="auto-suggest-list-menu-wrapper">
        {productTypesData?.length > 0 ? (
          <>
            <div data-testid="auto-suggest-list-suggestions">
              <h2 className="h3">{suggestions.value}</h2>
              <AutoSuggestSubList
                data={productTypesData}
                listItemSubmitHandler={listItemSubmitHandler}
                listPosition={listPosition}
                searchTerm={searchTerm}
                suggestionType="suggestions"
              />
            </div>
          </>
        ) : null}
        {productData?.length > 0 ? (
          <>
            <div data-testid="auto-suggest-list-products">
              <h2 className="h3">{products.value}</h2>
              {hasProductImagesEnabled ? (
                <div className={style.grid}>
                  {productData.slice(0, 4).map((item, index) => (
                    <SimpleProductCard
                      key={item.imageId}
                      brand="anf"
                      handleProductCardClick={listItemSubmitHandler}
                      imageHostName={queryData?.data.imageHost.imageHostName}
                      index={index}
                      item={prepareSimpleCardData(item)}
                      listPosition={listPosition - (productTypesData?.length ?? 0)}
                    />
                  ))}
                </div>
              ) : productSuggestions()}
            </div>
          </>
        ) : null}
        <ButtonGroup variant="vertical-spaced">
          <Button
            isFullWidth
            isProcessing={isProcessing}
            type="submit"
            value={searchTerm}
            variant="secondary"
          >
            {isProcessing ? `${processing.value}...` : `${seeAllResults.value} "${searchTerm}"`}
          </Button>
        </ButtonGroup>
        {isProcessing && (productTypesData?.length > 0 || productData?.length > 0) ? (
          <div className={style.spinWrapper}>
            <div className={style.dotSpin} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

AutoSuggestResult.propTypes = {
  productTypesData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  isFormFocused: PropTypes.bool,
  isProcessing: PropTypes.bool,
  listItemSubmitHandler: PropTypes.func.isRequired,
  listPosition: PropTypes.number.isRequired,
  productData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  searchLabel: PropTypes.string,
  searchTerm: PropTypes.string,
};
