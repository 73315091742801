import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import getBrandFamily from '../../tools/getBrandFamily';
import SingleAemEspot from '../SingleAemEspot/SingleAemEspot';
import FooterCookies from './FooterCookies';
import FooterGrid from './FooterGrid/FooterGrid';
import FooterMobileLogo from './FooterMobileLogo';
import FooterUtility from './FooterUtility';
import style from './footer.module.scss';

const FooterWrapper = ({
  brand,
  defaultCountry = '',
}) => {
  const brandFamily = getBrandFamily(brand);

  const { large: isDesktop } = useContext(BreakpointContext);

  return (
    <footer className={classNames(style.footer, 'scope-1892')}>
      <div className={style.espot}>
        <SingleAemEspot espotId={`footer--${brand}-top`} />
      </div>
      <FooterGrid brand={brand} defaultCountry={defaultCountry} />
      {isDesktop && (<FooterUtility isAnf={brandFamily === 'anf'} />)}
      {(!isDesktop && brandFamily === 'anf') && (<FooterMobileLogo brand={brand} />)}
      {(!isDesktop) && (<FooterCookies />)}

    </footer>
  );
};

FooterWrapper.propTypes = {
  brand: PropTypes.string.isRequired,
  defaultCountry: PropTypes.string,
};

export default FooterWrapper;
