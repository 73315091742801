import { MAX_RECENT_SEARCHES, RECENT_SEARCHES_KEY } from './constants';

const filterRecentSearchList = (submitedSearchTerm, previousList) => {
  const newSearchList = [submitedSearchTerm,
    ...previousList.filter((term) => term !== submitedSearchTerm)];
  if (newSearchList.length > MAX_RECENT_SEARCHES) {
    return newSearchList.slice(0, MAX_RECENT_SEARCHES);
  }
  return newSearchList;
};
  // Save a search term to localStorage
const saveRecentSearch = (newSearchTerm) => {
  let searches = JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY)) || [];
  searches = filterRecentSearchList(newSearchTerm, searches);
  localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(searches));
};

export default saveRecentSearch;
