import { useQuery } from '@apollo/client';
import useDigitalData from '../../../hooks/useDigitalData';
import {
  DD_EDITABLE_SEARCH_FLAG,
  DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
} from '../../DigitalDataProvider';
import { STORE_ATTRIBUTES } from '../queries';

const useFeatureFlags = () => {
  const { data: storeAttributeData } = useQuery(STORE_ATTRIBUTES);

  const {
    [DD_EDITABLE_SEARCH_FLAG]: editableSearchFlag = true,
    [DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS]: departmentSuggestionsFlag = false,
  } = useDigitalData([
    DD_EDITABLE_SEARCH_FLAG,
    DD_NO_RESULTS_DEPARTMENT_SUGGESTIONS,
  ]);

  const facetsLeftRailFlag = storeAttributeData?.config?.facetsLeftRailFlag?.value ?? false;
  const hasShopMyStoreEnabled = storeAttributeData?.config?.hasShopMyStoreEnabled?.value ?? false;

  return {
    editableSearchFlag,
    departmentSuggestionsFlag,
    facetsLeftRailFlag,
    hasShopMyStoreEnabled,
  };
};

export default useFeatureFlags;
