import { ACTION } from '../../../tools/constants';

const updateSearchTermToSuggested = (resolvedSearchTerm, searchTerm, dispatch) => {
  if (resolvedSearchTerm !== searchTerm) {
    dispatch({
      type: ACTION.SEARCHTERM,
      payload: { searchTerm: resolvedSearchTerm },
    });
  }
};

export default updateSearchTermToSuggested;
