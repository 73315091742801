import Cookies from 'js-cookie';

const getGeoLocation = () => Cookies.get('geoLocation');

const getGeoLocationCountry = (defaultCountry) => {
  // state, zipcode are not used in this function, but it might help understand the cookie structure
  const locationIndex = {
    country: 0,
    state: 1,
    zipcode: 2,
  };
  const cookie = getGeoLocation();
  // setting it to default country
  let locationValue = defaultCountry;

  // The cookie is a string with pattern [country]:[state]:[zipcode]
  // When it is returned, the cookie is seperated by colons.

  if (typeof cookie === 'string' && cookie.indexOf(':') > -1) {
    const seperatedCookieValues = cookie.split(':');

    locationValue = seperatedCookieValues[locationIndex.country];
  }

  return locationValue;
};

export default getGeoLocationCountry;
