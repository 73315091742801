const getBrandFlag = (brand) => {
  switch (brand) {
    case 'anf':
      return '1';
    case 'kids':
      return '2';
    default:
      return '3';
  }
};

export default getBrandFlag;
