import PropTypes from 'prop-types';
import React from 'react';
import DepartmentSelectorContainer from '../DepartmentSelector/DepartmentSelectorContainer';
import FilterAndFacets from './FilterAndFacets';
import style from './SearchAside.module.scss';

export default function SearchAside({
  departmentId,
  elasticDepartmentData,
  facets,
  facetsLeftRailFlag,
  handleCheckBoxChange,
  handleStoreFilter,
  hasShopMyStoreEnabled,
  initialDepartmentId,
  intlSearchTerm,
  isClearAllButtonEnabled,
  isDesktop,
  isPriceFilterEnabled,
  noMatchFoundFor,
  onClearAllBtnClick,
  onDepartmentChange,
  parameters,
  showAdditionalControls,
  showCorrectedSearchText,
  storeDetails,
}) {
  return (
    <aside className={`${style.aside} product-grid__col--minor search-leftrail-wrapper`}>
      <div className="product-grid__left--nav scope-1892">
        <DepartmentSelectorContainer
          departmentId={departmentId}
          elasticDepartmentData={elasticDepartmentData}
          initialDepartmentId={initialDepartmentId}
          intlSearchTerm={intlSearchTerm}
          isDesktop={isDesktop}
          noMatchFoundFor={noMatchFoundFor}
          onDepartmentChange={onDepartmentChange}
          parameters={parameters}
          showAdditionalControls={showAdditionalControls}
          showCorrectedSearchText={showCorrectedSearchText}
        />
        <div aria-label="Filters" className="rs-facets rs-facets--vertical " role="region" tabIndex="-1">
          <div className="rs-facets-leftrail__wrapper" id="facet-leftrail-wrapper-largescreen">
            <div className="filters-wrapper">
              <FilterAndFacets
                facets={facets}
                facetsLeftRailFlag={facetsLeftRailFlag}
                handleCheckBoxChange={handleCheckBoxChange}
                handleStoreFilter={handleStoreFilter}
                hasShopMyStoreEnabled={hasShopMyStoreEnabled}
                isClearAllButtonEnabled={isClearAllButtonEnabled}
                isDesktop={isDesktop}
                isPriceFilterEnabled={isPriceFilterEnabled}
                onClearAllBtnClick={onClearAllBtnClick}
                parameters={parameters}
                showAdditionalControls={showAdditionalControls}
                storeDetails={storeDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

SearchAside.propTypes = {
  departmentId: PropTypes.string,
  elasticDepartmentData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      defaultCategoryIdBySite: PropTypes.string,
    }),
  ),
  facets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string,
      selected: PropTypes.bool,
    })),
  })),
  facetsLeftRailFlag: PropTypes.bool,
  handleCheckBoxChange: PropTypes.func,
  handleStoreFilter: PropTypes.func,
  hasShopMyStoreEnabled: PropTypes.bool,
  initialDepartmentId: PropTypes.string,
  intlSearchTerm: PropTypes.string,
  isClearAllButtonEnabled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isPriceFilterEnabled: PropTypes.bool,
  noMatchFoundFor: PropTypes.shape({
    value: PropTypes.string,
  }),
  onClearAllBtnClick: PropTypes.func,
  onDepartmentChange: PropTypes.func,
  parameters: PropTypes.shape({
    brand: PropTypes.string,
    departmentId: PropTypes.string,
    facet: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.string,
    searchTerm: PropTypes.string,
  }),
  showAdditionalControls: PropTypes.bool,
  showCorrectedSearchText: PropTypes.bool,
  storeDetails: PropTypes.shape({
    storeId: PropTypes.string,
    storeName: PropTypes.string,
    storeAddress: PropTypes.string,
    storeCity: PropTypes.string,
    storeState: PropTypes.string,
    storeZip: PropTypes.string,
    storePhone: PropTypes.string,
    storeHours: PropTypes.string,
    storeDetailsUrl: PropTypes.string,
    storeMapUrl: PropTypes.string,
  }),
};
