/**
 * @description Removes the last attribute from a comma-separated
 * list if the list has more than one attribute
 * @param {String} keyword keyword string
 * @returns {String} keyword string without the last attribute
 */
const removeLastAttribute = (keyword) => {
  const keywordArray = keyword.split(', ');
  return keywordArray.length > 1 ? keywordArray.slice(0, -1).join(', ') : keyword;
};

export default removeLastAttribute;
