/**
 * Builds a string with the facetId and facetValues
 * @param {string} facetId - the name of the facet
 * @param {Array} facetValues - the values of the facet
 * @returns {string} the facet string of the form facetId:("facetValue1" "facetValue2")
 */
const buildFacetArrayString = (facetId, facetValues) => `${facetId}:(${facetValues.map((options) => `"${options}"`).join(' ')})`;

/**
 * Converts a facet array to a string of facet:filter pairs separated by spaces
 * @param {Array} facet - map of facet:[filter] strings
 * @returns {Array} array of strings
 */
const getStringifiedFacetArray = (facet) => {
  const arr = facet.map(
    ([key, value]) => buildFacetArrayString(key, value),
  );
  return arr;
};

export { getStringifiedFacetArray, buildFacetArrayString };
