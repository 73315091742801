import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';

export default function NumberOfResults({
  className = '',
  count,
  numberFirst = false,
}) {
  const results = useTranslatedText('results', { fallback: 'results' });
  return (
    <span className={className} role="status">
      {numberFirst ? (
        <>
          <span>
            {count}
            {' '}
          </span>
          <TmntHtml property={results.key || ''} value={results.value || ''} />
        </>
      ) : (
        <>
          <TmntHtml property={results.key || ''} value={results.value || ''} />
          <span>
            :
            {' '}
            {count}
          </span>
        </>

      )}
    </span>
  );
}

NumberOfResults.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number.isRequired,
  numberFirst: PropTypes.bool,
};
