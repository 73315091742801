import PropTypes from 'prop-types';
import React from 'react';
import style from './Heading.module.scss';

const Heading = ({ shopBy = 'Shop By', facetName }) => (
  <div className={`scope-1892 ${style.facetToasterHeading}`}>
    <span key="standard-header-span" className="h2">
      {`${shopBy} ${facetName}`}
    </span>
  </div>
);

Heading.propTypes = {
  shopBy: PropTypes.string,
  facetName: PropTypes.string,
};

export default Heading;
