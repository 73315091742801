import {
  CheckboxGroup,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import BreakpointContext from '../../context/breakpoint';
import ControlledCheckbox from '../ControlledCheckbox/ControlledCheckbox';

function FacetsCheckboxGroup({
  facetGroups,
  facetItemId,
  onCheckBoxChange,
}) {
  const { large: isDesktop } = useContext(BreakpointContext);
  const screenSizeVariable = isDesktop ? '-large-screen-' : '-small-screen-';

  useEffect(() => {
    const parameters = new URLSearchParams(window.location.search);
    if (!parameters.has('searchTerm')) {
      window.dispatchEvent(new CustomEvent('facetChange', {}));
    }
  });

  const getFacetCheckBoxes = (
    facetValues,
    facetId,
  ) => facetValues.map((facetValue, index) => (
    <div key={facetValue.id} data-testid="facet-checkbox">
      <ControlledCheckbox
        key={facetValue.id}
        description={facetValue.name}
        id={`${facetId}${screenSizeVariable}${facetValue.id.replace(/\W+/g, '-').toLowerCase()}-${index}`}
        isChecked={facetValue.checked}
        isDisabled={!facetValue.enabled}
        name={facetValue.name}
        onChange={onCheckBoxChange}
        value={`${facetId},${facetValue.id}`}
      />
    </div>
  ));

  const facetGroupItems = facetGroups.map((facetCheckboxGroupItem) => (
    <CheckboxGroup key={facetItemId} title={facetCheckboxGroupItem.name}>
      {getFacetCheckBoxes(
        facetCheckboxGroupItem.facetValues,
        facetItemId,
        facetCheckboxGroupItem.orderNumber,
      )}
    </CheckboxGroup>
  ));

  return (
    <>
      {facetGroupItems}
    </>
  );
}

FacetsCheckboxGroup.propTypes = {
  facetGroups: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  facetItemId: PropTypes.string.isRequired,
  onCheckBoxChange: PropTypes.func.isRequired,
};

export default FacetsCheckboxGroup;
