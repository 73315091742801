import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { ERROR_MESSAGE } from '../Messages/Messages';
import BrandTab from './BrandTab';
import style from './BrandTabs.module.scss';

const getBrands = gql`
query Brands {
  brands {
    url
    description
    id
    icons {
      defaultIcon
      hoverIcon
    }
    selected
  }
}
`;

function BrandTabs() {
  const { loading, error, data } = useQuery(getBrands);

  if (loading) return null;
  if (error) return ERROR_MESSAGE;
  const brandList = data.brands.map((brand) => (
    <BrandTab
      key={brand.id}
      brand={brand.id}
      icons={brand.icons}
      label={brand.description}
      selected={brand.selected}
      url={brand.url}
    />
  ));
  return (
    <ul className={style.tabs}>
      {brandList}
    </ul>
  );
}

export default BrandTabs;
