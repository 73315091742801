import $window from './window';

/**
 * Get the current page type from $window.digitalData
 * Only be called from an event handler
 * Otherwise needs to be wrapped in a useEffect
 * @returns {String} Name of current page type
 */
const getPageType = () => {
  const pageType = $window.digitalData.get('page.pageKind');
  return typeof pageType === 'string' ? pageType.replace(/ /g, '_') : '';
};

export default getPageType;
