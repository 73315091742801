import { useEffect, useState } from 'react';
import {
  DIGITAL_DATA_TIMEOUT,
  BROWSE_BETA_GROUP_PREFIX,
  SEEK_BETA_GROUP_PREFIX,
} from '../tools/constants';
import getDigitalDataSubscriptionPromises from '../tools/getDigitalDataSubscriptionPromises';
import $window from '../tools/window';

// maybe abstract this out of this file to be in the tools section.
// doing so lets us test this file and mock out this function.

const useDigitalData = (keys) => {
  const [digitalData, setDigitalData] = useState({});

  useEffect(() => {
    if ($window.digitalData) {
      const digitalDataAccumulator = {};
      const searchParameterObject = new URLSearchParams($window.location.search);
      const groupParameter = searchParameterObject.get('group');
      const isBetaGroup = groupParameter?.includes(BROWSE_BETA_GROUP_PREFIX)
        || groupParameter?.includes(SEEK_BETA_GROUP_PREFIX);

      Promise.allSettled(getDigitalDataSubscriptionPromises(keys, DIGITAL_DATA_TIMEOUT))
        .then((values) => {
          keys.forEach((key, index) => {
            if (values[index].status !== 'fulfilled') return;
            let keyValue = values[index].value;
            const sessionStorageValue = $window.sessionStorage.getItem(key);

            if (sessionStorageValue !== null) {
              keyValue = sessionStorageValue === 'true';
            }

            if (isBetaGroup) {
              $window.sessionStorage.setItem(key, values[index].value);
              keyValue = values[index].value;
            }

            digitalDataAccumulator[key] = keyValue;
          });
          setDigitalData({ ...digitalDataAccumulator });
        })
        .catch(() => {
          // noop
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { ...digitalData };
};

export default useDigitalData;
