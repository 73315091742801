import PropTypes from 'prop-types';
import React from 'react';
import FacetsLeftRail from '../FacetsLeftRail/FacetsLeftRail';
import StoreToggle from '../StoreToggle/StoreToggle';
import style from './SearchAside.module.scss';

export default function FilterAndFacets({
  facets,
  facetsLeftRailFlag,
  handleCheckBoxChange,
  handleStoreFilter,
  hasShopMyStoreEnabled,
  isClearAllButtonEnabled,
  onClearAllBtnClick,
  parameters,
  showAdditionalControls,
  storeDetails,
}) {
  if (!facetsLeftRailFlag) return null;

  return (
    <>
      {hasShopMyStoreEnabled && showAdditionalControls && (
      <div className={style.storeToggleWrapper}>
        <StoreToggle
          handleStoreFilter={handleStoreFilter}
          isShopMyStore={parameters.filter !== ''}
          storeDetails={storeDetails}
        />
        <hr />
      </div>
      )}
      {facets?.length > 0 && parameters.searchTerm && (
      <FacetsLeftRail
        brand={parameters.brand}
        facet={parameters.facet}
        facetData={facets}
        hasClearButtonEnabled={isClearAllButtonEnabled}
        onCheckBoxChange={handleCheckBoxChange}
        onClearAllBtnClick={onClearAllBtnClick}
      />
      )}
    </>
  );
}

FilterAndFacets.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string,
      selected: PropTypes.bool,
    })),
  })),
  facetsLeftRailFlag: PropTypes.bool,
  handleCheckBoxChange: PropTypes.func,
  handleStoreFilter: PropTypes.func,
  hasShopMyStoreEnabled: PropTypes.bool,
  isClearAllButtonEnabled: PropTypes.bool,
  noMatchFoundFor: PropTypes.shape({
    value: PropTypes.string,
  }),
  onClearAllBtnClick: PropTypes.func,
  parameters: PropTypes.shape({
    brand: PropTypes.string,
    departmentId: PropTypes.string,
    facet: PropTypes.arrayOf(PropTypes.string),
    filter: PropTypes.string,
    searchTerm: PropTypes.string,
  }),
  showAdditionalControls: PropTypes.bool,
  storeDetails: PropTypes.shape({
    storeId: PropTypes.string,
    storeName: PropTypes.string,
  }),
};
