import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSearchParam } from 'react-use';
import SingleAemEspot from '../SingleAemEspot';

/**
 * This espot is rendered client side due to reliance on the window (obfuscated by `useSearchParam`)
 * TODO: make this espot SSR
 */
const CategoryMarketingCampaignEspot = ({ brand = '', categoryId = '' }) => {
  const [espotId, setEspotId] = useState('');

  const cmpParam = useSearchParam('cmp');
  const icmpParam = useSearchParam('icmp');

  useEffect(() => {
    let suffix = '';
    if (cmpParam) {
      const cmpSlots = cmpParam.split(':');
      // Get the 11th slot if it exists
      if (cmpSlots.length >= 11) {
        suffix = `-${cmpSlots[10]}`;
      }
    }
    if (!suffix && icmpParam) {
      const icmpSlots = icmpParam.split(':');
      // Get the last slot if it exists
      if (icmpParam.length > 0) {
        suffix = `-${icmpSlots.slice(-1)}`;
      }
    }
    setEspotId(`${categoryId}-${brand.toUpperCase()}-cmp${suffix}`);
  }, [brand, categoryId, cmpParam, icmpParam]);

  return espotId ? <SingleAemEspot espotId={espotId} /> : null;
};

CategoryMarketingCampaignEspot.propTypes = {
  brand: PropTypes.string,
  categoryId: PropTypes.string,
};

export default CategoryMarketingCampaignEspot;
