import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import ClearAllButton from '../ClearAllButton/ClearAllButton';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import style from './Refine.module.scss';

export default function ToasterFooter({
  isFacetSelected,
  onDoneBtnClick = () => {},
  onClearAllBtnClick = () => {},
  resultsCount = 0,
}) {
  const done = useTranslatedText('done', { fallback: 'Done' });
  const [footerWidth, setFooterWidth] = useState('');

  useEffect(() => {
    const toasterModalContentWidth = document.querySelector('#refine-modal-toaster .modal-content')?.offsetWidth;
    setFooterWidth(`${toasterModalContentWidth}px`);
  }, []);

  return (
    <div className={style.footerContainer} style={{ width: footerWidth }}>
      <div className={style.footer}>
        <div className={`rs-search-details__results ${style.results}`}>
          <NumberOfResults count={resultsCount} numberFirst />
        </div>
        {
            isFacetSelected
              ? <ClearAllButton onClick={onClearAllBtnClick} />
              : null
          }
      </div>
      <div data-testid="refine-done-button">
        <Button isFullWidth onClick={onDoneBtnClick} variant="secondary">
          {done.value}
        </Button>
      </div>
    </div>
  );
}
ToasterFooter.propTypes = {
  isFacetSelected: PropTypes.bool.isRequired,
  onDoneBtnClick: PropTypes.func,
  onClearAllBtnClick: PropTypes.func,
  resultsCount: PropTypes.number,
};
