const getEspotSpreads = ({
  columnElementValue,
  maxSmallColumnSpread = 2,
  maxLargeColumnSpread = 3,
}) => {
  const smallColumnSpread = columnElementValue >= maxSmallColumnSpread
    ? maxSmallColumnSpread
    : columnElementValue;
  const largeColumnSpread = columnElementValue >= maxLargeColumnSpread
    ? maxLargeColumnSpread
    : columnElementValue;

  return {
    large: largeColumnSpread.toString(),
    small: smallColumnSpread.toString(),
  };
};

export default getEspotSpreads;
