import { ProductDetail, ProductName, ProductPrice } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import { priceType, promoMessageType } from '../types';

const ProductCardDetails = ({
  memberPrice = undefined,
  name,
  price,
  promoMessaging = undefined,
  productNameSize = undefined,
}) => (
  <>
    <ProductName size={productNameSize}>
      <h2 data-aui="product-card-name">
        {name}
      </h2>
    </ProductName>
    <ProductPrice
      description={price.description}
      discountPrice={price?.discountPrice}
      discountText={price?.discountText}
      originalPrice={price.originalPrice}
      variant={price.variant}
    />
    {memberPrice && (
      <ProductPrice
        description={memberPrice.description}
        discountText={memberPrice.discountText}
        originalPrice={memberPrice.originalPrice}
        variant={memberPrice.variant}
      />
    )}
    {promoMessaging?.message && (
      <ProductDetail variant="small">
        <div className="promo-badge">
          <span className={`promo-info--${promoMessaging.type === 'tiered' ? 'bogo' : promoMessaging.type}`}>
            {promoMessaging.message}
          </span>
        </div>
      </ProductDetail>
    )}
  </>
);

ProductCardDetails.propTypes = {
  name: PropTypes.string.isRequired,
  price: priceType.isRequired,
  promoMessaging: promoMessageType,
  memberPrice: priceType,
  productNameSize: PropTypes.string,
};

export default ProductCardDetails;
