import $window from './window';

/**
 * Sets a subscription on the $window.digitalData
 * for each of the passed keys.
 * @param {Array} keys Array of keys.
 * @param {Integer} waitTime Time to wait before unsubscribing. Defaulted to 0.
 * @returns {Array} An Array of Promises
 */
function getDigitalDataSubscriptionPromises(keys, waitTime = 0) {
  return keys.map((keyName) => {
    let timeoutId = null;
    let resolver;
    let rejector;
    const thePromise = new Promise((res, rej) => {
      resolver = res;
      rejector = rej;
    });
    const subscription = $window.digitalData.subscribe(keyName,
      function keySubscriptionHandler(value) {
        if (typeof value !== 'undefined') {
          this.unsubscribe();
          if (timeoutId !== null) {
            clearTimeout(timeoutId);
            timeoutId = null;
          }
          resolver(value);
        }
      });
    timeoutId = setTimeout(() => {
      subscription.unsubscribe();
      rejector();
    }, waitTime);
    return thePromise;
  });
}

export default getDigitalDataSubscriptionPromises;
