import {
  arrayOf, shape, string,
} from 'prop-types';
import imageSetType from './imageSetType';
import priceType from './priceType';
import promoMessageType from './promoMessageType';
import swatchType from './swatchType';

const productType = shape({
  id: string.isRequired,
  imageSet: imageSetType.isRequired,
  name: string.isRequired,
  price: priceType.isRequired,
  promoMessaging: promoMessageType,
  badges: arrayOf(shape({
    text: string,
    theme: string,
  })),
  collection: string,
  defaultSwatchSequence: string,
  departmentName: string,
  kic: string,
  memberPrice: priceType,
  partNumber: string,
  similarKeyword: string,
  productPageUrl: string,
  shortDescriptors: arrayOf(string),
  socialProofMessage: string,
  swatchList: arrayOf(swatchType),
});

export default productType;
