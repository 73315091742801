import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import useDigitalData from '../../../hooks/useDigitalData';
import { FRANCHISE_PORTAL_SESSION_KEY } from '../../../tools/constants';
import getUserCountry from '../../../tools/getUserCountry';
import $window from '../../../tools/window';
import { DD_FRANCHISE_PORTAL } from '../../DigitalDataProvider';
import FRANCHISE_DATA from '../queries';

const useFranchiseCountryData = (defaultCountry) => {
  const {
    [DD_FRANCHISE_PORTAL]: franchisePortalEligible,
  } = useDigitalData([
    DD_FRANCHISE_PORTAL,
  ]);

  const [initialValue, setInitialValue] = useState(false);

  useEffect(() => {
    const value = sessionStorage.getItem(FRANCHISE_PORTAL_SESSION_KEY);
    setInitialValue(value);
  }, []);

  const [viewedFranchisePortal, setViewedFranchisePortal] = useSessionStorage(
    FRANCHISE_PORTAL_SESSION_KEY,
    initialValue,
  );
  useEffect(() => {
    const handleFranchiseCountryReset = () => {
      setViewedFranchisePortal(false);
    };

    $window.addEventListener('mfe:resetFranchiseCountry', handleFranchiseCountryReset);

    return () => {
      $window.removeEventListener('mfe:resetFranchiseCountry', handleFranchiseCountryReset);
    };
  }, [setViewedFranchisePortal]);
  const franchiseEligible = franchisePortalEligible && !viewedFranchisePortal;
  const { data, loading, error } = useQuery(FRANCHISE_DATA, {
    skip: !franchiseEligible,
  });
  if (!franchiseEligible || loading || error) {
    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching franchise data', error);
    }
    return {
      countryCode: '',
      countryName: '',
      redirectUrl: '',
      showFranchisePortal: false,
      setViewedFranchisePortal,
    };
  }

  const franchiseData = data?.franchiseCountries || {};
  const { countryFromIP, countries } = franchiseData;
  const activeCountry = countries?.find((country) => country.countryCode === countryFromIP);
  const userCountry = getUserCountry(franchiseData, defaultCountry);

  return {
    countryCode: activeCountry?.countryCode ?? '',
    countryName: activeCountry?.name ?? '',
    redirectUrl: activeCountry?.redirectUrl ?? '',
    showFranchisePortal: activeCountry?.hasFranchisePortal
        && userCountry.countryCode === countryFromIP,
    setViewedFranchisePortal,
  };
};

export default useFranchiseCountryData;
