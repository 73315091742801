import { gql, useQuery } from '@apollo/client';
import { useLocalStorage } from 'react-use';

const getSavesListQuery = gql`
query SavesList {
  savesList {
    productId
    kic
    sku
  }
}
`;

const getStoreAttributeData = gql`
  query StoreAttribute {
    config {
      hasMfeMySavesEnabled: genericType(name: "hasMfeMySavesEnabled")
    }
  }
`;

const getNewTimeStamp = () => new Date().getTime() + 60000;

const getDefaultMySaves = () => ({
  list: {},
  savesCount: 0,
  timestamp: -1,
  timeStamp: getNewTimeStamp(),
});

const formatList = (rawList) => (rawList || []).reduce((acc, curr) => {
  acc[curr.productId] = {
    productId: curr.productId,
    kic: curr.kic,
    sku: curr.sku,
  }; return acc;
}, {});

const useFetchMySaves = () => {
  const [savesListLocalStorage, setSavesListLocalStorage] = useLocalStorage('savesList', getDefaultMySaves());

  const { data: storeAttributeData } = useQuery(getStoreAttributeData);
  const hasMfeMySavesEnabled = storeAttributeData?.config?.hasMfeMySavesEnabled?.value;

  const {
    data: mySavesItems, refetch,
  } = useQuery(getSavesListQuery, {
    onCompleted: (data) => {
      const formattedList = formatList(data.savesList);
      setSavesListLocalStorage({
        ...savesListLocalStorage,
        list: formattedList,
        savesCount: data.savesList.length,
        timeStamp: getNewTimeStamp(),
      });
    },
    fetchPolicy: 'network-only',
    skip: !hasMfeMySavesEnabled,
  });

  return { mySavesItems: mySavesItems ? formatList(mySavesItems.savesList) : {}, refetch };
};

export default useFetchMySaves;
