import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import CategoryDropdown from '../CategoryDropdown/CategoryDropdown';
import CategoryGridResultsCount from '../CategoryGridResultsCount/CategoryGridResultsCount';
import ProductGridTagGroup from '../ProductGridTagGroup/ProductGridTagGroup';
import RefineButton from '../Refine';
import SortBy from '../SortBy/SortBy';
import StoreToggle from '../StoreToggle';
import style from './ProductGridHeader.module.scss';

function CategoryGridHeader({
  brand,
  categoryId,
  categoryName,
  categoryTitle = '',
  categoryUrl = '',
  facet = [],
  facetData = [],
  filter = '',
  handleStoreFilter = () => { },
  hasCategoryFacetsEnabled = false,
  hasSelectedCategoryFilters = false,
  hasShopMyStoreEnabled = false,
  leftRailMobileFlag = false,
  mobileGridHeaderFlag = false,
  navParentCategoryId,
  onCheckBoxChange = () => { },
  onClearAllBtnClick = () => { },
  onFacetTagClick = () => { },
  onSortChange = () => { },
  resultsCount,
  selectedSort = '',
  sortData = {
    sortOptions: [],
  },
  storeDetails = {
    storeName: '',
    storeId: '',
  },
}) {
  const breakpoints = useContext(BreakpointContext);
  const { large: isDesktop } = breakpoints;

  const categoryResultsCount = hasSelectedCategoryFilters ? (
    <CategoryGridResultsCount
      count={resultsCount}
      hasCategoryFacetsEnabled={hasCategoryFacetsEnabled}
      hasMobileGridHeader={mobileGridHeaderFlag}
      hasSelectedFilters={hasSelectedCategoryFilters}
      onClearAllBtnClick={onClearAllBtnClick}
    />
  ) : null;

  if (!isDesktop) {
    const screenReaderCategoryH1 = <h1 className="screen-reader-text">{categoryTitle || categoryName}</h1>;
    const baseHeader = (
      <>
        {screenReaderCategoryH1}
        {hasShopMyStoreEnabled && (
          <StoreToggle
            handleStoreFilter={handleStoreFilter}
            isShopMyStore={filter !== ''}
            storeDetails={storeDetails}
          />
        )}
      </>
    );

    if (!mobileGridHeaderFlag) return baseHeader;

    return (
      <div className={
          classNames(
            style.productGridHeader, style.productGridHeaderMobile, style.categoryGridHeader,
          )
        }
      >
        {screenReaderCategoryH1}
        <h2 className={style.categoryHeadingMobile}>
          <a href={categoryUrl}>
            {categoryName || categoryTitle}
          </a>
        </h2>
        <div className={style.categoryDropdownWrapperMobile}>
          <CategoryDropdown
            currentCategoryId={categoryId}
            parentCategoryId={navParentCategoryId}
          />
        </div>
        <div className={style.filters}>
          {categoryResultsCount}
          <div className={style.filterTrack}>
            <ProductGridTagGroup
              facet={facet}
              hasMobileGridHeader
              setFacetState={onFacetTagClick}
            />
          </div>
        </div>
        <div className={style.lastColumn}>
          <RefineButton
            brand={brand}
            facet={facet}
            facetData={facetData}
            filter={filter}
            isCategoryPage
            isFacetSelected={facet?.length > 0 || !!filter}
            leftRailMobileFlag={leftRailMobileFlag}
            onCheckBoxChange={onCheckBoxChange}
            onClearAllBtnClick={onClearAllBtnClick}
            onSortChange={onSortChange}
            resultsCount={resultsCount}
            selectedSort={selectedSort}
            sortData={sortData}
            storeDetails={storeDetails}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={
        classNames(
          style.productGridHeader, style.productGridHeaderDesktop, style.categoryGridHeader,
        )
      }
    >
      <div className={style.categoryHeadingWrapperDesktop}>
        <h1 className={`h3 ${style.categoryHeadingDesktop}`}>{categoryTitle || categoryName}</h1>
        {categoryResultsCount}
      </div>
      <div className={style.filters}>
        <div className={style.filterTrack}>
          <ProductGridTagGroup
            facet={facet}
            hasMobileGridHeader
            setFacetState={onFacetTagClick}
          />
        </div>
      </div>
      <div className={style.lastColumn}>
        {sortData.sortOptions?.length !== 0
          ? (
            <SortBy
              onSortChange={onSortChange}
              selectedSort={selectedSort}
              sortData={sortData}
            />
          )
          : null}
      </div>
    </div>
  );
}

CategoryGridHeader.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryTitle: PropTypes.string,
  categoryUrl: PropTypes.string,
  resultsCount: PropTypes.number.isRequired,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  handleStoreFilter: PropTypes.func,
  hasSelectedCategoryFilters: PropTypes.bool.isRequired,
  hasCategoryFacetsEnabled: PropTypes.bool,
  hasShopMyStoreEnabled: PropTypes.bool,
  leftRailMobileFlag: PropTypes.bool,
  mobileGridHeaderFlag: PropTypes.bool,
  navParentCategoryId: PropTypes.string.isRequired,
  onFacetTagClick: PropTypes.func,
  onSortChange: PropTypes.func,
  selectedSort: PropTypes.string,
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onClearAllBtnClick: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
  storeDetails: PropTypes.shape({
    storeName: PropTypes.string,
    storeId: PropTypes.string,
  }),
};

export default CategoryGridHeader;
