import { DropDown } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import ProductGridTagGroup from '../ProductGridTagGroup/ProductGridTagGroup';
import MiniGridFilters from './MiniGridFilters';
import style from './MiniGridHeader.module.scss';

const getFacetDropdownOptions = (
  facets,
  facetIdList,
) => facets.filter((facet) => facetIdList.includes(facet.id))
  .map(
    (facet) => ({ label: facet.name, value: facet.id }),
  );

function MiniGridHeader({
  facetIds = [],
  facetData = [],
  handleDropDownChange = () => {},
  onFacetTagClick = () => {},
  onFilterButtonClick = () => {},
  selectedFacet = {
    facetQueryParam: [],
    parentId: '',
    valueIds: [],
  },
}) {
  const departmentDropdownLabel = useTranslatedText('departmentDropdownLabel', { fallback: 'Shop By' });

  const getFacetObjById = (facetId) => facetData.find(
    (facetObj) => facetObj.id === facetId,
  );
  const getFacetValuesForDisplay = (facetId) => {
    if (!facetId) return [];
    const facetObj = getFacetObjById(facetId);
    if (!facetObj) return [];
    const { facetValues: facetValuesFromData } = facetObj;
    if (selectedFacet.valueIds.length > 0) {
      return facetValuesFromData.filter(
        (facetValue) => !selectedFacet.valueIds.includes(facetValue.id),
      );
    }
    return facetValuesFromData;
  };

  return (
    <div className={style.miniGridHeader}>
      <div className={style.facetDropDown}>
        <DropDown
          id="facet-dropdown"
          label={departmentDropdownLabel.value}
          name="facet-selection"
          onChange={handleDropDownChange}
          options={getFacetDropdownOptions(facetData, facetIds)}
          value={selectedFacet.parentId}
        />
      </div>
      <div className={style.filterTrack}>
        <ProductGridTagGroup
          facet={selectedFacet.facetQueryParam}
          hasFilters
          hasMobileGridHeader
          setFacetState={onFacetTagClick}
        />
        {selectedFacet.parentId
        && (
        <MiniGridFilters
          facetValues={getFacetValuesForDisplay(selectedFacet.parentId)}
          handleClick={onFilterButtonClick}
        />
        )}
      </div>
    </div>

  );
}

MiniGridHeader.propTypes = {
  facetIds: PropTypes.arrayOf(PropTypes.string),
  facetData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    facetValues: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
  })),
  handleDropDownChange: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  onFacetTagClick: PropTypes.func,
  selectedFacet: PropTypes.shape({
    facetQueryParam: PropTypes.arrayOf(PropTypes.string),
    parentId: PropTypes.string,
    valueIds: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default MiniGridHeader;
