import PropTypes from 'prop-types';
import React from 'react';
import ClearAllButton from '../ClearAllButton/ClearAllButton';
import NumberOfResults from '../NumberOfResults/NumberOfResults';
import style from './CategoryGridResultsCount.module.scss';

const CategoryGridResultsCount = ({
  count,
  hasCategoryFacetsEnabled = false,
  hasMobileGridHeader,
  onClearAllBtnClick = () => { },
}) => {
  const handleClearAllClick = (event) => {
    if (hasCategoryFacetsEnabled) {
      onClearAllBtnClick();
      window.dispatchEvent(new CustomEvent('clearAllButtonClick', { detail: { event } }));
    } else {
      window.dispatchEvent(new CustomEvent('mfe:clearAllFiltersButtonClick', { detail: { event } }));
    }
  };

  return (
    <div className={`${style.wrapper} ${hasMobileGridHeader ? style.wrapperWithMfeGridHeader : style.wrapperWithCrsGridHeader}`}>
      <NumberOfResults className={style.count} count={count} />
      <ClearAllButton onClick={handleClearAllClick} />
    </div>
  );
};

CategoryGridResultsCount.propTypes = {
  count: PropTypes.number.isRequired,
  hasMobileGridHeader: PropTypes.bool.isRequired,
  hasCategoryFacetsEnabled: PropTypes.bool,
  onClearAllBtnClick: PropTypes.func,
};

export default CategoryGridResultsCount;
