const filterHyperlinkShortDescriptors = (shortDescriptors, whitelist) => {
  const filteredHyperlinkShortDescriptors = [];

  shortDescriptors.forEach((formattedShortDescriptor) => {
    const lowercaseShortDescriptor = formattedShortDescriptor.toLowerCase();
    if (whitelist.has(lowercaseShortDescriptor)) {
      filteredHyperlinkShortDescriptors.push({
        text: formattedShortDescriptor,
        url: whitelist.get(lowercaseShortDescriptor),
      });
    }
  });

  return filteredHyperlinkShortDescriptors;
};

export default filterHyperlinkShortDescriptors;
