import { Headline } from 'anf-core-react';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import useTranslatedText from '../../hooks/useTranslatedText';
import TmntHtml from '../../tools/TmntHtml';
import style from './styles/similarModal.module.scss';

const NoResults = () => {
  const { large: isDesktop } = useContext(BreakpointContext);
  const nullSimilarBody = useTranslatedText('nullSimilarBody', { fallback: 'In the meantime, check out our New Arrivals or close this window to continue shopping' });
  const nullSimilarHeader = useTranslatedText('nullSimilarHeader', { fallback: 'Sorry, we don\'t currently have any matches' });

  return (
    <div className={style.nullWrapper}>
      <Headline size="m">
        <TmntHtml property={nullSimilarHeader.key || ''} value={nullSimilarHeader.value || ''} />
      </Headline>
      <Headline size={isDesktop ? 'xxs' : 'xs'} variant="subcopy">
        <TmntHtml property={nullSimilarBody.key || ''} value={nullSimilarBody.value || ''} />
      </Headline>
    </div>
  );
};
export default NoResults;
