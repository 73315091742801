import { gql } from '@apollo/client';

const FRANCHISE_DATA = gql`
  query getCountriesData {
    franchiseCountries {
      countryFromIP
      countries {
        countryCode
        name
        hasFranchisePortal
        redirectUrl
      }
    }

  }
`;

export default FRANCHISE_DATA;
