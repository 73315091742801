import { useEffect, useState } from 'react';
import $window from '../tools/window';

function useStoreData() {
  const [storeDetails, setStoreDetails] = useState(null);
  useEffect(() => {
    const updateStoreDetails = (event) => {
      if (event?.detail?.storeId && event?.detail?.storeName) {
        setStoreDetails({
          storeName: event.detail.storeName,
          storeId: event.detail.storeId,
        });
      }
    };
    $window.addEventListener('mfe:storeData', updateStoreDetails);
    return () => ($window.removeEventListener('mfe:storeData', updateStoreDetails));
  }, []);
  return storeDetails;
}

export default useStoreData;
