import { shape, string } from 'prop-types';

const priceType = shape({
  description: string.isRequired,
  discountPrice: string,
  discountText: string,
  originalPrice: string.isRequired,
  variant: string.isRequired,
});

export default priceType;
