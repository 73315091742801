import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import useTranslatedText from '../../hooks/useTranslatedText';
import getSelectedFacets from '../../tools/getSelectedFacets';
import style from './shopAllButton.module.scss';

const ShopAllButton = ({
  url,
  brand = '',
  facet = [],
}) => {
  const { large: isDesktop } = useContext(BreakpointContext);

  const activeFilters = Array.from(
    getSelectedFacets(facet),
  ).reduce((acc, curr) => [...acc, ...curr[1]], []).join(', ');

  const collectionLabel = useTranslatedText('collection', {
    fallback: 'The Collection',
  });

  const shopAll = useTranslatedText('shopAll', {
    fallback: `Shop All ${collectionLabel.value}`,
    replacements: [activeFilters.length ? activeFilters : collectionLabel.value],
  });

  return (
    <div className={style.shopAllButtonContainer}>
      <Button
        brand={brand}
        classList={style.shopAllButton}
        href={url}
        isFullWidth={!isDesktop}
        variant="tertiary-dark"
      >
        <div className={style.shopAllButtonLabel}>{shopAll.value}</div>
      </Button>
    </div>
  );
};

ShopAllButton.propTypes = {
  brand: PropTypes.string,
  url: PropTypes.string.isRequired,
  facet: PropTypes.arrayOf(PropTypes.string),
};

export default ShopAllButton;
