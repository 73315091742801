/**
  * @description Case-insensitive search for first and last index
  * of a query in a string.
  * @param {String} string String to be searched through
  * @param {String} query Search term
  * @returns {Array} Starting and ending index of matched search
  * term or [0, 0] if not found
  */
const indexesOf = (string, query) => {
  const lowerString = string.toLowerCase();
  const lowerQuery = query.toLowerCase();

  const start = lowerString.indexOf(lowerQuery);

  if (start < 0) {
    return [0, 0];
  }

  return [start, start + lowerQuery.length - 1];
};

export default indexesOf;
