import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import useFocus from '../../../hooks/useFocus';

export default function useSearchBarState(
  onSearchTermChange,
  searchInputField,
  searchTerm,
  wrapper,
  setResolvedSearchTerm,
) {
  const [editableSearchTerm, setEditableSearchTerm] = useState(searchTerm);
  const [clickedButtons, setClickedButtons] = useState(new Set());
  const [isFocused, setFocus, setFocusReturn] = useFocus(wrapper);
  const addSuggestion = (event) => {
    if (typeof event.target.value !== 'string') return;
    const newSearchTerm = `${searchTerm} ${event.target.value}`;
    onSearchTermChange({ value: newSearchTerm, submitMethod: { trigger: 'attributePill', attribute: event.target.value } });
    const newSuggestions = new Set(clickedButtons);
    newSuggestions.add(event.target.value);
    setClickedButtons(newSuggestions);
  };

  const blurHandler = (event) => {
    if (event?.relatedTarget?.className.includes('noBlur')) return;
    setFocus(false);
  };

  const focusHandler = (event) => {
    if (event?.target?.id === 'editable-search-input-field') setFocus(true);
  };

  const removeSuggestion = (suggestion) => {
    if (typeof suggestion !== 'string') return;
    const newSearchTerm = searchTerm.replace(suggestion, '').trim();
    const newClickedSuggestions = new Set(clickedButtons);
    newClickedSuggestions.delete(suggestion);
    setClickedButtons(newClickedSuggestions);
    onSearchTermChange({ value: newSearchTerm, submitMethod: { trigger: 'attributePill' } });
  };

  useUpdateEffect(() => {
    const newClickedSuggestions = new Set(clickedButtons);
    clickedButtons.forEach((clickedButton) => {
      if (!searchTerm.includes(clickedButton)) {
        newClickedSuggestions.delete(clickedButton);
      }
    });
    setClickedButtons(newClickedSuggestions);
  }, [searchTerm]);

  const submitSearchTerm = useCallback((event) => {
    event.preventDefault();
    if (editableSearchTerm === '' || (event.type === 'submit' && event.nativeEvent.submitter !== null)) return;
    onSearchTermChange({ value: editableSearchTerm, submitMethod: { trigger: 'editableSearchBar' } });
    const newClickedSuggestions = new Set(clickedButtons);
    clickedButtons.forEach((clickedButton) => {
      if (!editableSearchTerm.includes(clickedButton)) {
        newClickedSuggestions.delete(clickedButton);
      }
    });
    setClickedButtons(newClickedSuggestions);
    searchInputField.current.clearButtonFocus();
  }, [clickedButtons, editableSearchTerm, onSearchTermChange, searchInputField]);

  const focusOnSearchInput = useCallback(() => {
    searchInputField.current.focus();
    setFocusReturn(true);
  }, [searchInputField, setFocusReturn]);

  const clearSearchterm = useCallback(() => {
    onSearchTermChange({ value: '', submitMethod: { trigger: 'clear button' } });
    setResolvedSearchTerm('');
    focusOnSearchInput();
  }, [focusOnSearchInput, onSearchTermChange, setResolvedSearchTerm]);

  return [
    addSuggestion,
    blurHandler,
    clearSearchterm,
    clickedButtons,
    editableSearchTerm,
    focusHandler,
    focusOnSearchInput,
    isFocused,
    removeSuggestion,
    setEditableSearchTerm,
    submitSearchTerm,
  ];
}
