import { gql, useQuery } from '@apollo/client';
import { Button, Icon, Toaster } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import $window from '../../tools/window';
import FacetsLeftRail from '../FacetsLeftRail';
import SortBy from '../SortBy/SortBy';
import StoreToggle from '../StoreToggle/StoreToggle';
import style from './Refine.module.scss';
import stickyStyle from './StickyRefine.module.scss';
import ToasterFooter from './ToasterFooter';

const getStoreAttributeData = gql`
  query storeAttributes {
    config {
      hasShopMyStoreEnabled: genericType(name: "hasLISFilter")
    }
  }
`;

function RefineButton({
  brand,
  facet = [],
  facetData = [],
  filter = '',
  handleStoreFilter = () => { },
  hasIconOnly = false,
  isCategoryPage = false,
  isFacetSelected = false,
  leftRailMobileFlag = false,
  onCheckBoxChange = () => { },
  onClearAllBtnClick = () => { },
  onSortChange = () => { },
  resultsCount = 0,
  selectedSort = '',
  sortData = {
    sortOptions: [],
  },
  storeDetails = {
    storeName: '',
    storeId: '',
  },
}) {
  const refineText = useRef();
  const refine = useTranslatedText('refine', { fallback: 'Refine' });
  const refined = useTranslatedText('refined', { fallback: 'Refined' });
  const [isRefineModalOpen, setIsRefineModalOpen] = useState(false);

  const renderFacets = leftRailMobileFlag;

  const { data: storeAttributeData } = useQuery(getStoreAttributeData);
  const hasShopMyStoreEnabled = storeAttributeData?.config?.hasShopMyStoreEnabled?.value;

  const handleRefineClick = () => {
    if (renderFacets) {
      setIsRefineModalOpen(true);
    } else {
      $window.dispatchEvent(new CustomEvent('mfe:refineToaster'));
    }
  };
  useEffect(() => {
    const dsModalClose = (event) => {
      if (event.detail.id === 'refine-modal' && refineText) {
        refineText.current.parentNode.focus();
      }
    };
    $window.addEventListener('dsModal:Close', dsModalClose);
    return () => {
      $window.removeEventListener('dsModal:Close', dsModalClose);
    };
  }, []);
  let selectedFacetCount = facet?.length;
  selectedFacetCount = filter ? selectedFacetCount += 1 : selectedFacetCount;

  const refineData = selectedFacetCount ? {
    label: `${refined.value} (${selectedFacetCount})`,
    property: refined.key,
    variant: 'secondary',
  } : {
    label: refine.value,
    property: refine.key,
    variant: 'tertiary-light',
  };
  const configData = hasIconOnly ? {
    classList: `${style.overrideButton} ${stickyStyle.refineButton}`,
    variant: 'borderless',
    isFullWidth: false,
    innerHTML: null,
  } : {
    classList: style.overrideButton,
    variant: refineData.variant,
    isFullWidth: true,
    innerHTML: (
      <span
        ref={refineText}
        data-property={refineData.property}
      >
        {refineData.label}
      </span>),
  };

  const handleDoneBtnClick = () => {
    setIsRefineModalOpen(false);
  };

  return (
    <div className="refine-button-wrapper" data-testid="refine-button">
      <Button
        classList={configData.classList}
        isFullWidth={configData.isFullWidth}
        onClick={handleRefineClick}
        value={refineData.label}
        variant={configData.variant}
      >
        <Icon icon="filter" />
        {configData.innerHTML}
      </Button>
      {renderFacets ? (
        <Toaster
          closeButtonLabelKey=""
          direction="from-right"
          footer={(
            <div className="scope-1892">
              <ToasterFooter
                key="refine-footer"
                isFacetSelected={isFacetSelected}
                onClearAllBtnClick={onClearAllBtnClick}
                onDoneBtnClick={handleDoneBtnClick}
                resultsCount={resultsCount}
              />
            </div>
          )}
          heading={<div className="scope-1892"><span key="standard-header-span" className="h2">{refine?.value ?? 'Refine'}</span></div>}
          id="refine-modal-toaster"
          isOpen={isRefineModalOpen}
          onClose={() => setIsRefineModalOpen(false)}
        >
          <div className="scope-1892">
            {hasShopMyStoreEnabled && (
              <div className={style.storeToggleWrapper}>
                <StoreToggle
                  handleStoreFilter={handleStoreFilter}
                  isShopMyStore={filter !== ''}
                  storeDetails={storeDetails}
                />
                <hr />
              </div>
            )}
            <SortBy
              onSortChange={onSortChange}
              selectedSort={selectedSort}
              sortData={sortData}
            />
            <FacetsLeftRail
              brand={brand}
              facet={facet}
              facetData={facetData}
              isCategoryPage={isCategoryPage}
              isFacetSelected={isFacetSelected}
              onCheckBoxChange={onCheckBoxChange}
              onClearAllBtnClick={onClearAllBtnClick}
            />
          </div>
        </Toaster>

      ) : null}
    </div>
  );
}

RefineButton.propTypes = {
  brand: PropTypes.string.isRequired,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  handleStoreFilter: PropTypes.func,
  hasIconOnly: PropTypes.bool,
  isCategoryPage: PropTypes.bool,
  leftRailMobileFlag: PropTypes.bool,
  onSortChange: PropTypes.func,
  selectedSort: PropTypes.string,
  sortData: PropTypes.shape({
    sortOptions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })),
  }),
  storeDetails: PropTypes.shape({
    storeId: PropTypes.string,
    storeName: PropTypes.string,
  }),
  facetData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isFacetSelected: PropTypes.bool,
  onClearAllBtnClick: PropTypes.func,
  onCheckBoxChange: PropTypes.func,
  resultsCount: PropTypes.number,
};

export default RefineButton;
