import { gql } from '@apollo/client';

const RESULT_COUNT_QUERY = gql`
query Search(
  $departmentId: String,
  $facet: [String],
  $searchTerm: String!
  $filter: String
  ) {
  searchResults(
    departmentId: $departmentId,
    facet: $facet,
    searchTerm: $searchTerm,
    filter: $filter,
  ) {
    stats {
      total
      refinedTotal
    }
  }
}
`;

export default RESULT_COUNT_QUERY;
