import PropTypes from 'prop-types';
import React from 'react';
import style from './productCard.module.scss';

function ProductCardButtons({
  children = null,
}) {
  if (children) {
    return (
      <div
        className={style.buttons}
        data-aui="product-card-buttons"
      >
        {children}
      </div>
    );
  }
  return null;
}

ProductCardButtons.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default ProductCardButtons;
