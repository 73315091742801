//  @ts-check
import { useQuery } from '@apollo/client';
import { TEXT_DATA } from '../queries';
import { EMPTY_TMNT } from '../tools/constants';

/**
 * Returns the translated text for the given TMNT array.
 * Returns fallback text if query fails.
 * @param {String} pairKey - TMNT key to be translated.
 * @param {Object} options - Object of options for the query.
 * @param {Array} options.replacements - Array of replacements to be used in the query.
 * @param {String} options.fallback - English default text.
 * @param {Boolean} options.skip - Boolean to skip the query.
 * @returns {Object} Object of translated text for the given TMNT array.
 */
const useTranslatedText = (pairKey = '', options = {
  replacements: [],
  fallback: '',
  skip: false,
}) => {
  const {
    replacements = [],
    fallback = '',
    skip = false,
  } = options;
  const { loading, data: textData } = useQuery(TEXT_DATA, {
    variables: {
      pairKey,
      replacements,
    },
    skip,
  });

  const {
    key: tmntKey,
    value: tmntValue,
  } = textData?.textFor?.pairWithReplacements || {};

  const resolvedTmntValue = (tmntValue && tmntValue !== EMPTY_TMNT) ? tmntValue : fallback;

  const translatedTextObject = {
    key: tmntKey || pairKey,
    value: resolvedTmntValue,
  };

  if (loading) return { key: pairKey, value: '' };
  return translatedTextObject;
};

export default useTranslatedText;
