import { gql } from '@apollo/client';

const GET_MFE_COMPONENT = gql`
  query GetMfeComponent($componentName: String!, $includeScript: Boolean!, $mfeName: String!) {
    standaloneMfe(componentName: $componentName, includeScript: $includeScript, mfeName: $mfeName) {
      componentHtml
    }
  }
`;

export default GET_MFE_COMPONENT;
