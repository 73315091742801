import { Icon } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import style from './footer.module.scss';

const FooterLink = ({
  link = [{ external: false, icon: null }],
}) => (
  <>
    {link.icon ? (
      <a className={style.iconLink} data-property={link.id} href={link.url} rel="noreferrer" target={link.external ? '_blank' : ''}>
        <Icon icon={link.icon} size="s" />
        <span className={style.iconLinkText}>{link.title}</span>
      </a>
    ) : (
      <a data-property={link.id} href={link.url} rel="noreferrer" target={link.external ? '_blank' : ''}>{link.title}</a>
    )}
  </>
);

FooterLink.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
    external: PropTypes.bool,
  }),
};

export default FooterLink;
