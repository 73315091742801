import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import BrandTabs from '../BrandTabs';
import FranchisePortalModal from '../FranchisePortal/FranchisePortalModal';
import useFranchiseCountryData from '../FranchisePortal/hooks/useFranchiseCountryData';
import SkipLink from '../SkipLink/SkipLink';
import styles from './Header.module.scss';
import UnifiedHeader from './UnifiedHeader';

const getHeaderData = gql`
  query header {
    config {
      hasDisabledBrandTabs: genericType(name: "hasDisabledBrandTabs")
      hasUnifiedCatalogHeaderEnabled: genericType(name: "hasUnifiedCatalogHeaderEnabled")
      hasMFELargeScreenFlyoutNav: genericType(name: "hasMFELargeScreenFlyoutNav")
      hasMfeMySavesEnabled: genericType(name: "hasMfeMySavesEnabled")
      hasCatalogMfeFooter: genericType(name: "hasCatalogMfeFooter")
    }
  }
`;

function Header({
  country, categoryId, storePreview, storeId, catalogId, langId, brand, pageType = '', urlRoot,
}) {
  const { data: headerData } = useQuery(getHeaderData);
  const {
    countryCode,
    countryName,
    redirectUrl,
    showFranchisePortal,
    setViewedFranchisePortal,
  } = useFranchiseCountryData(country);

  if (!headerData) return null;
  const {
    hasDisabledBrandTabs,
    hasUnifiedCatalogHeaderEnabled,
    hasMFELargeScreenFlyoutNav,
    hasMfeMySavesEnabled,
    hasCatalogMfeFooter,
  } = headerData.config;
  // To use this new header, we'll want to make sure switches are on for all features inside of it
  if (
    !hasUnifiedCatalogHeaderEnabled?.value
      || !hasMFELargeScreenFlyoutNav?.value
      || !hasMfeMySavesEnabled?.value
      || !hasCatalogMfeFooter?.value
  ) {
    return (
      <>
        <SkipLink />
        {!hasDisabledBrandTabs?.value && <div className={styles.innerBrandTabs}><BrandTabs /></div>}
        {showFranchisePortal && (
        <FranchisePortalModal
          brand={brand}
          countryCode={countryCode}
          countryName={countryName}
          setViewedFranchisePortal={setViewedFranchisePortal}
          shipToLink={redirectUrl}
        />
        )}
      </>
    );
  }
  return (
    <>
      <UnifiedHeader
        brand={brand}
        catalogId={catalogId}
        categoryId={categoryId}
        country={country}
        hasDisabledBrandTabs={hasDisabledBrandTabs?.value}
        langId={langId}
        pageType={pageType}
        storeId={storeId}
        storePreview={storePreview}
        urlRoot={urlRoot}
      />
      {showFranchisePortal && (
      <FranchisePortalModal
        brand={brand}
        countryCode={countryCode}
        countryName={countryName}
        setViewedFranchisePortal={setViewedFranchisePortal}
        shipToLink={redirectUrl}
      />
      )}
    </>
  );
}

Header.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  catalogId: PropTypes.string.isRequired,
  langId: PropTypes.string.isRequired,
  pageType: PropTypes.string,
  storeId: PropTypes.string.isRequired,
  storePreview: PropTypes.string.isRequired,
  urlRoot: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default Header;
