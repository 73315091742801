import PropTypes from 'prop-types';
import React from 'react';
import getBrandFamily from '../../tools/getBrandFamily';
import SingleCmcEspot from '../SingleCmcEspot/SingleCmcEspot';

const GridCategoryEndEspot = ({ categoryId, brand }) => (
  getBrandFamily(brand) === 'hol'
    ? <SingleCmcEspot categoryId={categoryId} espotId="gridCategoryBottomSpotTwo" />
    : <SingleCmcEspot categoryId={categoryId} espotId="Grid_ShopParentCategory" />
);

GridCategoryEndEspot.propTypes = {
  categoryId: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
};

export default GridCategoryEndEspot;
