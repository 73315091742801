import { Tabs, TabPanel } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import useTabNavigation from '../../hooks/useTabNavigation';

function GenderFlyoutTabs({
  genders = [],
  onChange,
  selectedGender,
}) {
  const tabPanelIDs = [
    ...genders.map((gender) => `${gender.id}-tabpanel`),
  ];

  const [tabPreference, setTabPreference] = useState(`${selectedGender}-tabpanel`);

  const {
    refs,
    visibleTabID,
    handleTabClick,
    handleTabKeyDown,
  } = useTabNavigation(tabPreference, tabPanelIDs);

  useEffect(() => {
    setTabPreference(visibleTabID);
  }, [setTabPreference, visibleTabID]);

  const handleClick = (event) => {
    handleTabClick(event);
    onChange(event.currentTarget.getAttribute('id'));
  };

  const handleKeyDown = (event) => {
    const { code, currentTarget } = event;
    const selectedElement = currentTarget.querySelector('[aria-selected="true"]');

    if (!selectedElement) return; // No selected element found, nothing to do

    let getSibling;
    if (code === 'ArrowRight') {
      getSibling = 'nextSibling';
    } else if (code === 'ArrowLeft') {
      getSibling = 'previousSibling';
    } else {
      return; // Not an arrow key, do nothing
    }
    handleTabKeyDown(event);
    const siblingWithId = selectedElement[getSibling];
    if (siblingWithId) {
      const siblingId = siblingWithId.getAttribute('id');
      onChange(siblingId);
    }
  };

  // Unused component but design system Tabs component has Tabpanel component as a required prop
  const genderTabPanels = () => genders.map((gender) => (
    <TabPanel
      key={gender.id}
      id={`${gender.id}-tabpanel`}
      isVisible={visibleTabID === tabPanelIDs[0]}
      labelledBy={`${gender.id}-tab`}
    >
      <div />
    </TabPanel>
  ));

  const genderTabs = () => genders.map((gender, index) => (
    {
      ariaControls: `${gender.id}-tabpanel`,
      id: `${gender.id}-tab`,
      isSelected: (visibleTabID === `${gender.id}-tabpanel`),
      label: gender.name,
      onClick: handleClick,
      ref: refs[index],
    }
  ));

  return (
    <Tabs
      controls={{
        onKeyDown: handleKeyDown,
        tabs: genderTabs(),
      }}
      labelText="Gender Tabs"
    >
      {genderTabPanels()}
    </Tabs>
  );
}

export default GenderFlyoutTabs;

GenderFlyoutTabs.propTypes = {
  genders: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
  selectedGender: PropTypes.string.isRequired,
};
