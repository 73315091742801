import { useQuery } from '@apollo/client';
import {
  Button, CountrySelector, FlagBlock, Link as LinkButton,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import useDigitalData from '../../hooks/useDigitalData';
import useIsSsr from '../../hooks/useIsSsr';
import useTranslatedText from '../../hooks/useTranslatedText';
import { COUNTRY_DATA } from '../../queries';
import getUserCountry from '../../tools/getUserCountry';
import BOBToaster from '../BOBToaster';
import { DD_USER_PREFERENCE_BOB_TOASTER_FLAG } from '../DigitalDataProvider';
import { ERROR_MESSAGE } from '../Messages/Messages';
import style from './UserPreferences.module.scss';

function UserPreferences({
  defaultCountry = '',
  parentComponent = 'footer',
}) {
  const changeLabel = useTranslatedText('change', { fallback: 'Change' });
  const [isBOBToasterOpen, setisBOBToasterOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});

  const {
    data: countriesData, error, loading,
  } = useQuery(COUNTRY_DATA);
  const isSsr = useIsSsr();

  useEffect(() => {
    if (countriesData) {
      const userCountry = getUserCountry(countriesData, defaultCountry);
      if (userCountry) {
        setSelectedCountry(userCountry);
      }
    }
  }, [countriesData, defaultCountry]);

  const {
    [DD_USER_PREFERENCE_BOB_TOASTER_FLAG]: hasUserPreferenceBOBToaster,
  } = useDigitalData([DD_USER_PREFERENCE_BOB_TOASTER_FLAG]);

  if (error) return ERROR_MESSAGE;
  // This component is client-side rendered because of hydration mismatch errors
  // when a user is in US storeContext but is in different shipTo country like "APO/FPO".
  if (isSsr || loading) {
    // If the component is in the header, we want the flag icon placehoder to be shown
    // until flag is loaded
    if (parentComponent === 'header') {
      return (
        <div className={style.countrySelector}>
          <div className={style.flagButton} />
        </div>
      );
    }
    return null;
  }

  const handleCountryChangeClick = (event) => {
    event.preventDefault();
    if (!hasUserPreferenceBOBToaster) {
      const modalTrigger = document?.querySelector('.js-open-modal[data-modal="ds-modal-upref-menu__selection"]');
      if (modalTrigger) {
        modalTrigger.click();
      }
    } else {
      setisBOBToasterOpen(true);
    }
  };

  const handleToasterClose = () => {
    setisBOBToasterOpen(false);
  };

  const countrySelectorComponent = (
    <CountrySelector
      flagBlock={(
        <FlagBlock
          countryCode={selectedCountry?.countryCode || 'US'}
          countryName={selectedCountry?.name || 'United States'}
        />
      )}
      link={<LinkButton onClick={handleCountryChangeClick}>{changeLabel.value}</LinkButton>}
    />
  );

  const flagButtonComponent = (
    <Button classList={style.flagButton} onClick={handleCountryChangeClick} variant="borderless">
      <FlagBlock
        countryCode={selectedCountry?.countryCode || 'US'}
        size="l"
      />
    </Button>
  );

  return (
    <div className={`${style.countrySelector} rs-nav__util-item--upref`}>
      {parentComponent === 'header' ? flagButtonComponent : countrySelectorComponent}
      <button className={`button ds-override js-open-modal ${style.hideButtonForCrs}`} data-modal="ds-modal-upref-menu__selection">Change</button>
      {isBOBToasterOpen && (
        <BOBToaster
          defaultCountry={selectedCountry.countryCode}
          handleToasterClose={handleToasterClose}
          isBOBToasterOpen={isBOBToasterOpen}
        />
      )}
    </div>
  );
}

export default UserPreferences;

UserPreferences.propTypes = {
  defaultCountry: PropTypes.string,
  parentComponent: PropTypes.string,
};
