/**
 * get selected filters from an array of facet:filters strings
 * @param {Array} facets - an array of facet:filters strings
 * @returns {Map} - a map of facetId to an array of selected filters
 */
const getSelectedFacets = (facets = []) => {
  const selectedFacets = new Map();
  let normalizedSelectedFacets;
  facets.forEach((facet) => {
    normalizedSelectedFacets = facet?.split(':');
    if (normalizedSelectedFacets.length === 2) {
      selectedFacets.set(
        normalizedSelectedFacets[0],
        normalizedSelectedFacets[1].split('" ').map((value) => value.replace(/[(),"]/g, '')),
      );
    }
  });
  return selectedFacets;
};

module.exports = getSelectedFacets;
