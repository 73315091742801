import { RecommendationSliders } from '@xp-utilities/web';
import React, { useContext } from 'react';
import BreakpointContext from '../../context/breakpoint';
import useIsSsr from '../../hooks/useIsSsr';
import { PLACEMENT_IDS } from '../../tools/constants';
import parsePlacementIds from '../../tools/parsePlacementIds';
import $window from '../../tools/window';
import recsContainerStyles from '../RecommendationSlider/recommendationSlider.module.scss';
import NullSearchRecommendationsContextProvider from './contexts/NullSearchRecommendationsContextProvider';

const NullSearchRecommendations = () => {
  const breakpoints = useContext(BreakpointContext);
  const placementIds = `${PLACEMENT_IDS.mobile.searchNull}|${PLACEMENT_IDS.desktop.searchNull}`;
  const placements = parsePlacementIds(placementIds, breakpoints);
  const recommendationsProps = {
    clientId: 'null-search-1',
    placements,
  };

  const handleOnProductClick = (event, product = {}) => {
    const {
      brand,
      collection,
      faceout,
      id,
      imageId,
      name,
    } = product;

    const eventDetails = {
      productId: id,
      collectionId: collection,
      imageId,
      productName: name,
      productBrand: brand,
      returnFocus: event.target,
      faceout,
    };

    const quickviewEvent = new CustomEvent('quickview:openModal', { detail: eventDetails });

    $window.dispatchEvent(quickviewEvent);
  };

  const isSsr = useIsSsr();

  // To reduce the spike in algonomy API calls with missing placementIds
  if (isSsr) return null;

  return (
    <NullSearchRecommendationsContextProvider id={recommendationsProps.clientId}>
      <div
        className={recsContainerStyles.recommendationsContainer}
        data-recommendations-client={recommendationsProps.clientId}
        data-testid={recommendationsProps.clientId}
        id={recommendationsProps.clientId}
      >
        <RecommendationSliders
          onProductClick={handleOnProductClick}
          placements={recommendationsProps.placements}
        />
      </div>
    </NullSearchRecommendationsContextProvider>
  );
};

export default NullSearchRecommendations;
