import PropTypes from 'prop-types';
import React from 'react';

function RecommendationSlider({
  placementId = '',
  styles = '',
}) {
  return (
    <div
      className={`js-recommendation-slider ${styles}`}
      data-placement-id={placementId}
    />
  );
}

RecommendationSlider.propTypes = {
  placementId: PropTypes.string,
  styles: PropTypes.string,
};

export default RecommendationSlider;
