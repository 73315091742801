/**
 * Map function to align to the DS dropdown options
 * @param {Array} options
 * @param {String} label
 * @param {String} value
 * @returns {Array} options
 */
const getDropDownOptions = (options, label, value) => options.map((option) => ({
  label: option[label],
  value: option[value],
}));

export default getDropDownOptions;
