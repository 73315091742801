import { shape, string } from 'prop-types';
import imageSetType from './imageSetType';

const swatchType = shape({
  id: string,
  name: string,
  product: shape({
    id: string,
    imageSet: imageSetType,
  }),
  swatchImage: string,
});

export default swatchType;
