import IconButton from 'anf-core-react/components/IconButton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import style from './productCard.module.scss';

const ProductCardSaveButton = ({ saved, onClick }) => {
  const saveButtonSave = useTranslatedText('saveButtonSave', { fallback: 'Save' });
  const saveButtonSaved = useTranslatedText('saveButtonSaved', { fallback: 'Saved' });
  const className = classNames('product-my-saves', style.save);
  const dataState = saved ? 'saved' : 'unsaved';
  const label = saved ? saveButtonSaved.value : saveButtonSave.value;
  const icon = saved ? 'heart-filled' : 'heart';
  return (
    <div className={className} data-state={dataState}>
      <IconButton
        icon={icon}
        labelText={label}
        onClick={onClick}
        theme="inverse"
      />
    </div>
  );
};

ProductCardSaveButton.propTypes = {
  saved: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProductCardSaveButton;
