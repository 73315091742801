import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';

const getTMNTValue = gql`
  query TMNT($pairKey: String!) {
    textFor {
      pair(pairKey: $pairKey) {
        key
        value
      }
    }
  }
`;

const NavFlyoutCategoryHeader = ({
  headerPairKey,
  additionalClasses = '',
}) => {
  const { data } = useQuery(getTMNTValue, {
    errorPolicy: 'ignore',
    variables: { pairKey: headerPairKey },
  });

  if (!data?.textFor?.pair?.value) {
    return null;
  }

  return (
    <h3 className={`nav-category-column-header ${additionalClasses}`} data-property={data.textFor.pair.key}>
      {data.textFor.pair.value}
    </h3>
  );
};

NavFlyoutCategoryHeader.propTypes = {
  headerPairKey: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};

export default NavFlyoutCategoryHeader;
