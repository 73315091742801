import { useEffect, useState } from 'react';
import $window from '../tools/window';

/**
 * Hook to determine if user is scrolling up and/or down
 * @param {Array} directionsToMonitor - Array of directions to monitor
 * @returns {Boolean} - Returns true if the scroll direction matches
 * any of the directions in the directionsToMonitor array
 * @example const isScrollingDown = useScrollDirection(['down']);
 */
const useIsScrolling = (directionsToMonitor = ['up', 'down']) => {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = $window.scrollY;

    const updateScrollDirection = () => {
      const { scrollY } = $window;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDirection
        // scroll at least 10px before updating the scroll direction
        && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    $window.addEventListener('scroll', updateScrollDirection, { passive: true });
    return () => {
      $window.removeEventListener('scroll', updateScrollDirection, { passive: true });
    };
  }, [scrollDirection]);

  return directionsToMonitor.includes(scrollDirection);
};

export default useIsScrolling;
