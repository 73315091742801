import PropTypes from 'prop-types';
import React from 'react';

// This file is strictly for rendering HTML from TMNTs
// User provided data MUST NEVER come through here
export default function TmntHtml({
  property,
  value,
}) {
  return (
    <span dangerouslySetInnerHTML={{ __html: value }} data-property={property} />
  );
}

TmntHtml.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
