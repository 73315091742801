import {
  Button,
  RadioButton,
  RadioButtonGroup,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import FranchisePortalModal from './FranchisePortalModal';

const FranchisePortalEntryPoint = ({ brand = '' }) => {
  const [isFranchiseModalOpen, setIsFranchiseModalOpen] = useState(false);
  const [currentCountry, setCurrentCountry] = useState('MX');

  const exampleCountries = {
    MX: 'Mexico',
    CA: 'Canada',
    US: 'United States',
  };

  return (
    <>
      <RadioButtonGroup legend={(
        <span className="h4">
          Country Code&nbsp;
          {currentCountry}
        </span>
      )}
      >
        <RadioButton
          id="MX"
          isChecked={currentCountry === 'MX'}
          label="Mexico"
          name="country"
          onChange={() => setCurrentCountry('MX')}
          value="MX"
        />
        <RadioButton
          id="CA"
          isChecked={currentCountry === 'CA'}
          label="Canada"
          name="country"
          onChange={() => setCurrentCountry('CA')}
          value="CA"
        />
        <RadioButton
          id="US"
          isChecked={currentCountry === 'US'}
          label="United States"
          name="country"
          onChange={() => setCurrentCountry('US')}
          value="US"
        />
      </RadioButtonGroup>
      <Button onClick={() => setIsFranchiseModalOpen(true)} variant="secondary">Open Franchise Modal</Button>
      {isFranchiseModalOpen && (
        <FranchisePortalModal
          brand={brand}
          countryCode={currentCountry}
          countryName={exampleCountries[currentCountry]}
          shipToLink="https://www.abercrombie.com"
        />
      )}
    </>
  );
};

FranchisePortalEntryPoint.propTypes = {
  brand: PropTypes.string,
};
export default FranchisePortalEntryPoint;
