import { ACTION } from './constants';
import { getPreparedFacetArrayParameter } from './getPreparedFacetArrayParameter';
import { getStringifiedFacetArray } from './getStringifiedFacetArray';

const emptyString = '';
const defaultValues = {
  historyNavigation: false,
  countryFulfillmentStore: emptyString,
  departmentId: emptyString,
  facet: [],
  filter: emptyString,
  initialDepartmentId: null,
  initialSearchTerm: emptyString,
  previousSearchterm: null,
  rows: '90',
  searchTerm: emptyString,
  sort: 'bestmatch',
  start: '0',
  storePreview: 'false',
  submitMethod: { trigger: 'toaster' },
  userInputedSearchTerm: emptyString,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.FACET_TOGGLE:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        facet: getStringifiedFacetArray(action.payload),
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.CLEAR_ALL_PARAMETERS:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        facet: defaultValues.facet,
        filter: defaultValues.filter,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.SORT_UPDATE:
      return {
        ...state,
        initialSearchTerm: defaultValues.initialSearchTerm,
        historyNavigation: defaultValues.historyNavigation,
        previousSearchterm: state.searchTerm,
        sort: action.payload,
        start: defaultValues.start,
      };

    case ACTION.DEPARTMENT_CHANGE:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        departmentId: action.payload.value === 'All' ? '' : action.payload.value,
        initialDepartmentId: defaultValues.initialDepartmentId,
        facet: defaultValues.facet,
        filter: defaultValues.filter,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.SEARCHTERM:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        facet: action?.payload?.submitMethod?.trigger === 'attributePill' ? state.facet : defaultValues.facet,
        filter: defaultValues.filter,
        initialDepartmentId: action.payload.departmentId ?? defaultValues.initialDepartmentId,
        departmentId: action.payload.departmentId ?? defaultValues.departmentId,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        searchTerm: action.payload.searchTerm ?? defaultValues.searchTerm,
        start: defaultValues.start,
        submitMethod: action.payload.submitMethod ?? defaultValues.submitMethod,
        userInputedSearchTerm: action.payload.searchTerm ?? defaultValues.userInputedSearchTerm,
      };

    case ACTION.PAGINATION:
      return {
        ...state,
        initialSearchTerm: defaultValues.initialSearchTerm,
        historyNavigation: defaultValues.historyNavigation,
        start: action.payload,
      };

    case ACTION.PRICE:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        initialDepartmentId: defaultValues.initialDepartmentId,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.LOCAL_STORE_TOGGLE:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        filter: action.payload ? `localStoreId:${action.payload}` : defaultValues.filter,
        initialSearchTerm: defaultValues.initialSearchTerm,
        previousSearchterm: state.searchTerm,
        start: defaultValues.start,
      };

    case ACTION.CRS_UPDATE:
      // CRS updates 3 types of parameters so we manually have to look to see which one to update.
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        facet: action.payload.facet
          ? getPreparedFacetArrayParameter(action.payload.facet) : defaultValues.facet,
        // only clear filter if it's explicitly sent as empty, not when it's missing
        filter: action.payload.filter ?? state.filter,
        initialDepartmentId: action.payload.initialDepartmentId ?? null,
        initialSearchTerm: action.payload.initialSearchTerm ?? defaultValues.initialSearchTerm,
        sort: action.payload.sort ?? defaultValues.sort,
        start: defaultValues.start,
      };

    case ACTION.SUGGESTED_SEARCHTERM:
      return {
        ...state,
        historyNavigation: defaultValues.historyNavigation,
        departmentId: action.payload.departmentId,
        initialSearchTerm: null,
        initialDepartmentId: null,
        searchTerm: action.payload.searchTerm,
      };

    case ACTION.HISTORY_NAVIGATION:
      return {
        ...state,
        ...(action.payload.categoryId && { categoryId: action.payload.categoryId }),
        ...(action.payload.departmentId && { departmentId: action.payload.departmentId }),
        ...(action.payload.previousSearchterm
          && { previousSearchterm: action.payload.previousSearchterm }),
        ...(action.payload.searchTerm && { searchTerm: action.payload.searchTerm }),
        historyNavigation: true,
        initialSearchTerm: null,
        initialDepartmentId: null,
        facet: action.payload.facet || defaultValues.facet,
        filter: action.payload.filter || defaultValues.filter,
        sort: action.payload.sort || defaultValues.sort,
        start: action.payload.start || defaultValues.start,
      };

    default:
      return state;
  }
};

export default reducer;
