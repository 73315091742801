import PropTypes from 'prop-types';
import React from 'react';
import ProductCardBadge from './ProductCardBadge';
import style from './productCard.module.scss';

function ProductCardBadges({ badges }) {
  const badgeElements = [];
  badges.forEach(({ text, theme }) => {
    // Omit badges with insufficient data
    if (text && theme) {
      badgeElements.push((
        <React.Fragment key={text}>
          {badgeElements.length > 0 && <span className={style.badgeSeparator}>{' | '}</span>}
          <ProductCardBadge
            text={text}
            theme={theme}
          />
        </React.Fragment>
      ));
    }
  });

  if (badgeElements.length === 0) {
    return null;
  }

  return (
    <div className={`${style.badges}`}>
      {badgeElements}
    </div>
  );
}

ProductCardBadges.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    theme: PropTypes.string,
  })).isRequired,
};

export default ProductCardBadges;
