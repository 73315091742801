import { gql, useLazyQuery, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import DigitalDataContext from '../../context/digitalData';
import espotFragment from '../../queries/fragments/Espot';
import productsFragment from '../../queries/fragments/Products';
import { DD_PAGINATION_TEST } from '../DigitalDataProvider';
import GridButtonPagination from '../GridButtonPagination/GridButtonPagination';
import GridPagination from '../GridPagination/GridPagination';
import { ERROR_MESSAGE } from '../Messages/Messages';
import FullPageGrid from '../ProductGrid/FullPageGrid';
import NoResults from '../ProductGrid/ProductGridNoResults';
import SkeletonProductGrid from '../ProductGrid/SkeletonGrid';
import { productType } from '../types';

const getCategoryData = gql`
  ${productsFragment}
  ${espotFragment}
  query CategoryGrid(
    $categoryId: String!,
    $facet: [String],
    $filter: String,
    $requestSocialProofData: Boolean,
    $rows: String!,
    $sort: String,
    $start: String!,
    $storePreview: String,
    $uPrefShipTo: String,
  ) {
    category(
      categoryId: $categoryId,
      facet: $facet,
      filter: $filter,
      requestSocialProofData: $requestSocialProofData,
      rows: $rows,
      sort: $sort,
      start: $start,
      storePreview: $storePreview,
      uPrefShipTo: $uPrefShipTo
    ) {
      categoryId
      fullCatHead
      pagination {
        currentPage
        totalPages
      }
      productTotalCount
      products {
        ...Products
        socialProofMessage
      }
      ...Espot
    }
  }
`;

const getAdditionalData = gql`
  ${productsFragment}
  query CategoryLoadMore(
    $categoryId: String!,
    $facet: [String],
    $filter: String,
    $rows: String!,
    $sort: String,
    $start: String!,
    $storePreview: String,
    $uPrefShipTo: String,
  ) {
    category(
      categoryId: $categoryId,
      facet: $facet,
      filter: $filter,
      rows: $rows,
      sort: $sort,
      start: $start,
      storePreview: $storePreview,
      uPrefShipTo: $uPrefShipTo
    ) {
      products {
        ...Products
      }
    }
  }
`;

function CategoryGridWrapper({
  appendCategoryData = null,
  categoryData = null,
  categoryId,
  facet = [],
  filter = '',
  handleCategoryData = () => {},
  handleLoadMoreButtonClick = () => {},
  handleLoadPreviousButtonClick = () => {},
  handleProductCount = () => {},
  onPaginationButtonClick,
  pageBottomStartParameter = 0,
  pageTopStartParameter = 0,
  prependCategoryData = null,
  rows = '90',
  sort = '',
  start = '0',
  storePreview = 'false',
  uPrefShipTo = '',
}) {
  const {
    [DD_PAGINATION_TEST]: digitalDataPaginationTest,
  } = useContext(DigitalDataContext);

  const queryVariables = {
    categoryId,
    facet,
    filter,
    requestSocialProofData: true,
    rows,
    sort,
    start,
    storePreview,
    uPrefShipTo,
  };

  const {
    loading, error, data,
  } = useQuery(getCategoryData, {
    variables: queryVariables,
    nextFetchPolicy: digitalDataPaginationTest ? 'standby' : 'cache-first',
    onCompleted: (received) => {
      handleCategoryData(received?.category?.products);
    },
  });

  /* Start Load More Test */

  const numericalRows = parseInt(rows, 10);
  const totalProducts = data?.category?.productTotalCount;
  const currentViewed = Math.min(pageBottomStartParameter + numericalRows, totalProducts);

  const [getAdditionalProducts, { loading: isMoreProductsLoading }] = useLazyQuery(
    getAdditionalData,
    {
      nextFetchPolicy: 'standby',
      onCompleted: (additionalProducts) => {
        appendCategoryData(additionalProducts?.category?.products);
        handleLoadMoreButtonClick(pageBottomStartParameter + numericalRows);
      },
    },
  );

  const [getPreviousProducts, { loading: isPrevProductsLoading }] = useLazyQuery(
    getAdditionalData,
    {
      nextFetchPolicy: 'standby',
      onCompleted: (additionalProducts) => {
        prependCategoryData(additionalProducts?.category?.products);
        handleLoadPreviousButtonClick(pageTopStartParameter - numericalRows);
      },
    },
  );

  const loadMoreButtonClick = () => {
    getAdditionalProducts({
      variables: {
        ...queryVariables,
        start: (pageBottomStartParameter + numericalRows).toString(),
      },
    });
  };

  const loadPreviousButtonClick = () => {
    getPreviousProducts({
      variables: {
        ...queryVariables,
        start: (pageTopStartParameter - numericalRows).toString(),
      },
    });
  };

  /* End Load More Test */

  useEffect(() => {
    handleProductCount(data?.category?.productTotalCount);
  }, [data?.category?.productTotalCount, handleProductCount]);

  // when load more is on, we only use loading message on initial load
  if (digitalDataPaginationTest) {
    if ((!categoryData && !error) || loading) return <SkeletonProductGrid cardAmount={12} />;
  } else if (loading) {
    return <SkeletonProductGrid cardAmount={12} />;
  }
  if (error) return ERROR_MESSAGE;

  const {
    espotContent,
    espotIdentifier,
    fullCatHead,
    pagination: {
      currentPage,
      totalPages,
    },
    products,
  } = data.category;

  return (
    <>
      {products.length > 0
        ? (
          <>
            {
              (digitalDataPaginationTest)
                ? (
                  (pageTopStartParameter >= numericalRows) && (
                    <GridButtonPagination
                      buttonTypeNext={false}
                      currentViewed={currentViewed}
                      isLoading={isMoreProductsLoading || isPrevProductsLoading}
                      onButtonClick={loadPreviousButtonClick}
                      productTotalCount={totalProducts}
                    />
                  )
                )
                : null
            }

            <FullPageGrid
              categoryId={categoryId}
              espotContent={espotContent}
              espotIdentifier={espotIdentifier}
              fullCatHead={fullCatHead}
              products={digitalDataPaginationTest ? categoryData : products}
            />
            {
              (digitalDataPaginationTest)
                ? (
                  <GridButtonPagination
                    buttonTypeNext
                    currentViewed={currentViewed}
                    isLoading={isMoreProductsLoading || isPrevProductsLoading}
                    onButtonClick={loadMoreButtonClick}
                    productTotalCount={totalProducts}
                    showProgressBar
                  />
                )
                : (
                  <GridPagination
                    currentPage={currentPage}
                    onButtonClick={onPaginationButtonClick}
                    rows={rows}
                    start={start}
                    totalPages={totalPages}
                  />
                )
            }
          </>
        )
        : (
          <NoResults />
        )}
    </>
  );
}

CategoryGridWrapper.propTypes = {
  // Required props
  categoryId: PropTypes.string.isRequired,
  handleProductCount: PropTypes.func.isRequired,
  onPaginationButtonClick: PropTypes.func.isRequired,
  // Optional props
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  rows: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  storePreview: PropTypes.string,
  uPrefShipTo: PropTypes.string,
  // Load More test props
  appendCategoryData: PropTypes.func,
  categoryData: PropTypes.arrayOf(productType),
  handleCategoryData: PropTypes.func,
  prependCategoryData: PropTypes.func,
  handleLoadMoreButtonClick: PropTypes.func,
  handleLoadPreviousButtonClick: PropTypes.func,
  pageTopStartParameter: PropTypes.number,
  pageBottomStartParameter: PropTypes.number,
};

export default CategoryGridWrapper;
