import { gql } from '@apollo/client';

const USERCONFIG_DATA = gql`
  query getUserConfigData($countryCode: String!) {
    userConfig(countryCode: $countryCode) {
      countries {
        countryCode
        name
      }
      languages {
        languageId
        name
        languageCode
        isActive
      }
      currencies {
        code
        symbol
        name
      }
    }
  }
`;

// GraphQL Mutations
const USER_PREFERENCES_REQUEST = gql`
  mutation updateUserPreferencesRequest(
    $countryCode: String,
    $currencyCode: String,
    $languageCode: String,
    $hostName: String,
    $url: String,
    $languageId: String
  ) {
    updateUserPreferencesRequest(
      countryCode: $countryCode,
      currencyCode: $currencyCode,
      languageCode: $languageCode,
      hostName: $hostName,
      url: $url,
      languageId: $languageId
    ) {
      response {
        success
        errors {
          message {
            value
            key
          }
          status
        }
      }
      language
      refresh
      transferBag
      url
      token
      redirectingUrl
    }
  }
`;

export { USERCONFIG_DATA, USER_PREFERENCES_REQUEST };
