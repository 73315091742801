import { createRef, useState } from 'react';
/**
 * Custom hook for tab navigation.
 *
 * @param {string} initialTabPreference - The ID of the initially visible tab.
 * @param {string[]} tabPanelIDs - An array of tab panel IDs.
 * @returns {{
*  refs: React.RefObject[],
*  visibleTabID: string,
*  handleTabClick: (event: React.MouseEvent) => void,
*  handleTabKeyDown: (event: React.KeyboardEvent) => void
* }} - An object containing the necessary properties and event handlers.
*/
const useTabNavigation = (initialTabPreference, tabPanelIDs) => {
  const refs = tabPanelIDs.map(() => createRef(null));
  const [visibleTabID, setVisibleTabID] = useState(initialTabPreference);

  const handleTabClick = (event) => {
    const tabID = event.currentTarget.getAttribute('aria-controls');
    setVisibleTabID(tabID);
  };

  const handleTabKeyDown = (event) => {
    const directionalArrowCodes = ['ArrowLeft', 'ArrowRight'];

    if (directionalArrowCodes.includes(event.code)) {
      event.preventDefault();

      const currentIndex = tabPanelIDs.indexOf(visibleTabID);
      const deltasByCode = {
        ArrowLeft: -1,
        ArrowRight: 1,
      };

      const indexOfTabToShow = (
        tabPanelIDs.length
        + currentIndex
        + deltasByCode[event.code]
      ) % tabPanelIDs.length;

      setVisibleTabID(tabPanelIDs[indexOfTabToShow]);

      refs[indexOfTabToShow].current.focus();
    }
  };

  return {
    refs, visibleTabID, handleTabClick, handleTabKeyDown,
  };
};

export default useTabNavigation;
