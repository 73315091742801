import { FACET_DICTIONARY } from './constants';

/**
 * Resets the state of a given elements.
 * @param {HTMLElement[]} marketingElements - Array of elements to reset.
 */
const resetDataStateForMarketingFilters = (marketingElements) => {
  marketingElements?.forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element.checked = false;
    element.setAttribute('data-state', '');
    element.removeAttribute('disabled');
  });
};

/**
 * Validates a filter element.
 * @param {HTMLElement} filterElement - The filter element to validate.
 * @returns {boolean} - Returns true if the filter element is valid, false otherwise.
 */
const validateFilterElement = (filterElement) => {
  const id = filterElement.getAttribute('data-filter-id');
  const values = filterElement.getAttribute('data-filter-values');
  return typeof id === 'string' && typeof values === 'string' && id.length > 0 && values.length > 0;
};

/**
 * Updates the facet values based on the filter object and selection status.
 * @param {Object} filterObj - The filter object.
 * @returns {string[]} - Array of updated facet values.
 */
const updateFacetValues = (filterObj) => {
  const facetValues = [];

  filterObj.values.split(',').forEach((value) => {
    const trimmedValue = value.trim();
    const noCorrespondingFacet = (
      document.querySelector(`.grid-filter-input input[value='${trimmedValue.replace('\'', '\\\'')}']`) === null
    )
    && (
      // checking old API format
      document.querySelectorAll(`div.Filters input[type="checkbox"][value="${filterObj.legacyName},${trimmedValue}"]`).length === 0
      // checking new API format
      && document.querySelectorAll(`div.Filters input[type="checkbox"][value="${filterObj.genericName},${trimmedValue}"]`).length === 0
    );
    if (noCorrespondingFacet) {
      return;
    }
    facetValues.push(trimmedValue);
  });

  return facetValues;
};

/**
 * Checks if filters are rendered on the page.
 * @returns {boolean} True if filters are rendered, false otherwise.
 */
const areFiltersRendered = () => document.querySelectorAll('[data-testid="filters-wrapper"]').length > 0;

/**
 * Converts old API filter names to new ones if needed
 * @returns {string} The filter name used by the V3 API
 */
const getGenericFilterName = (originalFilterName) => {
  const genericFilterName = Object.keys(FACET_DICTIONARY).find(
    (key) => FACET_DICTIONARY[key] === originalFilterName,
  );
  // if the name isn't in the dictionary, assume it's already in the new format and return as-is
  return (genericFilterName || originalFilterName);
};

/**
 * Converts new API filter names back to the old ones if needed
 * @returns {string} The filter name used by the old API
 */
const getLegacyFilterName = (originalFilterName) => {
  const legacyFilterName = FACET_DICTIONARY[originalFilterName];

  // if the name isn't in the dictionary, assume it's already in the old format and return as-is
  return (legacyFilterName || originalFilterName);
};

export {
  resetDataStateForMarketingFilters,
  validateFilterElement,
  updateFacetValues,
  areFiltersRendered,
  getGenericFilterName,
  getLegacyFilterName,
};
