import { ACTION } from './constants';
/**
 * Updates state when user navigates through browser back/forward buttons.
 * @param {function} dispatch - The dispatch function from the useReducer hook.
 */
const syncStateWithUrl = (dispatch) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const departmentId = urlSearchParams.get('departmentId');
  const categoryId = urlSearchParams.get('categoryId');
  const searchTerm = urlSearchParams.get('searchTerm');
  const previousSearchterm = urlSearchParams.get('previousSearchterm');
  const newParams = {
    ...(categoryId && { categoryId }),
    ...(departmentId && { departmentId }),
    ...(previousSearchterm && { previousSearchterm }),
    ...(searchTerm && { searchTerm }),
    facet: urlSearchParams.getAll('facet'),
    filter: urlSearchParams.get('filter'),
    previousSearchterm: urlSearchParams.get('previousSearchterm'),
    sort: urlSearchParams.get('sort'),
    start: urlSearchParams.get('start'),
    submitMethod: urlSearchParams.get('submitMethod'),
  };
  dispatch({
    type: ACTION.HISTORY_NAVIGATION,
    payload: newParams,
  });
};

export default syncStateWithUrl;
