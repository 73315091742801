import { Checkbox } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const propTypes = {
  description: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  name: PropTypes.string,
};

function ControlledCheckbox({
  children = null,
  description,
  id,
  isChecked = false,
  isDisabled = false,
  isInvalid = false,
  name = null,
  onChange = () => {},
  value,
}) {
  const [isInputChecked, setIsInputChecked] = useState(isChecked);
  const changeHandler = typeof onChange === 'function'
    ? (anEvent) => setIsInputChecked(onChange(anEvent) ?? anEvent.target.checked)
    : (anEvent) => setIsInputChecked(anEvent.target.checked);

  useEffect(() => {
    setIsInputChecked(isChecked);
  }, [isChecked]);

  return (
    <Checkbox
      description={description}
      id={id}
      isChecked={isInputChecked}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      name={name}
      onChange={changeHandler}
      value={value}
    >
      {children}
    </Checkbox>
  );
}

ControlledCheckbox.propTypes = propTypes;

export default ControlledCheckbox;
