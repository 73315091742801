import { useContext, useEffect } from 'react';
import SearchResultsContext from '../context/SearchResultsContext';

/**
 * Focus on the passed element if a search is initiated by the user
 * @param {MutableRefObject<T>} ref - Reference to the product card link
 * @param {boolean} skip - Optional condition to skip focusing on the element
 */
export default function useFocusAfterSearch(ref, skip = false) {
  const { userInitiatedAsyncSearch } = useContext(SearchResultsContext);

  useEffect(() => {
    if (userInitiatedAsyncSearch && !skip && ref?.current) {
      ref.current.focus();
    }
  }, [userInitiatedAsyncSearch, skip, ref]);
}
