import useTranslatedText from '../../../hooks/useTranslatedText';

const useFranchiseModalText = (countryName, skip) => {
  const shipToHeading = useTranslatedText('shippingTo', {
    fallback: `Shipping to ${countryName}?`,
    replacements: [countryName],
    skip,
  });

  const shopLocalSite = useTranslatedText('shopLocalSite', {
    fallback: 'Shop our local franchise partner\'s site.',
    skip,
  });

  const itemsNotSaved = useTranslatedText('itemsNotSave', {
    fallback: 'Items in your bag will not be saved or transferred and promotions/product availability may vary.',
    skip,
  });

  const confirmButtonText = useTranslatedText('yesShipTo', {
    fallback: `Yes, ship to ${countryName}`,
    replacements: [countryName],
    skip,
  });

  const declineButtonText = useTranslatedText('noShipElsewhere', {
    fallback: 'No, ship somewhere else',
    skip,
  });

  const legalText = useTranslatedText('franchiseLegalDisclaimer', {
    fallback: `By clicking "Yes, Ship to ${countryName}", you will be redirected to a third party ${countryName} site. Your use of and purchase from the ${countryName} site will be between you and the third party and you will be subject to the terms and conditions as provided to you by the third party. Please note that the ${countryName} site may also collect, use, and disclose your personal information for the third party's own purposes. We encourage you to review the privacy notice on the ${countryName} site to understand how your personal information will be used and to exercise your data rights.`,
    replacements: [countryName],
    skip,
  });

  return {
    shipToHeading,
    shopLocalSite,
    itemsNotSaved,
    confirmButtonText,
    declineButtonText,
    legalText,
  };
};

export default useFranchiseModalText;
