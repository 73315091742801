import { useEffect, useState } from 'react';
/**
* Handle Focus state. Also allows for an element to be passed in to have focus returned when needed
* @param {MutableRefObject<T>} ref - Reference to the element that focus should be returned to.
* @returns {[bool, function, function]} An Array containing
* the isFocused state,
* setFocus state setter,
* setFocusReturn state used to return focus to the passed in ref object.
*/
const useFocus = (ref) => {
  const [isFocused, setFocus] = useState(false);
  const [isFocusReturn, setFocusReturn] = useState(false); // Resets focus after clear button click

  useEffect(() => {
    if (isFocusReturn) {
      ref?.current?.focus();
      setFocusReturn(false);
    }
  }, [isFocusReturn, ref, setFocusReturn]);

  return [isFocused, setFocus, setFocusReturn];
};

export default useFocus;
