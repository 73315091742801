import { gql } from '@apollo/client';

const sortDataFragment = gql`
  fragment SortData on SortData {
    defaultSortOption
    sortOptions {
      id
      value
    }
  }
`;

export default sortDataFragment;
