import { TagGroup } from 'anf-core-react';
import {
  func, number, shape,
} from 'prop-types';
import React from 'react';

export default function ClickedButtons({
  clickedSuggestions = new Set(),
  removeSuggestion = () => {},
}) {
  const tags = [...clickedSuggestions].map((clickedSuggestion) => ({
    label: clickedSuggestion,
    removeButton: {
      labelText: `Remove ${clickedSuggestion}`, // TODO
      onClick: () => { removeSuggestion(clickedSuggestion); },
    },
  }));

  return (<TagGroup tags={tags} />);
}

ClickedButtons.propTypes = {
  clickedSuggestions: shape({
    size: number,
  }),
  removeSuggestion: func,
};
