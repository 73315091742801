import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';

import BreakpointProvider from '../BreakpointProvider/BreakpointProvider';
import Challenge from './Challenge';
import FooterWrapper from './FooterWrapper';

const getFastlyChallengeSwitch = gql`
  query FastlyChallengeSwitch {
    config {
      hasFastlyChallengeEnabled: genericType(name: "hasFastlyChallengeEnabled")
    }
  }
`;

export default function Footer({
  brand,
  country: defaultCountry = '',
}) {
  const { data } = useQuery(getFastlyChallengeSwitch);
  return (
    <>
      <BreakpointProvider>
        <FooterWrapper brand={brand} defaultCountry={defaultCountry} />
      </BreakpointProvider>
      {!!data?.config?.hasFastlyChallengeEnabled?.value && <Challenge />}
    </>
  );
}

Footer.propTypes = {
  brand: PropTypes.string.isRequired,
  country: PropTypes.string,
};
