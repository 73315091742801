import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import SimilarContext from '../../context/ShopSimilarContext';
import { SIMILAR_DEFAULT_RECIPE, SIMILAR_PRODUCT_ROWS } from '../../tools/constants';
import resolveSimilar from '../../tools/getLilySearchSuggestions';
import getSimilarCategory from '../../tools/getSimilarCategory';
import $window from '../../tools/window';
import BreakpointProvider from '../BreakpointProvider';
import SimilarGridContent from './SimilarGridContent';
import SimilarModalContent from './SimilarModalContent';
import { SIMILAR_PRODUCT_DATA } from './queries';

const SimilarEntryPoint = ({
  brand = '',
}) => {
  const [similarState, setSimilarState] = useState({
    containerId: '',
    facet: [],
    productId: '',
    rows: SIMILAR_PRODUCT_ROWS,
    similarRecipeId: SIMILAR_DEFAULT_RECIPE,
    displayFacets: false,
  });

  const [shopSimilarData, setShopSimilarData] = useState({});
  const [getProductData, { data: queryData }] = useLazyQuery(SIMILAR_PRODUCT_DATA, {
    ssr: false,
  });

  useEffectOnce(() => {
    const handleShopSimilarEvent = (event) => {
      const {
        productId,
        containerId,
        rows,
        facet,
        similarRecipeId,
        displayFacets,
        productData,
      } = event?.detail;
      if (productId) {
        setSimilarState((prevState) => ({
          ...prevState,
          containerId: containerId ?? '',
          productId: productId.toString(),
          rows: rows ? rows.toString() : prevState.rows,
          facet: facet ?? [],
          similarRecipeId: similarRecipeId ?? 'default',
          displayFacets: displayFacets ?? false,
          productData: productData ?? null,
        }));
        if (productData) {
          // get new search term from webservice tool
          setShopSimilarData({
            shopSimilarTerms: resolveSimilar(productData),
            similarCategoryData: getSimilarCategory(productData?.breadcrumbCategoryHierarchy),
          });
        } else {
          // get new search term from graphql
          getProductData({
            variables: { productId },
          });
        }
      }
    };
    $window.addEventListener('event:seek-shop-similar', handleShopSimilarEvent);

    const event = new Event('shopSimilarReady');
    $window.dispatchEvent(event);

    return () => {
      $window.removeEventListener('event:seek-shop-similar', handleShopSimilarEvent);
    };
  });
  const productName = queryData?.product?.name ?? similarState.productData?.name;

  useEffect(() => {
    const similarTerms = queryData?.product?.shopSimilarTerms || {};
    setShopSimilarData({ shopSimilarTerms: similarTerms });
  }, [queryData]);

  if (!similarState.productId
    || Object.keys(shopSimilarData?.shopSimilarTerms || {}).length === 0) return null;

  return (
    <BreakpointProvider>
      <SimilarContext.Provider value={{
        ...similarState,
        name: productName,
        shopSimilarTerms: shopSimilarData.shopSimilarTerms,
      }}
      >
        {similarState.containerId
          ? (
            <SimilarGridContent
              brand={brand}
              similarCategoryData={shopSimilarData.similarCategoryData}
            />
          )
          : <SimilarModalContent />}
      </SimilarContext.Provider>
    </BreakpointProvider>
  );
};

SimilarEntryPoint.propTypes = {
  brand: PropTypes.string,
};

export default SimilarEntryPoint;
