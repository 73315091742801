import PropTypes from 'prop-types';
import React, {
  useCallback, useReducer, useRef, useEffect, useState,
} from 'react';
import { useWindowSize } from 'react-use';
import useCRSParameterListener from '../../hooks/useCRSParameterListener';
import useFilterTriggerAnalytics from '../../hooks/useFilterTriggerAnalytics';
import useIsScrolling from '../../hooks/useIsScrolling';
import useStoreData from '../../hooks/useStoreData';
import useUrlSync from '../../hooks/useUrlSync';
import {
  ACTION, PRODUCTS_PER_PAGE,
} from '../../tools/constants';
import getFacetKeysForAnalytics from '../../tools/getFacetKeysForAnalytics';
import getFacetmap from '../../tools/getFacetmap';
import getPageType from '../../tools/getPageType';
import getSelectedFacets from '../../tools/getSelectedFacets';
import isServer from '../../tools/isServer';
import reducer from '../../tools/parametersChange';
import $window from '../../tools/window';
import EditableSearchHeader from '../EditableSearch/EditableSearchHeader';
import useStickyIntersection from '../Refine/hooks/useStickyIntersection';
import SearchAside from '../SearchAside/SearchAside';
import SearchErrorPage from '../SearchErrorPage';
import SearchMainContent from '../SearchMainContent/SearchMainContent';
import StoreDetailsProvider from '../StoreDetailsProvider/StoreDetailsProvider';
import style from './SearchPage.module.scss';
import useFeatureFlags from './hooks/useFeatureFlags';
import useSearchResponse from './hooks/useSearchResponse';
import updateSearchTermToSuggested from './tools/updateSearchTermToSuggested';

// all props come through gateway.jsx filtered through the parseEndpointParameters function
export default function SearchPage({
  brand: intlBrand = '',
  countryFulfillmentStore = '',
  departmentId: intlDepartmentId = '',
  facet = [],
  filter = '',
  initialDepartmentId = '',
  searchTerm = '',
  sort = '',
  start = '0',
  userInputedSearchTerm,
  store = '',
  storeId = '',
}) {
  // feature flags
  const {
    editableSearchFlag,
    departmentSuggestionsFlag,
    facetsLeftRailFlag,
    hasShopMyStoreEnabled,
  } = useFeatureFlags();

  const intlFacetArray = typeof facet === 'string' ? [facet] : facet;
  const isScrollingUp = useIsScrolling(['up']);
  const [parameters, dispatch] = useReducer(reducer, {
    brand: intlBrand,
    countryFulfillmentStore,
    currentURL: $window.location?.pathname,
    departmentId: intlDepartmentId, // Provided by CRS (SOLR API version)
    initialDepartmentId: initialDepartmentId || '', // Provided by the URL
    facet: intlFacetArray,
    filter,
    rows: PRODUCTS_PER_PAGE,
    searchTerm,
    sort,
    submitMethod: 'toaster',
    start,
    initialSearchTerm: userInputedSearchTerm,
    userInputedSearchTerm,
  });
  const { width } = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(true);
  const gridWrapper = useRef(null);
  const railRef = useRef({ appliedRailFilters: [] });
  // derived state:
  const isFacetSelected = parameters.facet?.length > 0;
  const gridIntersecting = useStickyIntersection(gridWrapper);
  const storeDetails = useStoreData();

  useEffect(() => {
    setIsDesktop(width >= 1025);
  }, [setIsDesktop, width]);

  //  Search response
  const {
    data,
    error,
    loading,
    previousData,
    resolvedSearchTerm,
    setResolvedSearchTerm,
  } = useSearchResponse(parameters);

  const syncSearchTerm = useCallback(() => {
    if (resolvedSearchTerm !== parameters.searchTerm) {
      dispatch({
        type: ACTION.SEARCHTERM,
        payload: { searchTerm: resolvedSearchTerm },
      });
    }
  }, [resolvedSearchTerm, parameters.searchTerm]);

  const handleStoreFilter = useCallback((storeIdFromFilter) => {
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.LOCAL_STORE_TOGGLE,
      payload: storeIdFromFilter,
    });
  }, [parameters.searchTerm, resolvedSearchTerm]);

  // URL SYNC
  useUrlSync(parameters, dispatch);

  const onClearFacetTag = (payload) => {
    if (railRef.current.appliedRailFilters.includes(`${payload.facetKey},${payload.facetValue}`)) {
      railRef.current.appliedRailFilters = railRef.current.appliedRailFilters.filter(
        (item) => item !== `${payload.facetKey},${payload.facetValue}`,
      );
    }
    if ((isDesktop && facetsLeftRailFlag) || !isDesktop) {
      const facetMap = getSelectedFacets(parameters.facet);
      // Check if selected checkbox is in already selected facet filters
      if (facetMap.has(payload.facetKey)) {
        const checkedFacets = facetMap.get(payload.facetKey);
        const index = checkedFacets.indexOf(payload.facetValue);
        if (index !== -1) {
          if (checkedFacets.length === 1) {
            facetMap.delete(payload.facetKey);
          } else {
            checkedFacets.splice(index, 1);
          }
        }
      }

      updateSearchTermToSuggested();
      dispatch({
        type: ACTION.FACET_TOGGLE,
        payload: [...facetMap],
      });
    } else {
      const correspondingLeftRailFilterInput = document.querySelector(
        `input[name="${payload.facetKey}"][value="${payload.facetValue}"],
        input[name="${payload.facetValue}"][value="${payload.facetKey},${payload.facetValue}"]`,
      );
      if (correspondingLeftRailFilterInput && correspondingLeftRailFilterInput.checked) {
        correspondingLeftRailFilterInput.click();
      }
    }
  };

  // Need this for backward compatibility
  const handleCRSParameterChange = useCallback((event) => {
    updateSearchTermToSuggested();
    dispatch({
      type: ACTION.CRS_UPDATE,
      payload: event.detail,
    });
  }, [dispatch]);

  const handleCategoryParametersChanged = useCallback((event) => {
    // if facets are on the only possible event we can get from CRS would be a store filter change
    if (facetsLeftRailFlag) {
      const newStoreId = event.detail.filter?.split(':')[1] ?? '';
      handleStoreFilter(newStoreId);
    } else {
      handleCRSParameterChange(event);
    }
  }, [facetsLeftRailFlag, handleCRSParameterChange, handleStoreFilter]);

  useCRSParameterListener(handleCategoryParametersChanged);
  useFilterTriggerAnalytics(storeDetails, parameters.filter);

  if (error) {
    console.error('SearchResultsPage : getSearchData : Error executing GraphQL query', {
      searchTermParameter: searchTerm,
      facetParameter: parameters.facet,
      departmentIdParameter: parameters.departmentId,
      error,
    });

    if (isServer()) {
      return null;
    }

    return <SearchErrorPage error={error} />;
  }

  let currentData;

  if (loading) {
    if (!previousData) {
      return null;
    }
    currentData = previousData;
  } else {
    currentData = data;
  }

  const {
    departmentId,
    facets,
    pagination,
    products,
    sortData,
    stats,
    departments: elasticDepartmentData,
    searchSuggestions,
  } = currentData?.searchResults || {};

  const showAdditionalControls = stats?.total !== 0 || !departmentSuggestionsFlag;
  const suggestionObject = searchSuggestions?.byName;

  const handleCheckBoxChange = (event) => {
    const { target: { checked, value } } = event;
    railRef.current.appliedRailFilters = checked
      ? [...railRef.current.appliedRailFilters, value]
      : railRef.current.appliedRailFilters.filter((item) => item !== value);

    const facetMap = getFacetmap(event, parameters.facet);
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });

    if ($window.digitalData) {
      const analyticsEvent = {
        search_filter_applied_category: `${[...getFacetKeysForAnalytics(facetMap)]}`,
        search_filter_applied_value_name: `${[...facetMap.values()]}`,
        event_name: 'filter_applied',
        event_type: 'filters',
        page_description: (!parameters.departmentId) ? 'All Genders' : parameters.departmentId,
        tealium_event: 'filter_applied',
        data_text: 'rail',
        data_action: railRef.current.appliedRailFilters.length > 1 ? 'multiple' : 'single',
      };
      $window.digitalData.trigger('search_filter_applied', analyticsEvent);
    }
  };

  const handleSortChange = (event) => {
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.SORT_UPDATE,
      payload: event.target.value,
    });
    $window.digitalData.trigger(`${getPageType()}_sort_applied`, {
      event_name: 'sort_applied',
      event_type: 'sort',
      search_filter_applied_value_name: event.target.value,
      tealium_event: 'sort_applied',
    });
  };

  const onClearAllBtnClick = () => {
    railRef.current.appliedRailFilters = [];
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.CLEAR_ALL_PARAMETERS,
    });
  };

  const onDepartmentChange = (event) => {
    railRef.current.appliedRailFilters = [];
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.DEPARTMENT_CHANGE,
      payload: event.target,
    });
  };

  const onPaginationButtonClick = (event, newStart) => {
    updateSearchTermToSuggested(resolvedSearchTerm, parameters.searchTerm, dispatch);
    dispatch({
      type: ACTION.PAGINATION,
      payload: newStart,
    });
  };

  const updateFacets = (facetMap, discoverTrigger = '', facetId) => {
    const discoverProps = discoverTrigger ? {
      data_text: `${discoverTrigger} ${facetId} filter`,
      data_action: 'applied',
    } : {};
    syncSearchTerm();
    dispatch({
      type: ACTION.FACET_TOGGLE,
      payload: [...facetMap],
    });

    if ($window.digitalData) {
      const analyticsEvent = {
        search_filter_applied_category: `${discoverTrigger} ${[...getFacetKeysForAnalytics(facetMap)]}`,
        search_filter_applied_value_name: `${[...facetMap.values()]}`,
        event_name: 'filter_applied',
        event_type: 'filters',
        page_description: (!parameters.departmentId) ? 'All Genders' : parameters.departmentId,
        tealium_event: 'filter_applied',
        data_text: 'rail',
        data_action: railRef.current.appliedRailFilters.length > 1 ? 'multiple' : 'single',
        ...discoverProps,
      };
      $window.digitalData.trigger('search_filter_applied', analyticsEvent);
    }
  };

  const handleFacetToasterChange = (facetId, filters, discoverTrigger = '') => {
    const facetMap = getSelectedFacets(parameters.facet);
    if (filters.length === 0) {
      facetMap.delete(facetId);
    } else {
      facetMap.set(facetId, filters);
    }
    updateFacets(facetMap, discoverTrigger, facetId);
  };

  /**
   * Submit search term from Editable Search Header or Search Default View
   * @param {Object} submission - Object containing the search term and submit method
   * @param {string} submission.value - Search term
   * @param {string} submission.submitMethod - Method used to submit the search term,
   * which will be undefined from Search Default View
   * @returns {void}
   */
  const onSearchTermChange = (submission) => {
    railRef.current.appliedRailFilters = [];
    const newSearchTerm = submission?.value;
    const submitMethod = submission?.submitMethod || 'user-initiated';
    const suggestedDepartment = submission?.departmentId || parameters.departmentId;
    dispatch({
      type: ACTION.SEARCHTERM,
      payload: {
        searchTerm: newSearchTerm,
        submitMethod,
        departmentId: suggestedDepartment,
      },
    });
  };

  // in future these different portal locations will just become their components.
  return (
    <StoreDetailsProvider store={store} storeId={storeId}>
      <div className="editable-search-container">
        <EditableSearchHeader
          brand={parameters.brand}
          departmentId={parameters.departmentId || departmentId}
          facet={parameters.facet}
          facetData={currentData?.searchResults?.facets}
          filter={parameters.filter}
          isDesktop={isDesktop}
          isFacetSelected={isFacetSelected}
          onCheckBoxChange={handleCheckBoxChange}
          onClearAllBtnClick={onClearAllBtnClick}
          onFacetToasterChange={handleFacetToasterChange}
          onSearchTermChange={onSearchTermChange}
          onSortChange={handleSortChange}
          resultsCount={currentData?.searchResults?.stats.refinedTotal}
          searchTerm={resolvedSearchTerm || parameters.searchTerm}
          selectedSort={parameters.sort
            || currentData?.searchResults?.sortData?.defaultSortOption}
          setResolvedSearchTerm={setResolvedSearchTerm}
          showStickyBar={gridIntersecting && isScrollingUp}
          sortData={currentData?.searchResults?.sortData}
          suggestionObject={suggestionObject}
        />
      </div>
      <div className={`${style.gridContentWrap}`} id="primary-content">
        {isDesktop ? (
          <SearchAside
            brand={parameters.brand}
            departmentId={(departmentId !== undefined && departmentId !== null
              ? departmentId : parameters.departmentId || initialDepartmentId)}
            editableSearchFlag={editableSearchFlag}
            elasticDepartmentData={elasticDepartmentData}
            facets={currentData?.searchResults?.facets}
            facetsLeftRailFlag={facetsLeftRailFlag}
            handleCheckBoxChange={handleCheckBoxChange}
            handleStoreFilter={handleStoreFilter}
            hasShopMyStoreEnabled={hasShopMyStoreEnabled}
            isClearAllButtonEnabled={isFacetSelected
              || !!parameters.filter}
            isDesktop={isDesktop}
            onClearAllBtnClick={onClearAllBtnClick}
            onDepartmentChange={onDepartmentChange}
            parameters={parameters}
            showAdditionalControls={showAdditionalControls}
            storeDetails={storeDetails}
          />
        ) : null}
        <main ref={gridWrapper} className={`${style.main} product-grid__col--major`} tabIndex="-1">
          <SearchMainContent
            editableSearchFlag={editableSearchFlag}
            elasticDepartmentData={elasticDepartmentData}
            facets={facets}
            handleCheckBoxChange={handleCheckBoxChange}
            handleSortChange={handleSortChange}
            handleStoreFilter={handleStoreFilter}
            initialDepartmentId={initialDepartmentId}
            loading={loading}
            onClearAllBtnClick={onClearAllBtnClick}
            onClearFacetTag={onClearFacetTag}
            onDepartmentChange={onDepartmentChange}
            onPaginationButtonClick={onPaginationButtonClick}
            onSearchTermChange={onSearchTermChange}
            pagination={pagination}
            parameters={parameters}
            products={products}
            searchGridData={{ loading, pagination, products }}
            searchSuggestions={searchSuggestions}
            searchTerm={searchTerm}
            showAdditionalControls={showAdditionalControls}
            sortData={sortData}
            stats={stats}
            storeDetails={storeDetails}
          />
        </main>
      </div>
    </StoreDetailsProvider>
  );
}
SearchPage.propTypes = {
  // Required props
  brand: PropTypes.string.isRequired,
  // Optional props
  searchTerm: PropTypes.string,
  countryFulfillmentStore: PropTypes.string,
  departmentId: PropTypes.string,
  initialDepartmentId: PropTypes.string,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  userInputedSearchTerm: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  store: PropTypes.string,
  storeId: PropTypes.string,
};
