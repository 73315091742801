import PropTypes from 'prop-types';
import React from 'react';

const OneTrustModalLink = ({
  link,
  className = '',
}) => (
  <button
    className={className}
    data-property={link.id}
    onClick={() => window.Optanon?.ToggleInfoDisplay()}
  >
    {link.title}
  </button>
);

OneTrustModalLink.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

export default OneTrustModalLink;
