import Icon from 'anf-core-react/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import style from './SmallScreenNavBar.module.scss';

const SmallScreenNavMenu = ({ labelText, onClick }) => (
  <button
    aria-label={labelText}
    className={style.menuButton}
    onClick={onClick}
  >
    <Icon
      icon="menu"
      size="m"
      theme="inverse"
      variant="square"
    />
  </button>
);

SmallScreenNavMenu.propTypes = {
  labelText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SmallScreenNavMenu;
